import { IClientPermission } from "../entities/visa/IClientPermission";
import { permissionType } from "../enums/enums";
import { usePermissions } from "@refinedev/core";
import { decrypt } from "./cryptoHelper";
import { refreshPermissions } from "providers/authProvider";
import { IPermissionResponse } from "entities/visa/IPermissionResponse";
import { ITreeMenu } from "@refinedev/core/dist/interfaces";
import IMenuPermission from "components/model/IMenuPermission";


/*
export default function CheckPermission(data:any,key:string,pType:permissionType | undefined |string):boolean{
    if(typeof pType !== typeof permissionType)
        return false;

    if(Array.isArray(data)){
        var permissions:Array<IClientPermission> = data;
        var permission = permissions.filter(item => item.Key == key);
        if(permission && permission.length > 0){
            let perm = permission[0];
            switch(pType){
                case permissionType.CanCreate:
                    return perm.CanAdd;
                case permissionType.CanDelete:
                    return perm.CanDelete;
                case permissionType.CanEdit:
                    return perm.CanEdit;
                case permissionType.CanView:
                    return perm.CanView;
                default:
                    return false;
            }
        }
        else{
            return false;
        }
    }
    else{
        let perm:IClientPermission = data;
        if(perm.Key == key){
            switch(pType){
                case permissionType.CanCreate:
                    return perm.CanAdd;
                case permissionType.CanDelete:
                    return perm.CanDelete;
                case permissionType.CanEdit:
                    return perm.CanEdit;
                case permissionType.CanView:
                    return perm.CanView;
                default:
                    return false;
            }
        }
        else{
            return false;
        }
    }
}
*/
/*
export function CheckMenuV2(resource: string, isParentName: boolean = false, children: ITreeMenu[] = []) {
    const encMenus = localStorage.getItem("menus") ?? "";
    if (encMenus && encMenus != "") {


export function IsGlobalAdmin() {
    const encPermissions = localStorage.getItem("permissions") ?? "";
    if (encPermissions && encPermissions != "") {
        refreshPermissions();
        var permissions: Array<IPermissionResponse> = JSON.parse(decrypt(encPermissions));

        return permissions.filter((value) => {
            if (value.manager == "GlobalAll" || value.manager == "GlobalAll") return true;
            return false;
        }).length > 0;
    }

    return false;
}

export function CheckMenu(PermissionList: IMenuPermission[], isParentName: boolean = false, children: ITreeMenu[] = []) {
    if (isParentName) {
        var canShow = false;

        children.forEach((value) => {
            const { meta, children } = value
            const menuPermList: IMenuPermission[] = meta?.permissions ?? [];

            if (children.length > 0)
                canShow = canShow || CheckMenu(menuPermList, true, children);
            else
                canShow = canShow || CheckMenu(menuPermList);
        });

        return canShow;
    }
    else {
        if (PermissionList.length < 1) return IsGlobalAdmin();
        else if (PermissionList.length == 1) {
            let perm = PermissionList[0];
            return CheckPermissionsV2(perm.Method, perm.Controller);;
        }
        else {
            var canShow = true;
            PermissionList.forEach(element => {
                canShow = canShow && CheckPermissionsV2(element.Method, element.Controller);
            });

            return canShow;
        }
    }
}
*/

export function IsGlobalAdmin() {
    const encPermissions = localStorage.getItem("permissions") ?? "";
    if (encPermissions && encPermissions != "") {
        refreshPermissions();
        var permissions: Array<IPermissionResponse> = JSON.parse(decrypt(encPermissions));

        return permissions.filter((value) => {
            if (value.manager == "GlobalAll" || value.manager == "GlobalAll") return true;
            return false;
        }).length > 0;
    }

    return false;
}

export function CheckMenu(PermissionList: IMenuPermission[], isParentName: boolean = false, children: ITreeMenu[] = []) {
    if (isParentName) {
        var canShow = false;

        children.forEach((value) => {
            const { meta, children } = value
            const menuPermList: IMenuPermission[] = meta?.permissions ?? [];

            if (children.length > 0)
                canShow = canShow || CheckMenu(menuPermList, true, children);
            else
                canShow = canShow || CheckMenu(menuPermList);
        });

        return canShow;
    }
    else {
        if (PermissionList.length < 1) return IsGlobalAdmin();
        else if (PermissionList.length == 1) {
            let perm = PermissionList[0];
            return CheckPermissionsV2(perm.Method, perm.Controller);;
        }
        else {
            var canShow = true;
            PermissionList.forEach(element => {
                canShow = canShow && CheckPermissionsV2(element.Method, element.Controller);
            });

            return canShow;
        }
    }
}


export function CheckPermissionsV2(Method: string, Controller: string) {
    const encPermissions = localStorage.getItem("permissions") ?? "";
    if (encPermissions && encPermissions != "") {

        refreshPermissions();

        var permissions: Array<IPermissionResponse> = JSON.parse(decrypt(encPermissions));
        //console.log(permissions);

        return permissions.filter((value) => {
            if (value.manager == "GlobalAll" || value.manager == "LocalAll" || (value.manager == Controller && value.method == Method)) return true;
            return false;
        }).length > 0;
    }

    return false;
}