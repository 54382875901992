import { Row, Col, Card, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
    ApdexScore,
    ErrorPercentage,
    ActiveRequest,
    CpuUsage,
    RequestCountPerTime,
    DataOutPerTime,
    RequestInfoPerEndpoint
} from "../../components/dashboard";
import { OcrCounter } from "components/dashboard/ocrcounter";

const { Text } = Typography;

export const PerformanceDashboardPage: React.FC = () => {
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col md={24}>
                    <Row gutter={[16, 16]}>
                        <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                            <ApdexScore />
                        </Col>
                        <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                            <ErrorPercentage />
                        </Col>
                        <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                            <CpuUsage />
                        </Col>
                        <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                            <ActiveRequest />
                        </Col>
                        <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                            <RequestCountPerTime />
                        </Col>
                        <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                            <DataOutPerTime />
                        </Col>
                     
                        <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                            <OcrCounter />
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <RequestInfoPerEndpoint />
                        </Col>
                    </Row>  
                </Col>
            </Row>
        </>
    );
};