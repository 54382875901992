import {
    BaseRecord,
    CrudFilter,
    CrudFilters,
    CrudSorting,
    IResourceComponentsProps,
    parseTableParams,
    ResourceRouterParams,
    useNavigation,
    useParsed,
    useResource,
    useTableReturnType,
    useTranslate,
} from "@refinedev/core";

import { List, CreateButtonProps, PageHeaderProps, useModal, Breadcrumb } from "@refinedev/antd";
import { Table, TableProps, Collapse, SpaceProps, Button, Modal, Form } from "antd";
//import { Breadcrumb } from "components/breadcrumb";
import { SettingOutlined } from '@ant-design/icons';
import { DetailedHTMLProps, HTMLAttributes } from "react";

import { RefineCrudListProps } from "@refinedev/ui-types";
import { v4 as uuid } from 'uuid';
import { FilterArea } from "./FilterArea";
import { CompareFn, SortOrder } from "antd/es/table/interface";
import dayjs from "dayjs";
import { useDarkMode } from "usehooks-ts";

const { Panel } = Collapse;


declare type CRUDListProps = RefineCrudListProps<CreateButtonProps, SpaceProps, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, PageHeaderProps, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, {
    /**
     * @deprecated use `headerProps`, `wrapperProps` and `contentProps` instead.
     */
    pageHeaderProps?: PageHeaderProps;
}>;

export type SetFilterBehavior = "merge" | "replace";

export interface ListPageProperties<TData extends BaseRecord = BaseRecord> {
    listProps: CRUDListProps
    tableProps: TableProps<TData>,
    rowKey: string,
    columns: Array<ColumnInfo>,
    filters?: Array<ColumnFilterInfo>,
    setFilters?: ((filters: CrudFilters, behavior?: SetFilterBehavior) => void) & ((setter: (prevFilters: CrudFilters) => CrudFilters) => void),
    setSorter?: any,
    isLoading: boolean,
    sorter?: CrudSorting
    footer?: any,
    onRow?: any,
    resource?: string
    standartOnRowRedirectToShow?: boolean
}

export interface RenderComponentProps {
    value: any,
    record: any,
    index: number
}

export interface ColumnFilterInfo {
    key: string,
    title: string,
    type: "string" | "number" | "date" | "boolean" | "datetime",
    selectProps?: any
}

export interface ColumnInfo {
    dataIndex: string | string[],
    title: string,
    render?: React.FC<RenderComponentProps>,
    sortable?: boolean,
    entityName?: string
    clientSorter?: {
        compare: CompareFn<any>;
        /** Config multiple sorter order priority */
        multiple?: number;
    };
}

const getDefaultSortOrderCore = (
    columnName: string,
    sorter?: CrudSorting,
): SortOrder | undefined => {
    if (!sorter) {
        return undefined;
    }

    const sortItem = sorter.find((item) => item.field === columnName);

    if (sortItem) {
        return sortItem.order as SortOrder;
    }

    return undefined;
};

const getDefaultSortOrder = (
    columnName: string,
    sorter?: CrudSorting,
): SortOrder | undefined => {
    const sort = getDefaultSortOrderCore(columnName, sorter);

    if (sort) {
        return `${sort}`;
    }

    return undefined;
};

const dateSort = (dateA: string, dateB: string) => dayjs(dateA).diff(dayjs(dateB));

const defaultSort = (a: number | string, b: number | string) => {
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
};

const defaultNumberSort = (a: number | string, b: number | string) => {
    if (Number(a) < Number(b)) return -1;
    if (Number(b) < Number(a)) return 1;
    return 0;
};

export const ClientSorter = {
    DEFAULT: defaultSort,
    DATE: dateSort,
    CONVERTNUMBERSORT: defaultNumberSort
};

export const ListPage = <TData extends BaseRecord = BaseRecord>({
    listProps,
    tableProps,
    rowKey,
    columns,
    setFilters,
    setSorter,
    filters,
    isLoading,
    sorter,
    footer,
    onRow,
    resource,
    standartOnRowRedirectToShow
}: ListPageProperties<TData>) => {
    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }

    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)

    var translate = useTranslate();

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();


    const {
        edit: navigateEdit,
        show: navigateShow
    } = useNavigation();



    let canSort = sorter;

    const { show, close, modalProps } = useModal();
    const [form] = Form.useForm();

    if (!resource && parsedResource) {
        resource = parsedResource.name;
    }

    const { resource: resourceItem } = useResource(resource);


    if (!onRow && resource) {
        onRow = (record: any, rowIndex: number) => {
            return {
                onDoubleClick: (event: any) => {
                    event.preventDefault()
                    if (resource) {
                        if (standartOnRowRedirectToShow && resourceItem?.show) navigateShow(resource, record.id, "push");
                        else if (!standartOnRowRedirectToShow && resourceItem?.edit) navigateEdit(resource, record.id, "push");
                    }
                },
            };
        }
    }

    return (
        <>
            <Modal {...modalProps}
                onCancel={close}
                onOk={() => {
                    form.submit();
                    close();
                }}
            >
                <FilterArea filters={filters!} setFilters={setFilters!} isLoading={isLoading} filterFromQuery={parsedParams?.filters} modalClose={close} form={form} />
            </Modal>

            <List {...listProps} breadcrumb={<Breadcrumb />} headerButtons={({ defaultButtons }) => (
                <>
                    {defaultButtons}
                    {listProps.headerButtons}
                    {
                        setFilters &&
                        filters &&
                        filters.length > 0 &&
                        <Button type="primary" onClick={show} loading={isLoading}>{translate("ListPage.components.filter")}
                            {parsedParams?.filters?.length > 0 ? "(" + parsedParams?.filters?.length + ")" : ""}
                        </Button>
                    }
                </>
            )}>
                <Table {...tableProps} rowKey={rowKey} footer={footer} pagination={
                    {
                        ...tableProps.pagination,
                        showSizeChanger: true,
                        showTotal: ((total: number, range: [number, number]) => translate("ListPage.pagination.count", {
                            range1: range[0],
                            range2: range[1],
                            total
                        }))
                    }
                }
                    onRow={onRow}
                    className={isDarkMode ? "table-striped-rows-dark" : "table-striped-rows"}
                    bordered
                    size="large"
                >
                    {
                        columns.map((item: ColumnInfo) => {
                            if (canSort && item.sortable && item.entityName) {
                                if (item.render) {
                                    let Comp: React.FC<RenderComponentProps> = item.render;


                                    return (
                                        <Table.Column key={item.entityName}
                                            dataIndex={item.dataIndex}
                                            title={item.title}
                                            sorter={item.sortable}
                                            defaultSortOrder={getDefaultSortOrder(item.entityName, sorter)} render={(value: any, record: any, index: number) => {
                                                return <Comp value={value} record={record} index={index} />
                                            }} />
                                    );
                                }


                                return (
                                    <Table.Column key={item.entityName}
                                        dataIndex={item.dataIndex}
                                        title={item.title}
                                        sorter={item.sortable}
                                        defaultSortOrder={getDefaultSortOrder(item.entityName, sorter)} />
                                );
                            }

                            if (canSort && item.clientSorter && item.entityName) {

                                const { compare: compareParams, ...otherSorterProps } = item.clientSorter;

                                if (item.render) {
                                    let Comp: React.FC<RenderComponentProps> = item.render;


                                    return (
                                        <Table.Column key={item.entityName}
                                            dataIndex={item.dataIndex}
                                            title={item.title}
                                            sorter={{
                                                compare: (rowA, rowB) => compareParams(rowA[item.entityName!], rowB[item.entityName!]),
                                                ...otherSorterProps
                                            }}
                                            render={(value: any, record: any, index: number) => {
                                                return <Comp value={value} record={record} index={index} />
                                            }} />
                                    );
                                }


                                return (
                                    <Table.Column key={item.entityName}
                                        dataIndex={item.dataIndex}
                                        title={item.title}
                                        sorter={{
                                            compare: (rowA: any, rowB: any) => compareParams(rowA[item.entityName!], rowB[item.entityName!]),
                                            ...otherSorterProps
                                        }} />
                                );
                            }

                            if (item.render) {
                                let Comp: React.FC<RenderComponentProps> = item.render;


                                return (
                                    <Table.Column key={uuid()} dataIndex={item.dataIndex} title={item.title} render={(value: any, record: any, index: number) => {
                                        return <Comp value={value} record={record} index={index} />
                                    }} />
                                );
                            }


                            return (
                                <Table.Column key={uuid()} dataIndex={item.dataIndex} title={item.title} />
                            );
                        })
                    }
                </Table>
            </List >
        </>
    );
};


