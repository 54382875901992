

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { ICurrency } from "../../../entities/definition/ICurrency";
import { useState } from "react";
import { ConvertStatusID, ConvertBoolean } from "helpers/statusHelper";
import React from "react";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import "./style.less";
import { IUserForView } from "entities/visa/IUserForView";
import { useConstantValueFromService, useConstantValueFromServiceString } from "hooks";
import { useNavigation, useParsed, useTranslate } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Col, Form, Input, Row, Select, Switch } from "antd";



export const CurrencyEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    var actionData = "create";

    if (parsedId) {
        actionData = "edit";
    }

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<ICurrency>({
            redirect: false,
            action: actionData == "create" ? "create" : "edit",
            mutationMode: "undoable",
            metaData: {
                isFormRequest: true
            }
        });
    const translate = useTranslate();
    const Data = queryResult?.data?.data;
    var initialStatus = Data?.statusID ?? 0;

    const { list } = useNavigation();

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    if (mutationResult.isSuccess) {
        redirect("list");
    }

    const { selectProps: MoneySymbolsSelectProps } = useConstantValueFromServiceString("getmoneysymbols");

    return (
        <Edit isLoading={formLoading}
            canDelete={(CheckPermissionsV2("Delete", "Business.Concrete.Definition.CurrencyManager") && parsedId != undefined)}
            title={translate("buttons.edit")} recordItemId={parsedId} saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" ref={formRef}>
                <Form.Item label={translate("pages.currency.fields.id")} name="id" initialValue={Data?.id ?? 0} hidden>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12} key="code">
                        <Form.Item label={translate("pages.currency.fields.code")} name="code" rules={[{ required: true, message: translate("rules.requiredName") }]}>
                            <Select {...MoneySymbolsSelectProps} showSearch={false} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Name">
                        <Form.Item label={translate("pages.currency.fields.name")} name="name" rules={[{ required: true, message: translate("rules.requiredName") }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} key="Status">
                        <Form.Item label={translate("pages.currency.fields.status")} name="statusID">
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
