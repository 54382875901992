import { StyleSheet } from 'aphrodite'

const bounce = {
    'from': {
        transform: 'scale(1) translateX(0)',
    },

    'to': {
        transform: 'scale(1.3) translateX(-2px)',
    }
};

const sheet = StyleSheet.create({
    toggleContainer: {
        position: 'fixed',
        //top:calc(50% - 28px)
        right: '28px',
        transform: 'translateX(101px)',
        backgroundColor: '#fb7a32',
        textAlign: 'center',
        padding: '6px 12px 6px 12px',
        borderTopLeftRadius: '99px',
        borderBottomLeftRadius: '99px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: '8px',
        transition: 'transform 0.8s ease-out',
        zIndex: 2,
        ':hover': {
            transform: 'translateX(0px)'
        },
        'a': {
            color: 'white',
            ':hover': {
                textDecoration: 'underline'
            }
        },
        'svg': {
            color: 'white',
            fontSize: '24px',
            animationName: bounce,
            animationDelay:'1s',
            animationIterationCount: 'infinite'            
        }
    }
});



export default sheet;