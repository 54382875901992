

import { IWorkflow } from "../../../entities/definition/IWorkflow";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor, ConvertBoolean, ConvertBooleanToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { BooleanField, EditButton, TagField, useTable } from "@refinedev/antd";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";

export const WorkflowList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IWorkflow>({
        sorters: {
            initial: [
                {
                    field: "SortOrder",
                    order: "desc"
                }
            ]
        }
    });
    
    const translate = useTranslate();

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.workflow.fields.id"),
            type: "number"
        },
        {
            key: "Name",
            title: translate("pages.workflow.fields.name"),
            type: "string"
        },
        {
            key: "Limits",
            title: translate("pages.workflow.fields.limits"),
            type: "string"
        },
        {
            key: "SortOrder",
            title: translate("pages.workflow.fields.sortOrder"),
            type: "number"
        },
        {
            key: "isRequireEmployeeDepartmentManagerApprovement",
            title: translate("pages.workflow.fields.isRequireEmployeeDepartmentManagerApprovement"),
            type: "boolean"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.workflow.fields.id")
        },
        {
            dataIndex: "name",
            title: translate("pages.workflow.fields.name")
        },
        {
            dataIndex: "limits",
            title: translate("pages.workflow.fields.limits")
        },
        {
            dataIndex: "sortOrder",
            title: translate("pages.workflow.fields.sortOrder"),
            sortable: true
        },
        {
            dataIndex: "isRequireEmployeeDepartmentManagerApprovement",
            title: translate("pages.workflow.fields.isRequireEmployeeDepartmentManagerApprovement"),
            render: (props: RenderComponentProps) => (
                <BooleanField
                    value={props.value}
                    trueIcon={<CheckCircleOutlined />}
                    falseIcon={<CloseCircleOutlined />}
                    valueLabelTrue={translate("pages.cost.fields.isRequireEmployeeDepartmentManagerApprovement.labeltrue")}
                    valueLabelFalse={translate("pages.cost.fields.isRequireEmployeeDepartmentManagerApprovement.labelfalse")}
                />
            )
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <EditButton
                    size="small"
                    recordItemId={props.record.id}
                />

            )
        }
    ]

    return (
        <ListPage listProps={{
            title: translate("pages.workflow.title"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Definition.WorkflowManager")
        }}
            tableProps={tableProps}
            columns={columns}
            filters={filters}
            rowKey="id"
            setFilters={setFilters}
            setSorter={setSorter}
            isLoading={queryResult.isFetching}
            standartOnRowRedirectToShow={false} />

    );
};