import { List, TextField, TagField, useTable, ShowButton, EditButton, BooleanField, useSelect } from "@refinedev/antd";
import { Table, Row, Col, Card } from "antd";
import { IResourceComponentsProps, useShow, useTranslate } from "@refinedev/core";
import { IUserForView } from "../../../entities/visa/IUserForView";
import { usePermissions } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertNotificationStatusID, ConvertNotificationStatusIDToColor, ConvertStatusID, ConvertStatusIDToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { useState } from "react";
import { Breadcrumb } from "components/breadcrumb";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { DateField } from "components/fields/date";
import INotification from "entities/configuration/INotification";

export const NotificationList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult, setFilters, setSorters: setSorter } = useTable<INotification>({
        sorters: {
            initial: [
                {
                    field: "ID",
                    order: "desc"
                }
            ]
        }
    });


    const translate = useTranslate();

    const { selectProps: userSelectProps } = useSelect<IUserForView>({
        resource: "User",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Username",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.Notification.fields.id"),
            type: "number"
        },
        {
            key: "UserID",
            title: translate("pages.Notification.fields.user"),
            type: "number",
            selectProps: userSelectProps
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.Notification.fields.id")
        },
        {
            dataIndex: ["user", "label"],
            title: translate("pages.Notification.fields.user")
        },
        {
            dataIndex: "header",
            title: translate("pages.Notification.fields.header")
        },
        {
            dataIndex: "content",
            title: translate("pages.Notification.fields.content")
        },
        {
            dataIndex: "url",
            title: translate("pages.Notification.fields.url")
        },
        {
            dataIndex: "imageURL",
            title: translate("pages.Notification.fields.imageURL")
        },
        {
            dataIndex: "icon",
            title: translate("pages.Notification.fields.icon")
        },
        {
            dataIndex: "badge",
            title: translate("pages.Notification.fields.badge")
        },
        {
            dataIndex: ["notificationType", "name"],
            title: translate("pages.Notification.fields.notificationType")
        },
        {
            dataIndex: "isReaded",
            title: translate("pages.Notification.fields.isReaded"),
            render: (props: RenderComponentProps) => (
                <BooleanField
                    value={props.value}
                    trueIcon={<TrueIcon />}
                    falseIcon={<FalseIcon />}
                />
            )
        },
        {
            dataIndex: "isSended",
            title: translate("general.notificationstatus.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertNotificationStatusID(props.value)} color={ConvertNotificationStatusIDToColor(props.value)} />
            )
        },
        {
            dataIndex: "sendedDate",
            title: translate("pages.Notification.fields.sendedDate")
        },
        {
            dataIndex: "plannedSendDate",
            title: translate("pages.Notification.fields.plannedSendDate")
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <ShowButton
                    size="small"
                    recordItemId={props.record.id}
                    value={translate("general.button.show")}
                />
            )
        }
    ]

    return (
        <ListPage listProps={{
            title: translate("pages.Notification.title"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Configuration.NotificationManager"),
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={tableQueryResult.isFetching} standartOnRowRedirectToShow={true} />
    );
};