import { useTable, EditButton } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import IParameter from "entities/configuration/IParameter";
import { CheckPermissionsV2 } from "helpers/permissionHelper";

export const ParameterList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {

    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IParameter>({
        syncWithLocation: true
    });
    const translate = useTranslate();

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.Parameter.fields.id"),
            type: "number"
        },
        {
            key: "Name",
            title: translate("pages.Parameter.fields.name"),
            type: "string"
        },
        {
            key: "Module",
            title: translate("pages.Parameter.fields.module"),
            type: "string"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.Parameter.fields.id")
        },
        {
            dataIndex: "module",
            title: translate("pages.Parameter.fields.module")
        },
        {
            dataIndex: "name",
            title: translate("pages.Parameter.fields.name")
        },
        {
            dataIndex: "value",
            title: translate("pages.Parameter.fields.value")
        }
    ]

    if (CheckPermissionsV2("Update", "ParameterManager")) {
        columns.push(
            {
                dataIndex: "actions",
                title: translate("general.button.actions"),
                render: (props: RenderComponentProps) => (
                    <EditButton
                        size="small"
                        recordItemId={props.record.id}
                    />
                )
            });
    }

    return (
        <ListPage listProps={{
            title: translate("pages.Parameter.title"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Configuration.ParameterManager"),
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={queryResult.isFetching} />
    );
};