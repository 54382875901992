import React, { useEffect } from "react";
import { useCustom, useApiUrl } from "@refinedev/core";
import IEnumModel from "entities/constant/IEnumModel";


export const useConstantValueFromServiceString = (resource:string) => {
    const API_URL = useApiUrl();
    const url = `${API_URL}/constant/${resource}`;

    const response = useCustom<{
        data: Array<IEnumModel>
    }>({ url, method: "get" });

    var result:Array<any> = [];

    if(response?.data){
        var _data = response?.data.data.data;
        _data.forEach((element:any) => {
            result.push({
                label:element.label,
                value:element.value
            });
        });
    }

    return {
        selectProps:{
            options:result,
            loading:response.isLoading,
            showSearch:false,
            filterOption:false,
        }
    };
};