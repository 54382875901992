import { useCallback, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Text } from "../../../../components/fields/text";

import "./style.css";
import { Button, Card, Col, Divider, InputNumber, Row, Space, Typography } from 'antd';

import { DeleteOutlined } from "@ant-design/icons";

export interface DecisionNodeData {
    nodeId: string;
    value: number;
}

export const DecisionNode = ({ id, data, isConnectable }: { id: string, data: any, isConnectable: boolean }) => {
    const onChange = (value: any) => {
        setValue(value);
    };
    const [removeLoading, setRemoveLoading] = useState(false);
    const [value, setValue] = useState(0);

    return (
        <Card title={
            <Button loading={removeLoading} onClick={() => {
                setRemoveLoading(true);
                data.onRemove(id).finally(() => {
                    setRemoveLoading(false)
                });
            }} size='small'>
                {
                    removeLoading ? "" : <DeleteOutlined />
                }
            </Button>
        }>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div>
                <Text size='sm' >Value: </Text >
                <InputNumber onChange={onChange} value={value} />
            </div>
            <Handle
                type="source"
                position={Position.Left}
                id="lessthansource"
                isConnectable={isConnectable}
            />
            <Handle type="source" position={Position.Right} id="greaterthansource" isConnectable={isConnectable} />
        </Card>
    );
};