import { OpenNotificationParams } from "@refinedev/core";
import { IServiceResult } from "interfaces/Results/ServiceResult";
import { IServiceResultMessage } from "interfaces/Results/ServiceResultMessage";

export const getAllMessages = (response: IServiceResult) => {
    var successMessages = response.messages.filter(op => op.isSuccess);
    var warningMessages = response.messages.filter(op => op.isWarning);
    var errorMessages = response.messages.filter(op => op.isError);

    return {
        success: successMessages.map((item) => { return item.description }),
        warning: warningMessages.map((item) => { return item.description }),
        error: errorMessages.map((item) => { return item.description }),
    }
};

export const getAllMessagesFromArray = (messages: IServiceResultMessage[]) => {
    var successMessages = messages.filter(op => op.isSuccess);
    var warningMessages = messages.filter(op => op.isWarning);
    var errorMessages = messages.filter(op => op.isError);

    return {
        success: successMessages,
        warning: warningMessages,
        error: errorMessages,
    }
};


export const useFormErrorHandler = (data: any, values: any, resource: any) => {
    var messages = getAllMessagesFromArray(data?.response?.data?.messages ?? []);

    let defaultMessage: IServiceResultMessage = {
        isError: true,
        code: "E0",
        description: "Bilinmeyen bir hata oluştu",
        isSuccess: false,
        isWarning: false
    }

    var errorMessage = messages?.error.at(0) ?? defaultMessage;

    let result: OpenNotificationParams = {
        message: `${errorMessage.description}`,
        description: `Hata Kodu: ${errorMessage.code}`,
        type: "error",
    }

    return result;
}

export const useFormSuccessHandler = (data: any, values: any, resource: any) => {
    var messages = getAllMessagesFromArray(data?.response?.data?.messages ?? []);

    let defaultMessage: IServiceResultMessage = {
        isError: false,
        code: "S1",
        description: "İşlem Başarılı",
        isSuccess: true,
        isWarning: false
    }

    var successMessage = messages?.success.at(0) ?? defaultMessage;

    let result: OpenNotificationParams = {
        message: `${successMessage.description}`,
        description: `İşlem Kodu: ${successMessage.code}`,
        type: "success",
    }

    return result;
}