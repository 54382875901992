
import { ICost } from "../../../entities/expense/ICost";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor, ConvertBoolean, ConvertBooleanToColor, ConvertCostStatusToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { axiosInstance } from "providers/authProvider";
import { values } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import { IEmployee } from "entities/visa/IEmployee";
import { useSelectCustomUrl } from "hooks";
import { IResourceComponentsProps, useNavigation, useTranslate } from "@refinedev/core";
import { BooleanField, NumberField, TagField, useModalForm, useTable } from "@refinedev/antd";
import { Dropdown, Form, Input, Menu, Modal, Select } from "antd";
import { EyeOutlined, CheckOutlined, CloseOutlined, FormOutlined, MoreOutlined } from "@ant-design/icons";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { DateField } from "components/fields/date";

const approve = (costId: string, description: string) => {
    return axiosInstance.post("CostApprovement/approveExpense?costId=" + costId + "&description=" + description);
}

const reject = (costId: string, description: string) => {
    return axiosInstance.post("CostApprovement/rejectExpense?costId=" + costId + "&description=" + description);
}

const sendFix = (costId: string, description: string, userId: string) => {
    return axiosInstance.post("CostApprovement/fixExpense?costId=" + costId + "&description=" + description + "&userId=" + userId);
}

export const ApprovalList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<ICost>({
        resource: "CostApprovement"
    });


    const translate = useTranslate();

    const moreMenu = (record: ICost) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item

                key="show"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EyeOutlined
                        style={{
                            color: "green",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                disabled={loading}
                onClick={() => {
                    push(`/Expense/Costs/${record.id}/show`);
                }}
            >
                {translate("buttons.show")}
            </Menu.Item>
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CheckOutlined
                        style={{
                            color: "green",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                disabled={loading}
                onClick={() => {
                    setCurrentActID(1);
                    setCurrentCostID(record.id.toString());
                    previousEmployeeModalShow();
                    //setEditId?.(record.id.toString());
                }}
            >
                {translate("buttons.approve")}
            </Menu.Item>
            <Menu.Item
                key="reject"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloseOutlined
                        style={{
                            color: "red",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                disabled={loading}
                onClick={() => {
                    setCurrentActID(2);
                    setCurrentCostID(record.id.toString());
                    previousEmployeeModalShow();
                }}
            >
                {translate("buttons.reject")}
            </Menu.Item>
            <Menu.Item
                key="sendFix"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                disabled={loading}
                onClick={() => {
                    setCurrentActID(3);
                    setCurrentCostID(record.id.toString());
                    previousApproversQuery.refetch();
                    previousEmployeeModalShow();
                }}
            >
                {translate("buttons.sendFix")}
            </Menu.Item>
        </Menu>
    );

    const [loading, setLoading] = useState(false);
    const [currentCostID, setCurrentCostID] = useState("");
    const [currentActID, setCurrentActID] = useState(0);
    const [form] = Form.useForm();

    const {
        modalProps: previousEmployeeModalProps,
        formProps: previousEmployeeFormProps,
        show: previousEmployeeModalShow,
        close: previousEmployeeModalClose,
        onFinish: previousEmployeeModelOnFinish,
        form: previousEmployeeModelForm
    } = useModalForm<IEmployee>({
        redirect: false,
        action: "create",
        warnWhenUnsavedChanges: false
    });

    const handleSubmit = (values: any) => {
        setLoading(true);
        switch (currentActID) {
            case 1:
                approve(currentCostID, values.description).finally(() => {
                    setLoading(false);
                    queryResult.refetch();
                });
                break;
            case 2:
                reject(currentCostID, values.description).finally(() => {
                    setLoading(false);
                    queryResult.refetch();
                });
                break;
            case 3:
                var userId = previousEmployeeModelForm.getFieldValue("userId") as string;
                sendFix(currentCostID, values.description, userId).finally(() => {
                    setLoading(false);
                    queryResult.refetch();
                });
                break;
            default: break;

        }

        setCurrentActID(0);
        setCurrentCostID("");
        previousEmployeeModalClose();
    }

    const { selectProps, query: previousApproversQuery } = useSelectCustomUrl({
        url: "Cost/getpreviousapprovers?costId=" + currentCostID,
        optionIdName: "id",
        optionLabelName: "label",
        enabled: currentCostID != ""
    });


    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.cost.fields.id"),
            type: "number"
        },
        {
            key: "Date",
            title: translate("pages.cost.fields.date"),
            type: "string"
        },
        {
            key: "TotalPrice",
            title: translate("pages.cost.fields.totalPrice"),
            type: "number"
        },
        {
            key: "TotalTax",
            title: translate("pages.cost.fields.totalTax"),
            type: "number"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.cost.fields.id")
        },
        {
            dataIndex: "date",
            title: translate("pages.cost.fields.date"),
            render: (props: RenderComponentProps) => (
                <DateField value={props.value} format="LL" />

            )
        },
        {
            dataIndex: "description",
            title: translate("pages.cost.fields.description")
        },
        {
            dataIndex: ["department", "name"],
            title: translate("pages.cost.fields.department")
        },
        {
            dataIndex: "totalPrice",
            title: translate("pages.cost.fields.totalPrice"),
            render: (props: RenderComponentProps) => (
                <NumberField
                    value={props.value}
                />
            )
        },
        {
            dataIndex: "tax",
            title: translate("pages.cost.fields.totalTax"),
            render: (props: RenderComponentProps) => (
                <NumberField
                    value={props.value}
                />
            )
        },
        {
            dataIndex: ["employee", "label"],
            title: translate("pages.cost.fields.employee")
        },
        {
            dataIndex: "costStatusType",
            title: translate("general.status.coststatustype"),
            render: (props: RenderComponentProps) => {



                return (
                    <TagField value={props.value.name} color={ConvertCostStatusToColor(props.value.id)} />
                )
            }
        },
        {
            dataIndex: "currentLevel",
            title: translate("pages.cost.fields.currentLevel"),
            render: (props: RenderComponentProps) => (
                <NumberField
                    value={props.value}
                />
            )
        },
        {
            dataIndex: "approvementLevel",
            title: translate("pages.cost.fields.approvementLevel"),
            render: (props: RenderComponentProps) => (
                <NumberField
                    value={props.value}
                />
            )
        },
        {
            dataIndex: "employeeDepartmentAprrovementRequired",
            title: translate("pages.cost.fields.employeeDepartmentAprrovementRequired.title"),
            render: (props: RenderComponentProps) => (
                <BooleanField
                    value={props.value}
                    trueIcon={<CheckCircleOutlined />}
                    falseIcon={<CloseCircleOutlined />}
                    valueLabelTrue={translate("pages.cost.fields.employeeDepartmentAprrovementRequired.labeltrue")}
                    valueLabelFalse={translate("pages.cost.fields.employeeDepartmentAprrovementRequired.labelfalse")}
                />
            )
        },
        {
            dataIndex: "targetDepartmentApprovementRequired",
            title: translate("pages.cost.fields.targetDepartmentApprovementRequired.title"),
            render: (props: RenderComponentProps) => (
                <BooleanField
                    value={props.value}
                    trueIcon={<CheckCircleOutlined />}
                    falseIcon={<CloseCircleOutlined />}
                    valueLabelTrue={translate("pages.cost.fields.targetDepartmentApprovementRequired.labeltrue")}
                    valueLabelFalse={translate("pages.cost.fields.targetDepartmentApprovementRequired.labelfalse")}
                />
            )
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <Dropdown
                    overlay={moreMenu(props.record)}
                    trigger={["click"]}
                >
                    <MoreOutlined
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            fontSize: 24,
                        }}
                    />
                </Dropdown>

            )
        }
    ]

    const { list, push } = useNavigation();

    return (
        <>
            <ListPage listProps={{
                title: translate("menu.expense.approval"),
                canCreate: false
            }}
                tableProps={tableProps}
                columns={columns}
                filters={filters}
                rowKey="id"
                setFilters={setFilters}
                setSorter={setSorter}
                isLoading={queryResult.isFetching}
                onRow={(record: ICost, rowIndex: number) => {
                    return {
                        onClick: () => { }, // click row
                        onDoubleClick: () => { push(`/Expense/Costs/${record.id}/show`); }, // double click row
                        onContextMenu: () => { }, // right button click row
                        onMouseEnter: () => { }, // mouse enter row
                        onMouseLeave: () => { }, // mouse leave row
                    };
                }} />

            <Modal
                {...previousEmployeeModalProps} >
                <Form {...previousEmployeeFormProps} onFinish={handleSubmit}>
                    <Form.Item label={translate("pages.cost.fields.description")} name="description">
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                    </Form.Item>
                    {(currentActID == 3) &&

                        <Form.Item label={translate("pages.cost.fields.previouseuser")} name="userId">
                            <Select {...selectProps} />
                        </Form.Item>
                    }
                </Form>
            </Modal>
        </>

    );
};