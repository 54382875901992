import {
  Refine,
  Authenticated,
  CanAccess,
  useNotification,
  OpenNotificationParams,
} from "@refinedev/core";
import { ThemedLayoutV2, useNotificationProvider } from "@refinedev/antd";
import { theme, ConfigProvider } from "antd";
import routerProvider, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { LoginPage } from "pages/auth/login";
import axios, { AxiosResponse } from "axios";
import { useState } from "react";

import { RefineKbarProvider, RefineKbar } from "@refinedev/kbar";

import { authProvider, axiosInstance } from "providers/authProvider";
import { JsonServer } from "providers/dataProvider";

import { PerformanceDashboardPage } from "pages/performancedashboard";
import { ForgetPasswordPage } from "pages/auth/forgetpassword";
import { GetServiceUrl } from "helpers/configManager";

import { Header, CustomSider, ApplicationTitle } from "components/layout";
import { OffLayoutArea } from "components/offLayoutArea";

import tr_TR from "antd/locale/tr_TR";
import en_US from "antd/locale/en_US";

import "dayjs/locale/en";
import "dayjs/locale/tr";
import dayjs from "dayjs";

import "@refinedev/antd/dist/reset.css";
import "styles/antd.css";

import { useDarkMode, useEffectOnce, useLocalStorage } from "usehooks-ts";

//resources
import { EntityLogList, EntityLogShow } from "pages/configuration/entitylog";
import { ErrorLogList, ErrorLogShow } from "pages/configuration/errorlog";
import { RequestLogList, RequestLogShow } from "pages/configuration/requestlog";
import {
  TranslationList,
  TranslationEdit,
} from "pages/configuration/translation";
import { ClientRoleList, ClientRoleEdit } from "pages/visa/clientrole";
import {
  ForbiddenUsernameList,
  ForbiddenUsernameEdit,
} from "pages/visa/forbiddenUsername";
import { PermissionList, PermissonEdit } from "pages/visa/permission";
import { RoleList, RoleEdit } from "pages/visa/role";
import { UserList, UserEdit } from "pages/visa/user";
import { ParameterList, ParameterEdit } from "pages/configuration/parameter";
import {
  MailTemplateList,
  MailTemplateEdit,
} from "pages/configuration/mailtemplate";
import { MailTaskList, MailTaskShow } from "pages/configuration/mailtask";
import { MobileAppNotificationLogList } from "pages/configuration/mobileappnotificationlog";
import { ErrorComponent } from "components/page/error";
import {
  MailAccountList,
  MailAccountEdit,
} from "pages/configuration/mailaccount";
import { RenewForgottenPasswordPage } from "pages/auth/renewforgottenpassword";
import { NotificationList } from "pages/configuration/notification";
import { EmployeeEdit, EmployeeList } from "pages/visa/employee";
import {
  AnnouncementEdit,
  AnnouncementList,
} from "pages/definition/announcement";
import { CostTypeEdit, CostTypeList } from "pages/definition/costtype";
import { CurrencyEdit, CurrencyList } from "pages/definition/currency";
import { DepartmentEdit, DepartmentList } from "pages/definition/department";
import {
  MainCostCategoryEdit,
  MainCostCategoryList,
} from "pages/definition/mainCostCategory";
import { WorkflowDesigner, WorkflowEdit, WorkflowList } from "pages/definition/workflow";
import { CategoryCostReportList } from "pages/report/categorycostreport";
import { DashboardPage } from "pages/dashboard";
import { ApprovalList } from "pages/expense/approval";
import { CostCreate, CostEdit, CostList, CostShow, SubEmployeeCostList } from "pages/expense/cost";
import pluralize from "pluralize";
import { pluralizeTr } from "helpers/stringHelper";
import {
  ApplicationSettings,
  IApplicationSettings,
} from "entities/configuration/IApplicationSettings";
import { IServiceObjectResult } from "interfaces/Results/ServiceObjectResult";
import {
  MessagePayload,
  Messaging,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { ApplicationSettingsEdit } from "pages/configuration/applicationsettings";
import Config from "config.json";
import { VerificationShow } from "pages/expense/approval/show";
import { RoleAssignmentLogList } from "pages/configuration/roleassignmentlog";
import { CategoryCostReportListV2 } from "pages/report/detailed-report";
import { CostReportList, CostReportEdit } from "pages/report/costreport";
import { CostReportDemandList } from "pages/report/costreportdemand";
import { SSSList } from "pages/content/SSS/list";
import { SSSCreate } from "pages/content/SSS/create";
import { SSSEdit } from "pages/content/SSS/edit";

function changeGlobalDateFormat(lang: string) {
  try {
    dayjs.locale(lang);
  } catch (e) {
    console.error(e);
  }
}

function App() {
  var initialDarkMode: boolean = false;
  var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

  if (localStorageTheme === "true") {
    initialDarkMode = true;
  }

  const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode);

  let initialAppSettings: ApplicationSettings = {
    defaultCurrencyID: 1,
    primaryThemeColor: "#311b92",
    logoDarkUrl: "",
    logoLightUrl: "",
    logoDarkCollapsedUrl: "",
    logoLightCollapsedUrl: "",
    minimumAmountRequiredForApproval: 0,
    oldestCostDatePeriod: 30
  };

  const [applicationSettings, setApplicationSettings] = useLocalStorage('ApplicationSettings', initialAppSettings)

  axios.defaults.baseURL = GetServiceUrl();

  // Initialize Firebase
  const app = initializeApp(Config.firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);

  const { open, close } = useNotification();

  onMessage(messaging, (payload: MessagePayload) => {
    const notif: OpenNotificationParams = {
      message: payload.notification?.title ?? "Expinova",
      description: payload.notification?.body ?? "",
      type: "progress",
    };

    open?.(notif);
  });


  function getInitialVariables(appSetFunc: any) {
    localStorage.setItem("appsettings_updateDate", new Date().toString());

    axios.get("ApplicationSetting/GetInitialSettings").then((response: AxiosResponse<IServiceObjectResult>) => {
      let serviceResponse = response.data;;

      if (serviceResponse.success) {
        let settings: IApplicationSettings = serviceResponse.data;
        appSetFunc({
          primaryThemeColor: settings.primaryThemeColor,
          logoDarkUrl: settings.logoDark.at(0)?.url ?? "",
          logoLightUrl: settings.logoLight.at(0)?.url ?? "",
          logoLightCollapsedUrl: settings.logoLightCollapsed.at(0)?.url ?? "",
          logoDarkCollapsedUrl: settings.logoDarkCollapsed.at(0)?.url ?? "",
          defaultCurrencyID: settings.defaultCurrencyID,
          minimumAmountRequiredForApproval: settings.minimumAmountRequiredForApproval,
          oldestCostDatePeriod: settings.oldestCostDatePeriod
        });
      }
    })
  }


  useEffectOnce(() => {
    getInitialVariables(setApplicationSettings);
  });

  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => {
      if (lang == "tr-TR") {
        changeGlobalDateFormat("tr");
      } else {
        changeGlobalDateFormat("en");
      }
      return i18n.changeLanguage(lang);
    },
    getLocale: () => i18n.language,
  };

  if (i18nProvider.getLocale() == "tr-TR") {
    changeGlobalDateFormat("tr");
  } else {
    changeGlobalDateFormat("en");
  }

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          algorithm: !isDarkMode ? theme.defaultAlgorithm : theme.darkAlgorithm,
          token: {
            colorPrimary: applicationSettings.primaryThemeColor,
          },
        }}
        locale={i18n.language == "tr-TR" ? tr_TR : en_US}
      >
        <RefineKbarProvider>
          <Refine
            dataProvider={JsonServer(GetServiceUrl(), axiosInstance)}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            options={{
              textTransformers: {
                plural: (text) => {
                  if (i18n.language == "tr-TR") return pluralizeTr(text);
                  else if (i18n.language == "en-US")
                    return pluralize.plural(text);

                  return text;
                },
              },
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              disableTelemetry: true,
              reactQuery: {
                clientConfig: {
                  defaultOptions: {
                    queries: {
                      refetchOnWindowFocus: false,
                    },
                  },
                },
              },
            }}
            notificationProvider={useNotificationProvider}
            routerProvider={routerProvider}
            resources={[
              {
                name: "performancedashboard",
                list: "/performancedashboard",
                meta: {
                  label: i18n.t("menu.dashboard.performance"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.RequestLogManager",
                      Method: "CalculateApdexScore",
                    },
                    {
                      Controller:
                        "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetErrorPercentage",
                    },
                    {
                      Controller:
                        "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetActiveRequestCount",
                    },
                    {
                      Controller:
                        "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetCpuUsage",
                    },
                    {
                      Controller:
                        "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetRequestInfoPerTimePerEndpoint",
                    },
                    {
                      Controller:
                        "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetRequestCountPerTime",
                    },
                    {
                      Controller:
                        "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetDataOutPerTime",
                    },
                  ],
                },
              },
              {
                name: "dashboard",
                list: "/dashboard",
                meta: {
                  label: i18n.t("menu.dashboard.dashboard"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.CostManager",
                      Method: "GetDashboardInformation"
                    }
                  ]
                },
              },

              {
                name: "Approval",
                meta: {
                  parent: i18n.t("menu.expense.title"),
                  label: i18n.t("menu.expense.approval"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Expense.CostManager",
                      Method: "GetApprovementList"
                    }
                  ]
                },
                list: "/expense/approvals"
              },
              {
                name: "Cost",
                meta: {
                  parent: i18n.t("menu.expense.title"),
                  label: i18n.t("menu.expense.cost"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Expense.CostManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/expense/costs",
                create: "/expense/costs/create",
                edit: "/expense/costs/:id/edit",
                show: "/expense/costs/:id:show"
              },
              {
                name: "Cost",
                meta: {
                  parent: i18n.t("menu.expense.title"),
                  label: i18n.t("menu.expense.costsubemployees"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Expense.CostManager",
                      Method: "GetAllList_MENU"
                    }
                  ]
                },
                list: "/expense/costs/subemployees",
                identifier: "CostSubEmployee"
              },
              {
                name: "User",
                meta: {
                  label: i18n.t("menu.visa.user"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Visa.UserManager",
                      Method: "GetUserList_MENU",
                    },
                  ],
                },
                list: "/visa/users",
                edit: "/visa/users/:id/edit",
                create: "/visa/users/create",
              },
              {
                name: "Employee",
                meta: {
                  label: i18n.t("menu.visa.employee"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Visa.EmployeeManager",
                      Method: "GetUserList_MENU",
                    },
                  ],
                },
                list: "/visa/employees",
                edit: "/visa/employees/:id/edit",
                create: "/visa/employees/create",
              },
              {
                name: "Permission",
                meta: {
                  label: i18n.t("menu.visa.permission"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Visa.PermissionManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/visa/permissions",
                edit: "/visa/permissions/:id/edit",
              },
              {
                name: "Role",
                meta: {
                  label: i18n.t("menu.visa.role"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Visa.RoleManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/visa/roles",
                edit: "/visa/roles/:id/edit",
                create: "/visa/roles/create",
              },
              {
                name: "ClientPermission",
                meta: {
                  label: i18n.t("menu.visa.clientpermission"),
                  parent: i18n.t("menu.visa.title"),
                },
              },
              {
                name: "ClientRole",
                meta: {
                  label: i18n.t("menu.visa.clientrole"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Visa.ClientRoleManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/visa/clientroles",
                edit: "/visa/clientroles/:id/edit",
                create: "/visa/clientroles/create",
              },
              {
                name: "ForbiddenUsername",
                meta: {
                  label: i18n.t("menu.visa.forbiddenUsername"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Visa.ForbiddenUsernameManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/visa/forbiddenusernames",
                edit: "/visa/forbiddenusernames/:id/edit",
                create: "/visa/forbiddenusernames/create",
              },
              {
                name: "Translation",
                meta: {
                  label: i18n.t("menu.configuration.translation"),
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.TranslationManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/configuration/translations",
                edit: "/configuration/translations/:id/edit",
                create: "/configuration/translations/create",
              },
              {
                name: "Parameter",
                meta: {
                  label: i18n.t("menu.configuration.parameter"),
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.ParameterManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/configuration/parameters",
                edit: "/configuration/parameters/:id/edit",
                create: "/configuration/parameters/create",
              },
              {
                name: "MailTemplate",
                meta: {
                  label: i18n.t("menu.configuration.mailtemplate"),
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.MailTemplateManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/configuration/mailtemplates",
                edit: "/configuration/mailtemplates/:id/edit",
                create: "/configuration/mailtemplates/create",
              },
              {
                name: "MailTask",
                meta: {
                  label: i18n.t("menu.configuration.mailtask"),
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.MailTaskManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/configuration/mailtasks",
                show: "/configuration/mailtasks/:id/show",
              },
              {
                name: "MailAccount",
                meta: {
                  label: i18n.t("menu.configuration.mailaccount"),
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.MailAccountManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/configuration/mailaccounts",
                edit: "/configuration/mailaccounts/:id/edit",
              },
              {
                name: "ApplicationSetting",
                meta: {
                  label: i18n.t("menu.configuration.applicationsetting"),
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.ApplicationSetting",
                      Method: "Update",
                    },
                  ],
                },
                list: "/configuration/applicationsettings",
              },
              {
                name: "Log",
                meta: {
                  label: i18n.t("menu.log.errorlog"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.LogManager",
                      Method: "GetLogs_MENU",
                    },
                  ],
                },
                list: "/log/errorlogs",
                show: "/log/errorlogs/:id/show",
              },
              {
                parentName: "Logs",
                name: "RequestLog",
                meta: {
                  label: i18n.t("menu.log.requestlog"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetLogs_MENU",
                    },
                  ],
                },
                list: "/log/requestlogs",
                show: "/log/requestlogs/:id/show",
              },
              {
                name: "EntityLog",
                meta: {
                  label: i18n.t("menu.log.auditLog"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.EntityLogManager",
                      Method: "GetLogs_MENU",
                    },
                  ],
                },
                list: "/log/entitylogs",
                show: "/log/entitylogs/:id/show",
              },
              {
                name: "MobileAppNotificationLog",
                meta: {
                  label: i18n.t("menu.log.mobileAppNotificationLog"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.MobileAppNotificationLogManager",
                      Method: "GetLogs_MENU",
                    },
                  ],
                },
                list: "/log/mobileappnotificationlogs",
              },
              {
                name: "RoleAssignmentLog",
                meta: {
                  label: i18n.t("menu.log.RoleAssignmentLog"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.RoleAssignmentLogManager",
                      Method: "GetLogs_MENU",
                    },
                  ],
                },
                list: "/log/roleassignmentlogs",
              },
              {
                name: "Notification",
                meta: {
                  label: i18n.t("menu.log.Notification"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Configuration.NotificationManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/log/notifications",
              },
              {
                name: "Announcement",
                meta: {
                  parent: i18n.t("menu.definition.title"),
                  label: i18n.t("menu.definition.announcement"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Definition.AnnouncementManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/definition/announcements",
                edit: "/definition/announcements/:id/edit",
                create: "/definition/announcements/create",
              },
              {
                name: "CostType",
                meta: {
                  parent: i18n.t("menu.definition.title"),
                  label: i18n.t("menu.definition.costtype"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Definition.CostTypeManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/definition/costtypes",
                edit: "/definition/costtypes/:id/edit",
                create: "/definition/costtypes/create",
              },
              {
                name: "Currency",
                meta: {
                  parent: i18n.t("menu.definition.title"),
                  label: i18n.t("menu.definition.currency"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Definition.CurrencyManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/definition/currencies",
                edit: "/definition/currencies/:id/edit",
                create: "/definition/currencies/create",
              },
              {
                name: "Department",
                meta: {
                  parent: i18n.t("menu.visa.title"),
                  label: i18n.t("menu.definition.department"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Definition.DepartmentManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/definition/departments",
                edit: "/definition/departments/:id/edit",
                create: "/definition/departments/create",
              },
              {
                name: "MainCostCategory",
                meta: {
                  parent: i18n.t("menu.definition.title"),
                  label: i18n.t("menu.definition.maincostcategories"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Definition.MainCostCategoryManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/definition/maincostcategories",
                edit: "/definition/maincostcategories/:id/edit",
                create: "/definition/maincostcategories/create",
              },
              {
                name: "Workflow",
                meta: {
                  parent: i18n.t("menu.definition.title"),
                  label: i18n.t("menu.definition.workflow"),
                  permissions: [
                    {
                      Controller:
                        "Business.Concrete.Definition.WorkflowManager",
                      Method: "GetList_MENU",
                    },
                  ],
                },
                list: "/definition/workflows",
                edit: "/definition/workflows/:id/edit",
                create: "/definition/workflows/create",
              },
              {
                name: "CostReport",
                meta: {
                  parent: i18n.t("menu.report.title"),
                  label: i18n.t("menu.report.costreport"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Expense.CostReportManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/expense/costreports",
                create: "/expense/costreports/create",
                edit: "/expense/costreports/:id/edit",
                show: "/expense/costreports/:id/show"
              },
              {
                name: "CostReportDemand",
                meta: {
                  parent: i18n.t("menu.report.title"),
                  label: i18n.t("menu.report.costreportdemands"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Expense.CostReportDemandManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/expense/costreportdemands",
              },
              {
                name: "Approval",
                meta: {
                  parent: i18n.t("menu.expense.title"),
                  label: i18n.t("menu.expense.approval"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Expense.ApprovalManager",
                      Method: "GetApprovementList"
                    }
                  ]
                },
                list: "/expense/approvals"
              },
              {
                name: "Cost",
                meta: {
                  parent: i18n.t("menu.expense.title"),
                  label: i18n.t("menu.expense.cost"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Expense.CostManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/expense/costs",
                create: "/expense/costs/create",
                edit: "/expense/costs/:id/edit",
                show: "/expense/costs/:id:show"
              },
              
              {
                name: "FAQ",
                list: "/content/SSS/list",
                meta: {
                  label: i18n.t("menu.faq.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Content.FAQManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                create: "/content/SSS/create",
                edit: "/content/SSS/:id/edit",
                show: "/expense/costs/:id:show"
              },
             
            ]}
          >
            <Routes>
              <Route
                path="/verification/:verificationkey"
                element={<VerificationShow />}
              />

              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource resource="dashboard" />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/forget-password"
                  element={<ForgetPasswordPage />}
                />

                <Route
                  path="/renewpass/:token"
                  element={<RenewForgottenPasswordPage />}
                />
              </Route>

              <Route
                element={
                  <Authenticated redirectOnFail="/login">
                    <ThemedLayoutV2
                      Sider={() => (
                        <CustomSider
                          Title={({ collapsed }) => (
                            <ApplicationTitle collapsed={collapsed} />
                          )}
                        />
                      )}
                      Header={Header}
                      Title={ApplicationTitle}
                      OffLayoutArea={OffLayoutArea}
                    >
                      <CanAccess fallback={<ErrorComponent status="403" />}>
                        <Outlet />
                      </CanAccess>
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<DashboardPage />} />

                <Route path="/dashboard">
                  <Route index element={<DashboardPage />} />
                </Route>

                <Route path="/performancedashboard">
                  <Route index element={<PerformanceDashboardPage />} />
                </Route>

                <Route path="/visa/users">
                  <Route index element={<UserList />} />
                  <Route path=":id/edit" element={<UserEdit />} />
                  <Route path="create" element={<UserEdit />} />
                </Route>

                <Route path="/content/SSS">
                  <Route index element={<SSSCreate />} />
                  <Route path="create" element={<SSSCreate />} />
                </Route>

                <Route path="/visa/employees">
                  <Route index element={<EmployeeList />} />
                  <Route path=":id/edit" element={<EmployeeEdit />} />
                  <Route path="create" element={<EmployeeEdit />} />
                </Route>

                <Route path="/visa/permissions">
                  <Route index element={<PermissionList />} />
                  <Route path=":id/edit" element={<PermissonEdit />} />
                  <Route path="create" element={<PermissonEdit />} />
                </Route>

                <Route path="/visa/roles">
                  <Route index element={<RoleList />} />
                  <Route path=":id/edit" element={<RoleEdit />} />
                  <Route path="create" element={<RoleEdit />} />
                </Route>

                <Route path="/visa/clientroles">
                  <Route index element={<ClientRoleList />} />
                  <Route path=":id/edit" element={<ClientRoleEdit />} />
                  <Route path="create" element={<ClientRoleEdit />} />
                </Route>

                <Route path="/visa/forbiddenusernames">
                  <Route index element={<ForbiddenUsernameList />} />
                  <Route path=":id/edit" element={<ForbiddenUsernameEdit />} />
                  <Route path="create" element={<ForbiddenUsernameEdit />} />
                </Route>

                <Route path="/configuration/translations">
                  <Route index element={<TranslationList />} />
                  <Route path=":id/edit" element={<TranslationEdit />} />
                  <Route path="create" element={<TranslationEdit />} />
                </Route>

                <Route path="/configuration/parameters">
                  <Route index element={<ParameterList />} />
                  <Route path=":id/edit" element={<ParameterEdit />} />
                  <Route path="create" element={<ParameterEdit />} />
                </Route>

                <Route path="/configuration/mailtemplates">
                  <Route index element={<MailTemplateList />} />
                  <Route path=":id/edit" element={<MailTemplateEdit />} />
                  <Route path="create" element={<MailTemplateEdit />} />
                </Route>

                <Route path="/configuration/mailtasks">
                  <Route index element={<MailTaskList />} />
                  <Route path=":id/show" element={<MailTaskShow />} />
                </Route>

                <Route path="/configuration/mailaccounts">
                  <Route index element={<MailAccountList />} />
                  <Route path=":id/edit" element={<MailAccountEdit />} />
                </Route>

                <Route path="/configuration/mailaccounts">
                  <Route index element={<MailAccountList />} />
                  <Route path=":id/edit" element={<MailAccountEdit />} />
                </Route>

                <Route path="/configuration/applicationsettings">
                  <Route index element={<ApplicationSettingsEdit />} />
                </Route>

                <Route path="/log/errorlogs">
                  <Route index element={<ErrorLogList />} />
                  <Route path=":id/show" element={<ErrorLogShow />} />
                </Route>

                <Route path="/log/requestlogs">
                  <Route index element={<RequestLogList />} />
                  <Route path=":id/show" element={<RequestLogShow />} />
                </Route>

                <Route path="/log/entitylogs">
                  <Route index element={<EntityLogList />} />
                  <Route path=":id/show" element={<EntityLogShow />} />
                </Route>

                <Route path="/log/mobileappnotificationlogs">
                  <Route index element={<MobileAppNotificationLogList />} />
                </Route>

                <Route path="/log/roleassignmentlogs">
                  <Route index element={<RoleAssignmentLogList />} />
                </Route>

                <Route path="/log/notifications">
                  <Route index element={<NotificationList />} />
                </Route>

                <Route path="/definition/announcements">
                  <Route index element={<AnnouncementList />} />
                  <Route path=":id/edit" element={<AnnouncementEdit />} />
                  <Route path="create" element={<AnnouncementEdit />} />
                </Route>

                <Route path="/definition/costtypes">
                  <Route index element={<CostTypeList />} />
                  <Route path=":id/edit" element={<CostTypeEdit />} />
                  <Route path="create" element={<CostTypeEdit />} />
                </Route>

                <Route path="/definition/currencies">
                  <Route index element={<CurrencyList />} />
                  <Route path=":id/edit" element={<CurrencyEdit />} />
                  <Route path="create" element={<CurrencyEdit />} />
                </Route>

                <Route path="/definition/departments">
                  <Route index element={<DepartmentList />} />
                  <Route path=":id/edit" element={<DepartmentEdit />} />
                  <Route path="create" element={<DepartmentEdit />} />
                </Route>

                <Route path="/definition/maincostcategories">
                  <Route index element={<MainCostCategoryList />} />
                  <Route path=":id/edit" element={<MainCostCategoryEdit />} />
                  <Route path="create" element={<MainCostCategoryEdit />} />
                </Route>

                <Route path="/definition/workflows">
                  <Route index element={<WorkflowList />} />
                  <Route path=":id/edit" element={<WorkflowEdit />} />
                  <Route path="create" element={<WorkflowEdit />} />
                  <Route path=":id/designer" element={<WorkflowDesigner />} />
                </Route>

                <Route path="/expense/costreports">
                  <Route index element={<CostReportList />} />
                  <Route path="create" element={<CostReportEdit />} />
                  <Route path=":id/edit" element={<CostReportEdit />} />
                </Route>

                <Route path="/expense/costreportdemands">
                  <Route index element={<CostReportDemandList />} />
                </Route>

                <Route path="/expense/approvals">
                  <Route index element={<ApprovalList />} />
                </Route>

                <Route path="/expense/costs">
                  <Route index element={<CostList />} />
                  <Route path="subemployees" element={<SubEmployeeCostList />} />
                  <Route path=":id/edit" element={<CostEdit />} />
                  <Route path=":id/show" element={<CostShow />} />
                  <Route path="create" element={<CostCreate />} />
                </Route>

                <Route path="/content/SSS">
                  <Route index element={<SSSList />} />
                  <Route path="list" element={<SSSList />} />
                  <Route path="create" element={<SSSCreate />} />
                  <Route path=":id/edit" element={<SSSEdit />} />
                </Route>


              </Route>

              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <ThemedLayoutV2
                      Sider={() => <CustomSider />}
                      Header={Header}
                      Title={ApplicationTitle}
                      OffLayoutArea={OffLayoutArea}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent status="404" />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
          </Refine>
        </RefineKbarProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
