import { useCustom, useApiUrl } from "@refinedev/core";

export type selectCustomUrlProps = {
    url: string,
    optionIdName: string,
    optionLabelName: string,
    enabled?: boolean
};

const defaultValue = {
    optionIdName: "id",
    optionLabelName: "name",
    enabled: true
}

export const useSelectCustomUrl = (props: selectCustomUrlProps) => {
    var _props: selectCustomUrlProps = {
        ...defaultValue,
        ...props
    };
    const API_URL = useApiUrl();
    const url = `${API_URL}/${_props.url}`;

    const query = useCustom<{
        data: Array<any>,
    }>({ url, method: "get", queryOptions: { enabled: props.enabled } });

    var result: Array<any> = [];

    if (query?.data) {
        var _data = query?.data.data.data;
        _data.forEach((element: any) => {
            result.push({
                label: element[_props.optionLabelName],
                value: element[_props.optionIdName]
            });
        });
    }

    return {
        selectProps: {
            options: result,
            loading: query.isLoading,
            showSearch: false,
            filterOption: false,
        },
        query
    };
};