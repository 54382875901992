import * as crypto from "crypto-js";
import Config from "config.json";

export const encrypt = (text:string) => {    
    return crypto.AES.encrypt(text, Config.secretKey).toString();
}

export const decrypt = (text:string) => {
    var bytes = crypto.AES.decrypt(text, Config.secretKey);
    return bytes.toString(crypto.enc.Utf8);
}