
import { IMainCostCategory } from "../../../entities/definition/IMainCostCategory";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor, ConvertBoolean, ConvertBooleanToColor, ConvertCostStatusToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { ICost } from "entities/expense/ICost";
import { ICostActionHistory } from "entities/expense/ICostActionHistory";
import { ICostItem } from "entities/expense/ICostItem";
import { IMedia } from "entities/content/IMedia";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { IDepartment } from "entities/definition/IDepartment";
import { useConstantValueFromService } from "hooks";
import { Dropdown, Menu, Table, Tabs } from "antd";
import { IResourceComponentsProps, useGetIdentity, useTranslate } from "@refinedev/core";
import { DateField, EditButton, FileField, List, NumberField, TagField, useSelect, useTable } from "@refinedev/antd";
import { EyeOutlined, CheckOutlined, CloseOutlined, FormOutlined, MoreOutlined } from "@ant-design/icons";
import { useGetIdentityInterface } from "models/useGetIdentityInterface";
import { DownSquareTwoTone  } from '@ant-design/icons';
import { UpSquareTwoTone  } from '@ant-design/icons';
import { useState } from "react";
const { TabPane } = Tabs;

export const SubEmployeeCostList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {

    const { data: identity } = useGetIdentity<useGetIdentityInterface>();

    const { tableProps, tableQueryResult, setFilters, sorter, filters: tableFilters } = useTable<ICost>({
        meta: {
            controller: "getAllList"
        },
        resource: "Cost",
        permanentSorter: [
            {
                field: "DateCreated",
                order: "desc"
            }
        ]
    });



    const { selectProps: costStatusTypeSelectProps } = useConstantValueFromService("getcoststatustype");

    const { selectProps: departmentSelectProps } = useSelect<IDepartment>({
        resource: "Department",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "name",
                operator: "contains",
                value
            }
        ]
    });

    const moreMenu = (record: ICostItem) => {
        return (
            <Menu
                mode="vertical"
                onClick={({ domEvent }) => domEvent.stopPropagation()}
            >
                {record?.medias?.map((item: IMedia) => {
                    return (<Menu.Item
                        key={item.uid}
                    >
                        <FileField src={item.url} title={item.name} />
                    </Menu.Item>)
                })}

            </Menu>
        )
    }; 

    const expandedRowIcon = ({ expanded, onExpand, record }: any) => {
        if (record.costItems && record.costItems.length > 0) {
            const iconSize = 20;
    
            return expanded ? (
                <DownSquareTwoTone onClick={(e) => onExpand(record, e)} style={{ fontSize: iconSize }} />
            ) : (
                <UpSquareTwoTone onClick={(e) => onExpand(record, e)} style={{ fontSize: iconSize }} />
            );
        }
        return null;
    };
    
    const translate = useTranslate();
    const expandedRowRender = (record: ICost) => {

        return (
            <Tabs>
                <TabPane tab={translate("menu.expense.costitem")} key="1">
                    <List  title={""} breadcrumb={<></>} canCreate={false} >
                        <Table
                            dataSource={record.costItems}
                            rowKey="id"
                          
                            footer={(currentPageData: any) => {
                                let totalPrice = 0;
                                let totalTax = 0;

                                currentPageData.map((item: ICostItem) => {
                                    totalPrice += item.totalPrice;
                                    totalTax += item.tax
                                });

                                return (
                                    <div>
                                        <span>{translate("pages.costitem.fields.totalPrice")}: {totalPrice}</span>
                                        <br />
                                        <span>{translate("pages.costitem.fields.tax")}: {totalTax}</span>
                                    </div>
                                )
                            }}
                        >
                            <Table.Column<ICostItem>
                                dataIndex="date"
                                title={translate("pages.cost.fields.date")}
                                render={(value: any) => <DateField format="LLL" value={value} />}
                            />

                            <Table.Column dataIndex="description" title={translate("pages.costitem.fields.description")} />
                            <Table.Column dataIndex={["subCostCategory", "label"]} title={translate("pages.costitem.fields.subCostCategory")} />
                            <Table.Column dataIndex="categoryDescription" title={translate("pages.costitem.fields.categoryDescription")} />
                            <Table.Column dataIndex={["costType", "name"]} title={translate("pages.costitem.fields.costtype")} />
                            <Table.Column dataIndex="documentNo" title={translate("pages.costitem.fields.documentNo")} />
                            <Table.Column dataIndex="firmName" title={translate("pages.costitem.fields.firmName")} />

                            <Table.Column<ICostItem>
                                key="totalPrice"
                                
                                title={translate("pages.costitem.fields.totalPrice")}
                                dataIndex="totalPrice"
                                render={(value) => (
                                    <NumberField
                                        value={value}
                                    />
                                )}
                            />

                            <Table.Column<ICostItem>
                                key="tax"
                                title={translate("pages.costitem.fields.tax")}
                                dataIndex="tax"
                                render={(value) => (
                                    <NumberField
                                        value={value}
                                    />
                                )}
                            />

                        <Table.Column<ICostItem>
                                title={translate("general.button.files")}
                                dataIndex="actions"
                                render={(_text, record): React.ReactNode => {
                                    return (
                                        <Dropdown
                                            overlay={moreMenu(record)}
                                            trigger={["click"]}
                                        >
                                            <MoreOutlined
                                                onClick={(e) => e.stopPropagation()}
                                                style={{
                                                    fontSize: 24,
                                                }}
                                            />
                                        </Dropdown>
                                    );
                                }}
                            />
                        </Table>
                    </List>
                </TabPane>
                <TabPane tab={translate("menu.expense.costactionhistory")} key="2">
                    <List title="" breadcrumb={<></>} canCreate={false}>
                        <Table
                            dataSource={record.costActionHistories.sort((n1, n2) => n2.id - n1.id)}
                            rowKey="id"
                        >
                            <Table.Column
                                dataIndex="dateCreated"
                                title={translate("pages.cost.fields.actiondate")}
                                render={(value: any) => <DateField format="LLL" value={value} />}
                            />

                            <Table.Column dataIndex="description" title={translate("pages.cost.fields.description")} />
                            <Table.Column
                                dataIndex="oldCostStatusType"
                                title={translate("pages.cost.fields.oldcoststatustype")}
                                render={(value: any) => <TagField value={value.name} color={ConvertCostStatusToColor(value.id)} />}
                            />
                            <Table.Column
                                dataIndex="newCostStatusType"
                                title={translate("pages.cost.fields.newcoststatustype")}
                                render={(value: any) => <TagField value={value.name} color={ConvertCostStatusToColor(value.id)} />}
                            />
                            <Table.Column dataIndex="oldCurrentLevel" title={translate("pages.cost.fields.oldCurrentLevel")} />
                            <Table.Column dataIndex="newCurrentLevel" title={translate("pages.cost.fields.newCurrentLevel")} />
                            <Table.Column dataIndex="approvementLevel" title={translate("pages.cost.fields.approvementLevel")} />
                            <Table.Column dataIndex={["referenceUser", "label"]} title={translate("pages.costitem.fields.referenceuser")} />
                        </Table>
                    </List>
                </TabPane>
            </Tabs>

        )
                            
                            
    };

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            type: "number",
            title: translate("pages.cost.fields.id"),
        },
        {
            key: "Date",
            type: "date",
            title: translate("pages.cost.fields.date"),
        },
        {
            key: "DepartmentID",
            type: "number",
            title: translate("pages.cost.fields.department"),
            selectProps: departmentSelectProps
        },
        {
            key: "CostStatusTypeID",
            type: "number",
            title: translate("pages.cost.fields.coststatustype"),
            selectProps: costStatusTypeSelectProps
        }
    ]

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.cost.fields.id"),
            sortable: true,
            entityName: "ID"
        },
        {
            dataIndex: "date",
            title: translate("pages.cost.fields.date"),
            render: (props: RenderComponentProps) => (
                <DateField format="LL" value={props.value} />
            ),
            sortable: true,
            entityName: "Date"
        },
        {
            dataIndex: ["employee", "label"],
            title: translate("pages.cost.fields.employee"),
        },
        {
            dataIndex: "description",
            title: translate("pages.cost.fields.name"),
        },
        {
            dataIndex: ["department", "name"],
            title: translate("pages.cost.fields.department"),
        },
        {
            dataIndex: "totalPrice",
            title: translate("pages.cost.fields.totalPrice"),
            render: (props: RenderComponentProps) => (
                <NumberField
                    value={props.value}
                />
            )
        },
        {
            dataIndex: "tax",
            title: translate("pages.cost.fields.tax"),
            render: (props: RenderComponentProps) => (
                <NumberField
                    value={props.value}
                />
            )
        },
        {
            dataIndex: "costStatusType",
            title: translate("pages.cost.fields.coststatustype"),
            render: (props: RenderComponentProps) => (
                <TagField value={props.value.name} color={ConvertCostStatusToColor(props.value.id)} />
            )
        },
        {
            dataIndex: ["waitingSupervisor", "label"],
            title: translate("pages.cost.fields.waitingSupervisor"),
        },
        /*{
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <EditButton
                    size="small"
                    recordItemId={props.record.id}
                />
            )
        }*/
    ]

    tableProps.expandable = {
        expandedRowRender,
        rowExpandable: (record: ICost) => record.costItems && record.costItems.length > 0,
        expandIcon: expandedRowIcon,
    };
    /* if (!onRow && resource) {
        onRow = (record: any, rowIndex: number) => {
            return {
                onDoubleClick: (event: any) => {
                    event.preventDefault()
                    if (resource) {
                        if (standartOnRowRedirectToShow && resourceItem?.show) navigateShow(resource, record.id, "push");
                        else if (!standartOnRowRedirectToShow && resourceItem?.edit) navigateEdit(resource, record.id, "push");
                    }
                },
            };
        }
    } */
    return (
        
        <ListPage
        
            listProps={{
                title: translate("menu.expense.costsubemployees"),
                canCreate: false,
            }}
            tableProps={tableProps}
            columns={columns}
            rowKey="id"
            isLoading={tableQueryResult.isFetching}
            setFilters={setFilters}
            filters={filters}
            sorter={sorter}
            onRow={(_record: ICostItem) => {
                return {
                    onDoubleClick: null,
                };
            }}
        />
    );
    
};