import React from "react";

import { useLink, TitleProps, useIsAuthenticated } from "@refinedev/core";

import logo from "../../../assets/images/logo.png";
import logo_light from "../../../assets/images/logo_light.png";
import logoCollapsed from "../../../assets/images/logo-collapsed.png";
import logoCollapsed_light from "../../../assets/images/logo-collapsed_light.png";
import { css, CSSProperties, StyleDeclarationMap } from "aphrodite";
import { useDarkMode, useReadLocalStorage } from "usehooks-ts";

import { Logo, InnerLogo } from "./styled";
import { ApplicationSettings } from "entities/configuration/IApplicationSettings";
import { Divider } from "antd";

interface CustomTitleProps extends TitleProps {
  collapsed: boolean;
  classes?: any[];
  ignoreBottomLine?: boolean;
  onLoad?: any;
}

export const ApplicationTitle: React.FC<CustomTitleProps> = ({
  collapsed,
  classes,
  ignoreBottomLine,
  onLoad,
}) => {
  const applicationSettings = useReadLocalStorage<ApplicationSettings>(
    "ApplicationSettings"
  );

  const Link = useLink();

  var initialDarkMode: boolean = false;
  var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

  if (localStorageTheme === "true") {
    initialDarkMode = true;
  }
  const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode);

  if (!classes) classes = [];

  const { data } = useIsAuthenticated();

  return (
    <>
      <Logo>
        <Link to="/" href="/">
          <InnerLogo>
            {collapsed ? (
              <img
                crossOrigin={onload != undefined ? "anonymous" : undefined}
                onLoad={onLoad}
                src={
                  !isDarkMode
                    ? applicationSettings?.logoLightCollapsedUrl ??
                      logoCollapsed_light
                    : applicationSettings?.logoDarkCollapsedUrl ?? logoCollapsed
                }
                alt="ExpinovaLogo"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "12px 24px",
                }}
                className={css(classes)}
              />
            ) : (
              <img
                crossOrigin={onload != undefined ? "anonymous" : undefined}
                onLoad={onLoad}
                src={
                  !isDarkMode
                    ? applicationSettings?.logoLightUrl ?? logo_light
                    : applicationSettings?.logoDarkUrl ?? logo
                }
                alt="ExpinovaLogo"
                style={{
                  maxWidth: "50%",
                  verticalAlign: "middle",
                }}
                className={css(classes)}
              />
            )}
          </InnerLogo>
        </Link>
      </Logo>
      {data?.authenticated && !ignoreBottomLine ? <Divider /> : <></>}
    </>
  );
};
