import {
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useParsed,
} from "@refinedev/core";
import PDFFile from "components/PDF/PdfFile";
import { ICost } from "entities/expense/ICost";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { IServiceObjectResult } from "interfaces/Results/ServiceObjectResult";

export const VerificationShow: React.FC<
  IResourceComponentsProps<DataType, OptionType>
> = (props) => {
  const {
    resource: parsedResource,
    action: parsedAction,
    id: parsedId,
    pathname: parsedPathname,
    params: parsedParams,
  } = useParsed();

  const API_URL = useApiUrl();

  const url = `${API_URL}/cost/getbyverificationkey?verificationkey=${parsedParams?.verificationkey}`;

  //Refine.dev
  //React Query
  const {
    data: response,
    isLoading,
    isLoadingError,
    refetch,
  } = useCustom<{
    data: ICost;
  }>({ url, method: "get" });

  var record: ICost | undefined = response?.data?.data;
  return (
    <> 
      <PDFFile cost={record} isLoading={isLoading}/>
    </>
  );
};

