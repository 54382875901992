import { useTranslate } from '@refinedev/core';
import { Typography } from 'antd';
import { BaseEdge, EdgeLabelRenderer, getStraightPath } from 'reactflow';

export default function GreaterThanEdge({ id, sourceX, sourceY, targetX, targetY }:
    { id: string, sourceX: number, sourceY: number, targetX: number, targetY: number }) {
    const [edgePath, labelX, labelY] = getStraightPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const translate = useTranslate();

    return (
        <>
            <BaseEdge id={id} path={edgePath} label="Greater Then" />
            <EdgeLabelRenderer>
                <Typography.Text style={{
                    position: 'absolute',
                    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                    pointerEvents: 'all',
                }}>
                    {translate("workflowdesigner.edge.greaterthanedge")}
                </Typography.Text>
            </EdgeLabelRenderer>
        </>
    );
}
