import {
  useGetLocale,
  useSetLocale,
  useGetIdentity,
  useTranslate,
  useLogout,
  useIsAuthenticated,
  useWarnAboutChange,
} from "@refinedev/core";


// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Layout as AntdLayout, Space, Menu, Button, Dropdown, Avatar, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { axiosInstance } from "providers/authProvider";
import { IServiceObjectResult } from "interfaces/Results/ServiceObjectResult";
import { CustomModal } from "components/modal/customModal";
import { CheckPermissionsV2 } from "helpers/permissionHelper";

import 'dayjs/locale/tr';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import dayjs, { ConfigType } from "dayjs";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { LogoutOutlined } from "@ant-design/icons";

import { theme } from 'antd';
import { IconMoonStars, IconSun } from "assets/icons/customIcons";

import type { MenuProps } from 'antd';
import { useGetIdentityInterface } from "models/useGetIdentityInterface";
import { useDarkMode } from "usehooks-ts";

const { DownOutlined } = Icons;
const { Title, Text } = Typography;

function generateGoldenKey(): Promise<string> {
  return new Promise((resolve, reject) => {
    axiosInstance.get("Auth/creategoldenkey").then((response) => {
      var serviceResponse: IServiceObjectResult = response.data;
      if (serviceResponse.success) {
        resolve(serviceResponse.data as string);
      }
      else {
        var message = serviceResponse.messages.at(0)?.description;
        reject(message ?? "");
      }
    }).catch((err) => {
      reject(err.message as string);
    });
  });
}





export const Header: React.FC = () => {

  var initialDarkMode: boolean = false;
  var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

  if (localStorageTheme === "true") {
    initialDarkMode = true;
  }
  const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)

  const { i18n } = useTranslation();
  const translate = useTranslate();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity<useGetIdentityInterface>();
  const currentLocale = locale();
  const { mutate: mutateLogout } = useLogout();
  const { isSuccess, isLoading: isAuthenticatedLoading, isError } = useIsAuthenticated();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState("...");
  const [visible, setVisible] = useState(false);
  const { warnWhen, setWarnWhen } = useWarnAboutChange();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const changingLanguage = (lang: string) => {
    if (lang == "tr-TR")
      dayjs.locale("tr");
    else if (lang == "en-US")
      dayjs.locale("en");
    else if (lang == "ger-DE")
      dayjs.locale("de");

    changeLanguage(lang)
  }


  let profileMenuItems: MenuProps["items"] = [
    {
      key: "logout",
      label: (
        <a>
          {translate("buttons.logout", "Logout")}
        </a>
      ),
      icon: <LogoutOutlined />
    }
  ];

  const handleLogout = () => {
    if (warnWhen) {
      const confirm = window.confirm(
        translate(
          "warnWhenUnsavedChanges",
          "Are you sure you want to leave? You have unsaved changes.",
        ),
      );

      if (confirm) {
        setWarnWhen(false);
        mutateLogout();
      }
    } else {
      mutateLogout();
    }
  };

  const profileMenuItemsOnClick: MenuProps['onClick'] = ({ key }) => {
    if (key == "logout")
      handleLogout();
  };


  let languageItems: MenuProps["items"] = [];

  const languageItemsOnClick: MenuProps['onClick'] = ({ key }) => {
    changingLanguage(key);
  };

  [...(i18n.languages || [])].sort().map((lang: string) => {
    languageItems?.push({
      key: lang,
      label: (
        <a>
          {lang === "en-US" ? "English" : (lang == "tr-TR" ? "Türkçe" : "Deustch")}
        </a>
      ),
      icon: (
        <Avatar size={16} src={`/images/flags/${lang}.svg`} />
      )
    })
  })

  const domain = localStorage.getItem("domain");
  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        background: colorBgContainer,
        alignItems: "center",
      }}
    >

      <CustomModal title="Şifre:" disableRefreshButton={false} hideText={true} isFetching={isLoading} isLoading={isLoading} visible={visible} onClickRefresh={() => {
        setLoading(true); generateGoldenKey().then((data) => {
          setVisible(true); setData(data);
        }).catch((error) => { setVisible(true); setData(data); }).finally(() => setLoading(false));
      }} visibility={setVisible}>
        <Text>{data}</Text>
      </CustomModal>

      <Space direction="horizontal" style={
        {
          marginLeft: "8px",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center"
        }
      }>
        <Button
          hidden={!CheckPermissionsV2("All", "GlobalAll")}
          onClick={() => {
            setLoading(true);
            generateGoldenKey().then((data) => {
              setVisible(true); setData(data);
            }).catch((error) => { setVisible(true); setData(data); }).finally(() => setLoading(false));
          }}>
          {translate("buttons.goldenKey")}
        </Button>

        <Button onClick={toggle}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          {!isDarkMode ? <IconMoonStars /> : <IconSun />}
        </Button>

        <Dropdown menu={{ items: languageItems, onClick: languageItemsOnClick }}>
          <Button type="link" icon={(<Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />)}>
            {currentLocale === "en-US" ? "English" : (currentLocale == "tr-TR" ? "Türkçe" : "Deutsch")}
            <DownOutlined />
          </Button>
        </Dropdown>


        <Dropdown menu={{ items: profileMenuItems, onClick: profileMenuItemsOnClick }} disabled={!isSuccess}>
          <Button type="link" icon={user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}>
            {user?.name && (
              <Text ellipsis strong>
                {domain?.toUpperCase()} - {user.name}
              </Text>
            )}
          </Button>
        </Dropdown>
      </Space>
    </AntdLayout.Header>
  );
};


