import React from "react";
import { Typography } from "antd";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { DateFieldProps } from "@refinedev/antd";

dayjs.extend(LocalizedFormat);

/**
 * This field is used to display dates. It uses {@link https://day.js.org/docs/en/display/format `Day.js`} to display date format.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/fields/date} for more details.
 */
export const DateField: React.FC<DateFieldProps> = ({
  value,
  locales,
  format: dateFormat = "L",
  ...rest
}) => {
  const { Text } = Typography;
  const defaultLocale = dayjs.locale();
  return (
    <Text {...rest}>
      {dayjs(value)
        .locale(locales || defaultLocale)
        .format(dateFormat)}
    </Text>
  );
};
