import { List, TextField, TagField, useTable, ShowButton, EditButton } from "@refinedev/antd";
import { Table, Row, Col, Card } from "antd";
import { IResourceComponentsProps, useShow, useTranslate } from "@refinedev/core";
import { IUserForView } from "../../../entities/visa/IUserForView";
import { usePermissions } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { useState } from "react";
import { Breadcrumb } from "components/breadcrumb";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { DateField } from "components/fields/date";

export const UserList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult, setFilters, setSorters: setSorter } = useTable<IUserForView>();
    const translate = useTranslate();

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("users.fields.id"),
            type: "number"
        },
        {
            key: "Firstname",
            title: translate("users.fields.firstname"),
            type: "string"
        },
        {
            key: "Lastname",
            title: translate("users.fields.lastname"),
            type: "string"
        },
        {
            key: "Username",
            title: translate("users.fields.username"),
            type: "string"
        },
        {
            key: "Email",
            title: translate("users.fields.email"),
            type: "string"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("users.fields.id")
        },
        {
            dataIndex: "firstname",
            title: translate("users.fields.firstname")
        },
        {
            dataIndex: "lastname",
            title: translate("users.fields.lastname")
        },
        {
            dataIndex: "email",
            title: translate("users.fields.email")
        },
        {
            dataIndex: "username",
            title: translate("users.fields.username")
        },
        {
            dataIndex: "isLocked",
            title: translate("users.fields.isLocked")
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },
        {
            dataIndex: "lastLogin",
            title: translate("users.fields.lastLogin"),
            render: (props: RenderComponentProps) => (
                <DateField format="LLL" value={props.value} />
            )
        },
        {
            dataIndex: "lastSuccessLogin",
            title: translate("users.fields.lastSuccessLogin"),
            render: (props: RenderComponentProps) => (
                <DateField format="LLL" value={props.value}/>
            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <EditButton
                    size="small"
                    recordItemId={props.record.id}
                    value={translate("general.button.show")}
                />
            )
        }
    ]

    return (
        <ListPage listProps={{
            title: translate("users.users"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Visa.UserManager"),
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={tableQueryResult.isFetching} />
    );
};