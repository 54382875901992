import * as Icons from "@ant-design/icons";
import { RefineKbar } from "@refinedev/kbar";

import { css } from 'aphrodite'
import sheet from './style';

const { LeftOutlined } = Icons;

export const OffLayoutArea = () => {
    return (
        <div className={css(sheet.toggleContainer)}>
            <RefineKbar />
        </div>
    );
};