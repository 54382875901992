import { ColumnFilterInfo, SetFilterBehavior } from "./ListPage";
import { Button, Col, Form, FormInstance, Row } from "antd";
import {
    BaseRecord,
    CrudFilter,
    CrudFilters,
    CrudOperators,
    LogicalFilter,
    useTranslate,
} from "@refinedev/core";
import { DynamicFormField } from "components/form/dynamicformfield";
import { setFieldValue } from "helpers/objectHelper";

import { css } from 'aphrodite'
import sheet from './style';

export interface FilterAreaProperties {
    setFilters: ((filters: CrudFilters, behavior?: SetFilterBehavior) => void) & ((setter: (prevFilters: CrudFilters) => CrudFilters) => void),
    filters: Array<ColumnFilterInfo>,
    isLoading: boolean,
    filterFromQuery: CrudFilters,
    modalClose: any,
    form: FormInstance<any>
}

export const FilterArea = ({
    setFilters,
    filters,
    isLoading,
    filterFromQuery,
    modalClose,
    form
}: FilterAreaProperties) => {

    const onFinish = (values: any) => {
        let filters: CrudFilters = [];

        if (values.filters) {
            values.filters.map((props: any) => {

                if (props) {
                    let { field, value, operator }: { field: string, value: any, operator: Exclude<CrudOperators, "or" | "and"> } = props;

                    if (!operator) operator = "eq";

                    let filter: LogicalFilter = {
                        field,
                        value,
                        operator
                    };

                    filters.push(filter);
                }
            });

        }

        setFilters(filters, "replace");
        modalClose();
    }

    const clearFilter = () => {
        let filters: CrudFilters = [];
        setFilters(filters, "replace");
        filterFromQuery = [];
        form.resetFields()
        modalClose();
    }


    const translate = useTranslate();


    return (
        <div className={css(sheet.filterFormArea)}>
            <Form onFinish={onFinish} form={form} initialValues={{ filters: filterFromQuery }} >
                <DynamicFormField filters={filters} form={form} clearFilters={clearFilter} />
            </Form>
        </div>

    );
}