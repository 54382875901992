import { List, TextField, TagField, useTable, ShowButton, EditButton } from "@refinedev/antd";
import { Table, Button } from "antd";

import {
    IResourceComponentsProps,
    ResourceRouterParams,
    useList,
    useNotification,
    useOne,
    useResourceWithRoute,
    useRouterContext,
    useShow,
    useTranslate,
} from "@refinedev/core";

import { IPermissionResponse } from "../../../entities/visa/IPermissionResponse";
import { usePermissions } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { useState } from "react";
import { RedoOutlined } from "@ant-design/icons";
import { axiosInstance } from "providers/authProvider";
import { IServiceObjectResult } from "interfaces/Results/ServiceObjectResult";
import { getAllMessages } from "helpers/serviceResponseHelper";
import { ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";

export const PermissionList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult } = useTable<IPermissionResponse>();
    const translate = useTranslate();
    const { open, close } = useNotification();



    const [isLoading, setLoading] = useState(false);

    const updateAll = () => {
        setLoading(true);
        axiosInstance.get("permission/updateallpermissions").then((response) => {
            var serviceResponse: IServiceObjectResult = response.data;
            var messages = getAllMessages(serviceResponse);

            if (messages.success && messages.success.length > 0) {
                var message = "";

                messages.success.map((item, index) => {

                    if (index == 0) message = item;
                    else message += " | " + item;
                });

                open?.({
                    message: message,
                    type: "success",
                    key: "updateallpermissions"
                });
            }

            if (messages.warning && messages.warning.length > 0) {
                var message = "";

                messages.warning.map((item, index) => {

                    if (index == 0) message = item;
                    else message += " | " + item;
                });

                open?.({
                    message: message,
                    type: "success",
                    key: "updateallpermissions"
                });
            }

            if (messages.error && messages.error.length > 0) {
                var message = "";

                messages.error.map((item, index) => {

                    if (index == 0) message = item;
                    else message += " | " + item;
                });

                open?.({
                    message: message,
                    type: "error",
                    key: "updateallpermissions"
                });
            }


        }).catch((error) => {
            if (error.response) {
                var messages = getAllMessages(error.response);
                if (messages.error && messages.error.length > 0) {
                    var message = "";

                    messages.error.map((item, index) => {

                        if (index == 0) message = item;
                        else message += " | " + item;
                    });

                    open?.({
                        message: message,
                        type: "error",
                        key: "updateallpermissions"
                    });
                }
            }
            else {
                open?.({
                    message: error.message,
                    type: "success",
                    key: "updateallpermissions"
                });
            }
        }).finally(() => {
            tableQueryResult.refetch().finally(() => {
                setLoading(false);
            });
        });
    };


    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("permissions.fields.id")
        },
        {
            dataIndex: "manager",
            title: translate("permissions.fields.manager")
        },
        {
            dataIndex: "method",
            title: translate("permissions.fields.method")
        },
        {
            dataIndex: "definitionKey",
            title: translate("permissions.fields.definitionKey")
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },

        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <EditButton
                    size="small"
                    recordItemId={props.record.id}
                    value={translate("general.button.show")}
                />
            )
        }
    ]


    return (
        <ListPage listProps={{
            title: translate("permissions.permissions"),
            canCreate: false,
            headerButtons: <Button
                onClick={() => updateAll()}
                disabled={isLoading}
                icon={<RedoOutlined spin={isLoading} />}
            >
                {translate("permissions.button.updateall")}
            </Button>
        }}
            tableProps={tableProps}
            columns={columns}
            rowKey="id"
            isLoading={tableQueryResult.isFetching}

        />
    );
};