import { Edit, useFileUploadState , useForm} from "@refinedev/antd";
import { useApiUrl, useGetIdentity, useNavigation, useParsed, useTranslate } from "@refinedev/core";
import { Form, Input, Row, Col, DatePicker, Select, Switch } from "antd";
import Tabs from "antd/lib/tabs";
import { ApplicationSettings } from "entities/configuration/IApplicationSettings";
import { IFAQ } from "entities/content/IFAQ";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { ConvertStatusID } from "helpers/statusHelper";
import { useGetIdentityInterface } from "models/useGetIdentityInterface";
import React, { useState } from "react";
import { useReadLocalStorage } from "usehooks-ts";


const { TabPane } = Tabs;
export const SSSCreate: React.FC = () => {

    const applicationSettings = useReadLocalStorage<ApplicationSettings>('ApplicationSettings')
    const { data: identity } = useGetIdentity<useGetIdentityInterface>();

   
    const apiUrl = useApiUrl();

    const { isLoading, onChange } = useFileUploadState();

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const [activeKey, setActiveKey] = useState("1");
    const { list, push } = useNavigation();
    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult, form: costForm } = useForm<IFAQ>({
            redirect: "list"
            , action: "create",
            mutationMode: "undoable"
        });
        const translate = useTranslate();

        const userData = queryResult?.data?.data;
        var initialStatus = userData?.statusID ?? 1;

        const onSelectedChange = (checked: boolean) => {
            costForm.setFieldsValue({
                statusID: checked ? 1 : 0
            });
        }
    return (

        <>
         <Edit isLoading={formLoading}
                saveButtonProps={saveButtonProps}
                title={translate("buttons.create")} recordItemId={parsedId}
                canDelete={(CheckPermissionsV2("Delete", "Business.Concrete.Content.FAQManager") && parsedId != undefined)}>

                <Tabs activeKey={activeKey}>
                    <TabPane tab={translate("menu.faq.title")} key="1">
                        <Form {...formProps} layout="vertical">
                            <Form.Item label={translate("pages.cost.fields.id")} name="id" initialValue={userData?.id ?? 0} hidden>
                                <Input />
                            </Form.Item>
                            <Row gutter={24}>
                                <Col span={12} key="question">
                                    <Form.Item label={translate("menu.faq.question")} name="question">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12} key="description">
                                    <Form.Item label={translate("menu.faq.description")} name="description">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            
                            </Row>
                           
                        </Form>
                    </TabPane>
                </Tabs>
            </Edit>
        </>
    )
}