import { useTable, ShowButton, TagField } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import IMailTask from "entities/configuration/IMailTask";
import { DateField } from "components/fields/date";
import { ConvertBoolean, ConvertBooleanToColor } from "helpers/statusHelper";

export const MailTaskList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {

    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IMailTask>({
        syncWithLocation: true
    });
    const translate = useTranslate();

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.MailTask.fields.id"),
            type: "number"
        },
        {
            key: "ToList",
            title: translate("pages.MailTask.fields.toList"),
            type: "string"
        },
        {
            key: "Subject",
            title: translate("pages.MailTask.fields.subject"),
            type: "string"
        },
        {
            key: "NextSendDate",
            title: translate("pages.MailTask.fields.nextSendDate"),
            type: "date"
        },
        {
            key: "IsSended",
            title: translate("pages.MailTask.fields.isSended"),
            type: "boolean"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.MailTask.fields.id")
        },
        {
            dataIndex: "toList",
            title: translate("pages.MailTask.fields.toList")
        },
        {
            dataIndex: "subject",
            title: translate("pages.MailTask.fields.subject")
        },
        {
            dataIndex: "nextSendDate",
            title: translate("pages.MailTask.fields.nextSendDate"),
            render: (props: RenderComponentProps) => (
                <DateField value={props.value} format="LLL" />
            )
        },
        {
            dataIndex: "isSended",
            title: translate("pages.MailTask.fields.isSended"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertBoolean(props.value)} color={ConvertBooleanToColor(props.value)} />
            )
        },
        {
            dataIndex: "sendDate",
            title: translate("pages.MailTask.fields.sendDate"),
            render: (props: RenderComponentProps) => (
                props.value ? <DateField value={props.value} format="LLL" /> : <>None</>

            )
        },
        {
            dataIndex: "tryCount",
            title: translate("pages.MailTask.fields.tryCount")
        },
        {
            dataIndex: "result",
            title: translate("pages.MailTask.fields.result")
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <ShowButton
                    size="small"
                    recordItemId={props.record.id}
                />

            )
        }
    ]

    return (
        <ListPage listProps={{
            title: translate("pages.MailTask.title"),
            canCreate: false,
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={queryResult.isFetching} />
    );
};