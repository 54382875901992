import { useNavigation, useParsed, useTranslate } from '@refinedev/core';
import { useCallback, useMemo, useRef } from 'react';
import ReactFlow, { Background, Connection, Controls, Edge, FitViewOptions, MiniMap, NodeResizer, NodeToolbar, Panel, ReactFlowProvider, addEdge, updateEdge, useEdgesState, useNodesState } from 'reactflow';
import 'reactflow/dist/style.css';
import { DecisionNode, DecisionNodeData } from './nodes/decisionnode';
import LessThanOrEqualEdge from './edges/lessthanedge';
import GreaterThanEdge from './edges/greaterthanedge';
import { Button, Card, Col, Modal, Row, Space } from 'antd';
import { useModal } from '@refinedev/antd';
import { Text } from "../../../components/fields/text";
import { v1 as uuid } from 'uuid';
import { ApprovalNode } from './nodes/approvalnode';

export const WorkflowDesigner: React.FC = () => {
    const nodeTypes = useMemo(
        () => ({
            DecisionNode,
            ApprovalNode
        }),
        [],
    );
    const edgeTypes = {
        'lessthanorequal': LessThanOrEqualEdge,
        'greaterthan': GreaterThanEdge
    };


    const translate = useTranslate();


    const onRemoveBlock = (id: string) => {
        //Servisten de silmek gerekir mi?
        return new Promise((resolve, reject) => {
            setNodes((nds) => {
                return nds.filter((element) => element.id != id);
            });
            setEdges((eds) => eds.filter((e) => e.source !== id && e.target !== id));
            resolve(true);
        });
    }

    const { goBack } = useNavigation();

    const initialNodes = [
        {
            id: '1',
            type: 'input',
            data: { label: translate("workflowdesigner.nodes.start") },
            position: { x: 0, y: 0 },
        },
        {
            id: 'node-1',
            type: 'DecisionNode',
            position: { x: 0, y: 0 },
            toolbarVisible: true,
            toolbarPosition: { x: 0, y: 0 },
            data: {
                label: "test",
                onRemove: onRemoveBlock,
            },
        },
    ];
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const [nodes, setNodes, onNodesChange] = useNodesState<any>(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const edgeUpdateSuccessful = useRef(true);

    const onAddBlock = useCallback((item: any) => {
        if (item.type == "DecisionNode") {
            let id = uuid();
            var newNode = {
                id,
                type: 'DecisionNode',
                data: {
                    label: translate("workflowdesigner.nodes.decisionnode.name"),
                    onRemove: onRemoveBlock,
                    Blockdata: {} as DecisionNodeData
                },
                position: { x: 0, y: 0 },

            }

            newNode.data.Blockdata.nodeId = id;

            setNodes((nds) => nds.concat(newNode));
        }
        else if (item.type == "ApprovalNode") {
            let id = uuid();
            var newNode = {
                id,
                type: 'DecisionNode',
                data: {
                    label: translate("workflowdesigner.nodes.decisionnode.name"),
                    onRemove: onRemoveBlock,
                    Blockdata: {} as DecisionNodeData
                },
                position: { x: 0, y: 0 },

            }

            newNode.data.Blockdata.nodeId = id;

            setNodes((nds) => nds.concat(newNode));
        }
        close()
    }, [setNodes]);



    const onConnect = useCallback(
        (connection: Connection) => {
            if (connection.sourceHandle == "lessthansource") {
                const edge = { ...connection, type: 'lessthanorequal' };
                setEdges((eds) => addEdge(edge, eds));
            }
            else if (connection.sourceHandle == "greaterthansource") {
                const edge = { ...connection, type: 'greaterthan' };
                setEdges((eds) => addEdge(edge, eds));
            }
            else {
                setEdges((eds) => addEdge(connection, eds));
            }
        },
        [setEdges],
    );

    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);
    const onEdgeUpdate = useCallback((oldEdge: any, newConnection: any) => {
        edgeUpdateSuccessful.current = true;
        setEdges((els) => updateEdge(oldEdge, newConnection, els));
    }, []);
    const onEdgeUpdateEnd = useCallback((_: any, edge: any) => {
        if (!edgeUpdateSuccessful.current) {
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
        }

        edgeUpdateSuccessful.current = true;
    }, []);
    const fitViewOptions: FitViewOptions = {
        padding: 0.2,
    };
    const defaultEdgeOptions = { animated: true, };

    /*
    
            
    */

    const { modalProps, show, close } = useModal();

    return (
        <div style={{ width: '85vw', height: '88vh' }}>
            <Modal {...modalProps}>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Card title={
                            <Space align='baseline' wrap>
                                <Button onClick={() => {
                                    onAddBlock({
                                        type: "DecisionNode"
                                    })
                                }}>
                                    Ekle
                                </Button>
                                <Text>
                                    Karar Düğümü
                                </Text>
                            </Space>
                        }>
                            Belirli fiyat için bir karar verilmesini istiyorsanız bu düğüm kullanılmalıdır.
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title={
                            <Space align='baseline' wrap>
                                <Button onClick={() => {
                                    onAddBlock({
                                        type: "ApprovalNode"
                                    })
                                }}>
                                    Ekle
                                </Button>
                                <Text>
                                    Onaycı Düğümü
                                </Text>
                            </Space>
                        }>
                            Bu noktaya gelindiğinde masrafın onaylı statüsünde olması için en az kaç onaycıdan geçmesi gerektiği bilgisi yer alır.
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title={
                            <Space align='baseline' wrap>
                                <Button>
                                    Ekle
                                </Button>
                                <Text>
                                    Çalışan Departman Onayı
                                </Text>
                            </Space>
                        }>
                            Bu noktaya gelindiğinde masraf mutlaka ilgili çalışanın departman yöneticisine onaya gider.
                        </Card>
                    </Col>
                </Row>
            </Modal>
            <ReactFlowProvider>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    defaultEdgeOptions={defaultEdgeOptions}
                    fitView
                    fitViewOptions={fitViewOptions}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                >
                    <Controls />
                    <Background gap={12} size={1} />
                    <Panel position="top-left">
                        <Button onClick={show}>
                            Open Node Selector
                        </Button>
                        <Button onClick={goBack}>
                            Save &  Back
                        </Button>
                    </Panel>
                </ReactFlow>
            </ReactFlowProvider>
        </div>

    )
}