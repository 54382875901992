
import { useApiUrl, useCustom, useLogin, useTranslate } from "@refinedev/core";

import React, { useState } from "react";
import {
    Row,
    Col,
    Layout as AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
} from "antd";

import { theme } from 'antd';
import { StyleSheet } from 'aphrodite'
import { css } from 'aphrodite'

import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

import logo from "../../assets/images/logo.png";
import logo_light from "../../assets/images/logo_light.png";
import { IconMoonStars, IconSun } from "assets/icons/customIcons";
import { ApplicationTitle } from "components/layout/title";

import Cookies from 'universal-cookie';
import { useDarkMode } from "usehooks-ts";

const { Text, Title } = Typography;
const { useToken } = theme;

interface CaptchaCheckModel {
    captchaModel: string;
    inputCode: string;
}

export interface ILoginForm {
    domain: string;
    username: string;
    password: string;
    remember: boolean;
    captchaCheckModel: CaptchaCheckModel;
}


interface CaptchaModel {
    captchaModel: string;
    image: string;
}

export const LoginPage: React.FC = () => {

    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }
    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)

    const { token } = useToken();
    let model: CaptchaModel = {
        captchaModel: "",
        image: ""
    };

    const sheet = StyleSheet.create({
        layout: {
            background: 'radial-gradient(50% 50% at 50% 50%, ' + token.colorFillSecondary + ' 0%, ' + token.colorPrimary + ' 100%)',
            backgroundRepeat: 'cover'
        },
        layout_container: {
            maxWidth: '408px',
            margin: 'auto'
        },
        layout_title: {
            textAlign: 'center',
            fontSize: '30px',
            letterSpacing: '-0.04em'
        },
        layout_imageContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '16px'
        },
        layout_imageContainer_img: {
            width: '30vh'
        }
    });


    var errorCountStr = localStorage.getItem("iec_app_lg_ct");
    var errorCount = 0;

    if (errorCountStr) errorCount = parseInt(errorCountStr);

    const API_URL = useApiUrl();
    const url = `${API_URL}/auth/generatecaptcha`;
    const { data } = useCustom({
        url, method: 'get'
    });

    var captchaVisible = false;

    if (errorCount > 2) {
        captchaVisible = true;
        model.captchaModel = data?.data.captchaModel ?? "";
        model.image = data?.data.image ?? "";
    }

    const [passIsShow, setPassIsShow] = useState(false);

    const SwitchVisiblePasswordButton = () => {
        if (passIsShow)
            return <EyeInvisibleOutlined onClick={() => setPassIsShow(!passIsShow)} />

        return <EyeOutlined onClick={() => setPassIsShow(!passIsShow)} />
    }

    const [form] = Form.useForm<ILoginForm>();
    const translate = useTranslate();
    const { mutate: login, isLoading, error, isSuccess } = useLogin<ILoginForm>();


    const CardTitle = (
        <Title level={3} className={css(sheet.layout_title)}>
            {translate("pages.login.title")}
        </Title>
    );

    const initialDomain = ""//window.location.href.replaceAll('https://', '').replaceAll('http://', '').split('/')[0].split('.')[0];

    return (
        <AntdLayout className={css(sheet.layout)}>
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div className={css(sheet.layout_container)}>
                        <div className={css(sheet.layout_imageContainer)}>
                            <ApplicationTitle collapsed={false} classes={[sheet.layout_imageContainer_img]} />
                        </div>
                        <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                            <Form<ILoginForm>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    if (errorCount > 2) {
                                        values.captchaCheckModel.captchaModel = model.captchaModel;
                                    }
                                    login(values);
                                }}
                                requiredMark={false}
                                initialValues={{
                                    remember: false,
                                }}
                            >
                                <Form.Item
                                    name="domain"
                                    label={translate("pages.login.domain")}
                                    rules={[
                                        {
                                            required: true,
                                        }
                                    ]}
                                    initialValue={initialDomain}
                                >
                                    <Input
                                        size="large"
                                        placeholder={translate("pages.login.domain")}
                                        value={initialDomain}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="username"
                                    label={translate("pages.login.username")}
                                    rules={[
                                        {
                                            required: true,
                                        }
                                    ]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={translate("pages.login.username")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={translate("pages.login.password")}
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input
                                        type={passIsShow ? "text" : "password"}
                                        placeholder="●●●●●●●●"
                                        size="large"
                                        addonAfter={<SwitchVisiblePasswordButton />}
                                    />
                                </Form.Item>

                                {
                                    captchaVisible && (
                                        <>
                                            <Form.Item
                                                label={translate("pages.login.captcha")}
                                            >
                                                <img src={`${model.image}`} style={{ width: "100%" }} />
                                            </Form.Item>
                                            <Form.Item
                                                name={["captchaCheckModel", "inputCode"]}
                                                label=""
                                                rules={[
                                                    {
                                                        required: true
                                                    }
                                                ]}
                                                style={{ marginBottom: "12px" }}
                                            >
                                                <Input
                                                    type="text"
                                                    placeholder=""
                                                    size="large"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name={["captchaCheckModel", "captchaModel"]}
                                                label=""
                                                style={{ marginBottom: "12px" }}
                                                initialValue={model.captchaModel}
                                            >
                                                <Input hidden value={model.captchaModel} />
                                            </Form.Item>


                                        </>
                                    )
                                }

                                <div style={{ marginBottom: "12px" }}>
                                    <Form.Item
                                        name="remember"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Checkbox
                                            style={{
                                                fontSize: "12px",
                                            }}
                                        >
                                            {translate("pages.login.remember")}
                                        </Checkbox>
                                    </Form.Item>

                                    <a
                                        style={{
                                            float: "right",
                                            fontSize: "12px",
                                        }}
                                        href="forget-password"
                                    >
                                        {translate("pages.login.forgotPassword")}
                                    </a>
                                </div>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                    loading={isLoading}
                                >
                                    {translate("pages.login.signin")}
                                </Button>
                            </Form>
                            <div style={{
                                marginTop: 8,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <Text style={{ fontSize: 12 }}>
                                    {translate("pages.login.noAccount")}{" "}
                                    <a href="#" style={{ fontWeight: "bold" }}>
                                        {translate("pages.login.signup")}
                                    </a>
                                </Text>
                                <Button onClick={toggle}
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    {!isDarkMode ? <IconMoonStars /> : <IconSun />}
                                </Button>
                            </div>

                        </Card>

                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};