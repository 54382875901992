import { List, TextField, TagField, useTable, ShowButton, EditButton } from "@refinedev/antd";
import { Table, Row, Col, Card, Space, Button } from "antd";
import { IResourceComponentsProps, useCreate, useShow, useTranslate } from "@refinedev/core";
import { IUserForView } from "../../../entities/visa/IUserForView";
import { usePermissions } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { useState } from "react";
import { Breadcrumb } from "components/breadcrumb";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { DateField } from "components/fields/date";
import { ICostReport } from "entities/report/ICostReport";
import { axiosInstance } from "providers/authProvider";
import { useFormErrorHandler, useFormSuccessHandler } from "helpers/serviceResponseHelper";

export const CostReportList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult, setFilters, setSorters: setSorter } = useTable<ICostReport>();
    const translate = useTranslate();

    let filters: Array<ColumnFilterInfo> = [

        {
            key: "Name",
            title: translate("users.fields.firstname"),
            type: "string"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.costreport.fields.id")
        },
        {
            dataIndex: "name",
            title: translate("pages.costreport.fields.name")
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <Space>
                    <EditButton
                        size="small"
                        recordItemId={props.record.id}
                        value={translate("general.button.show")}
                    />
                    <Button size="small" onClick={() => {
                        handleExport(props.record.id)
                    }} loading={isLoading}>
                        {translate("general.button.export")}
                    </Button>
                </Space>
            )
        }
    ]

    const { mutate, isLoading } = useCreate();

    const handleExport = (id: number) => {
        mutate({
            resource: "CostReportDemand",
            values: {
                reportId: id,
                id: 0,
                statusID: 1
            },
            errorNotification: useFormErrorHandler,
            successNotification: useFormSuccessHandler
        });
    }

    return (
        <ListPage listProps={{
            title: translate("menu.report.costreport"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Visa.CostReportManager"),
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={tableQueryResult.isFetching} />
    );
};