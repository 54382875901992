export function isNullOrWhitespace(input: string) {
  return !input || !input.trim();
}

export function pluralizeTr(input: string) {
  if (isAlreadyPlural(input)) return input;


  const thickVowels = ["a", "ı", "o", "u", "A", "I", "O", "U",];
  const thinVowels = ["e", "i", "ü", "ö", "İ", "Ö", "Ü", "E"];
  const vowels = [...thickVowels, ...thinVowels];

  let lastVowelIndex = enSagdakiniBul(input, vowels);

  const lastVowel = input[lastVowelIndex];

  if (irregulars.filter((value: any) => (value.single == input)).length > 0)
    return input + (irregulars.filter((value: any) => (value.single == input)).at(0)?.plural ?? "");

  if (thickVowels.includes(lastVowel)) return input + "lar";

  return input + "ler";
}


function enSagdakiniBul(input: string, arananlar: string[]) {
  const strLen = input.length;

  for (let i = strLen - 1; i >= 0; i--) {
    if (arananlar.includes(input.substring(i, i + 1)))
      return i;
  }

  return 0;
}

function isAlreadyPlural(input: string) {  
  const strLen = input.length;
  const pluralAddons = ["lar", "ler"];

  if (strLen > 3) {
    return pluralAddons.includes(input.substring(strLen - 3));
  }

  return false;
}

const irregulars = [
  {
    single: "gol",
    plural: "ler"
  },
  {
    single: "saat",
    plural: "ler"
  },
  {
    single: "kalp",
    plural: "ler"
  },
  {
    single: "metal",
    plural: "ler"
  }
];