

import { IDepartment } from "../../../entities/definition/IDepartment";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor, ConvertBoolean, ConvertBooleanToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { EditButton, TagField, useSelect, useTable } from "@refinedev/antd";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { IEmployee } from "entities/visa/IEmployee";

export const DepartmentList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IDepartment>();
    const translate = useTranslate();

    const { selectProps: employeeSelectProps } = useSelect({
        resource: "Employee",
        optionLabel: "user.label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "User.Username",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.department.fields.id"),
            type: "number"
        },
        {
            key: "Name",
            title: translate("pages.department.fields.name"),
            type: "string"
        },
        {
            key: "ParentDepartment",
            title: translate("pages.department.fields.parentDepartment"),
            type: "string"
        },
        {
            key: "ManageruserID",
            title: translate("pages.department.fields.manageruser"),
            type: "number",
            selectProps: employeeSelectProps
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.department.fields.id")
        },
        {
            dataIndex: "name",
            title: translate("pages.department.fields.name")
        },
        {
            dataIndex: "parentDepartmentName",
            title: translate("pages.department.fields.parentDepartment")
        },
        {
            dataIndex: ["managerUser", "label"],
            title: translate("pages.department.fields.manageruser")
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <EditButton
                    size="small"
                    recordItemId={props.record.id}
                />

            )
        }
    ]

    return (
        <ListPage listProps={{
            title: translate("pages.department.title"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Definition.DepartmentManager")
        }}
            tableProps={tableProps}
            columns={columns}
            filters={filters}
            rowKey="id"
            setFilters={setFilters}
            setSorter={setSorter}
            isLoading={queryResult.isFetching}
            standartOnRowRedirectToShow={false} />

    );
};