import { FormInstance } from "antd";
import { NamePath } from "antd/lib/form/interface";

export function getFormData(object: any) {
    const formData = new FormData();
    Object.keys(object).forEach(key => {
        var index = -1;
        if (Array.isArray(object[key])) {
            index++;
            object[key].forEach((element: any) => {
                var data = getFormData(element);

                for (const pair of data) {
                    formData.append(pair[0], pair[1]);
                }

            });
        }
        else {
            if (object[key] === undefined || object[key] === null)
                formData.append(key, "")
            else formData.append(key, object[key])
        }
    });    
    return formData;
}

export const setFieldValue = (
    form: FormInstance,
    name: NamePath,
    value: string
): void => {
    if (form && form.getFieldValue(name)) {
        const fixname: string[] = [];
        if (typeof name == 'object') {
            name.forEach((node: any) => {
                fixname.push(node);
            });
        } else {
            fixname.push(String(name));
        }
        let fieldsValue: unknown;
        fixname.reverse().forEach((node: string) => {
            fieldsValue = {
                [String(node)]: fieldsValue != undefined ? fieldsValue : value,
            };
        });
        form.setFieldsValue(fieldsValue);
    }
};