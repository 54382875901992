
import { IMainCostCategory } from "../../../entities/definition/IMainCostCategory";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor, ConvertBoolean, ConvertBooleanToColor, ConvertCostStatusToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { ICost } from "entities/expense/ICost";
import { ICostActionHistory } from "entities/expense/ICostActionHistory";
import { ICostItem } from "entities/expense/ICostItem";
import { IMedia } from "entities/content/IMedia";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { IDepartment } from "entities/definition/IDepartment";
import { useConstantValueFromService } from "hooks";
import { Button, Col, DatePicker, Dropdown, Form, Input, Menu, Modal, Row, Select, Switch, Table, Tabs, Upload, UploadFile } from "antd";
import { IResourceComponentsProps, OpenNotificationParams, useApiUrl, useGetIdentity, useTranslate } from "@refinedev/core";
import { DateField, EditButton, FileField, List, NumberField, TagField, getValueFromEvent, useFileUploadState, useModalForm, useSelect, useTable } from "@refinedev/antd";
import { EyeOutlined, CheckOutlined, CloseOutlined, FormOutlined, MoreOutlined } from "@ant-design/icons";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { useGetIdentityInterface } from "models/useGetIdentityInterface";
import dayjs from "dayjs";
import { ICurrency } from "entities/definition/ICurrency";
import { useDarkMode, useEffectOnce, useReadLocalStorage } from "usehooks-ts";
import { ApplicationSettings } from "entities/configuration/IApplicationSettings";
import { UploadChangeParam } from "antd/es/upload";
import { IMultiMediaSaveRequest } from "entities/content/IMultiMediaSaveRequest";
import { useFormSuccessHandler } from "helpers/serviceResponseHelper";


const { TabPane } = Tabs;

export const CostList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const apiUrl = useApiUrl();
    const applicationSettings = useReadLocalStorage<ApplicationSettings>(
        "ApplicationSettings"
    );
    const { data: identity } = useGetIdentity<useGetIdentityInterface>();

    const { tableProps, tableQueryResult, setFilters, sorter, filters: tableFilters } = useTable<ICost>({
        permanentFilter: [
            {
                field: "EmployeeID",
                operator: "eq",
                value: identity?.employee.id
            }
        ],
        permanentSorter: [
            {
                field: "DateCreated",
                order: "desc"
            }
        ]
    });



    const { selectProps: costStatusTypeSelectProps } = useConstantValueFromService("getcoststatustype");


    const { selectProps: departmentSelectProps } = useSelect<IDepartment>({
        resource: "Department",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "name",
                operator: "contains",
                value
            }
        ]
    });

    const moreMenu = (record: ICostItem) => {
        return (
            <Menu
                mode="vertical"
                onClick={({ domEvent }) => domEvent.stopPropagation()}
            >
                {record?.medias?.map((item: IMedia) => {
                    return (<Menu.Item
                        key={item.uid}
                    >
                        <FileField src={item.url} title={item.name} />
                    </Menu.Item>)
                })}

            </Menu>
        )
    };

    const translate = useTranslate();
    const expandedRowRender = (record: ICost) => {
        return (
            <Tabs>
                <TabPane tab={translate("menu.expense.costitem")} key="1">
                    <List title={""} breadcrumb={<></>} canCreate={false}>
                        <Table
                            dataSource={record.costItems}
                            rowKey="id"
                            footer={(currentPageData: any) => {
                                let totalPrice = 0;
                                let totalTax = 0;

                                currentPageData.map((item: ICostItem) => {
                                    totalPrice += item.totalPrice;
                                    totalTax += item.tax
                                });

                                return (
                                    <div>
                                        <span>{translate("pages.costitem.fields.totalPrice")}: {totalPrice}</span>
                                        <br />
                                        <span>{translate("pages.costitem.fields.tax")}: {totalTax}</span>
                                    </div>
                                )
                            }}
                        >
                            <Table.Column<ICostItem>
                                dataIndex="date"
                                title={translate("pages.cost.fields.date")}
                                render={(value: any) => <DateField format="LLL" value={value} />}
                            />

                            <Table.Column dataIndex="description" title={translate("pages.costitem.fields.description")} />
                            <Table.Column dataIndex={["subCostCategory", "label"]} title={translate("pages.costitem.fields.subCostCategory")} />
                            <Table.Column dataIndex="categoryDescription" title={translate("pages.costitem.fields.categoryDescription")} />
                            <Table.Column dataIndex={["costType", "name"]} title={translate("pages.costitem.fields.costtype")} />
                            <Table.Column dataIndex="documentNo" title={translate("pages.costitem.fields.documentNo")} />
                            <Table.Column dataIndex="firmName" title={translate("pages.costitem.fields.firmName")} />

                            <Table.Column<ICostItem>
                                key="totalPrice"
                                title={translate("pages.costitem.fields.totalPrice")}
                                dataIndex="totalPrice"
                                render={(value) => (
                                    <NumberField
                                        value={value}
                                    />
                                )}
                            />

                            <Table.Column<ICostItem>
                                key="tax"
                                title={translate("pages.costitem.fields.tax")}
                                dataIndex="tax"
                                render={(value) => (
                                    <NumberField
                                        value={value}
                                    />
                                )}
                            />

                            <Table.Column<ICostItem>
                                title={translate("general.button.files")}
                                dataIndex="actions"
                                render={(_text, record): React.ReactNode => {
                                    return (
                                        <Dropdown
                                            overlay={moreMenu(record)}
                                            trigger={["click"]}
                                        >
                                            <MoreOutlined
                                                onClick={(e) => e.stopPropagation()}
                                                style={{
                                                    fontSize: 24,
                                                }}
                                            />
                                        </Dropdown>
                                    );
                                }}
                            />
                        </Table>
                    </List>
                </TabPane>
                <TabPane tab={translate("menu.expense.costactionhistory")} key="2">
                    <List title="" breadcrumb={<></>} canCreate={false}>
                        <Table
                            dataSource={record.costActionHistories.sort((n1, n2) => n2.id - n1.id)}
                            rowKey="id"
                        >
                            <Table.Column
                                dataIndex="dateCreated"
                                title={translate("pages.cost.fields.actiondate")}
                                render={(value: any) => <DateField format="LLL" value={value} />}
                            />

                            <Table.Column dataIndex="description" title={translate("pages.cost.fields.description")} />
                            <Table.Column
                                dataIndex="oldCostStatusType"
                                title={translate("pages.cost.fields.oldcoststatustype")}
                                render={(value: any) => <TagField value={value.name} color={ConvertCostStatusToColor(value.id)} />}
                            />
                            <Table.Column
                                dataIndex="newCostStatusType"
                                title={translate("pages.cost.fields.newcoststatustype")}
                                render={(value: any) => <TagField value={value.name} color={ConvertCostStatusToColor(value.id)} />}
                            />
                            <Table.Column dataIndex="oldCurrentLevel" title={translate("pages.cost.fields.oldCurrentLevel")} />
                            <Table.Column dataIndex="newCurrentLevel" title={translate("pages.cost.fields.newCurrentLevel")} />
                            <Table.Column dataIndex="approvementLevel" title={translate("pages.cost.fields.approvementLevel")} />
                            <Table.Column dataIndex={["referenceUser", "label"]} title={translate("pages.costitem.fields.referenceuser")} />
                        </Table>
                    </List>
                </TabPane>
            </Tabs>

        )
    };

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            type: "number",
            title: translate("pages.cost.fields.id"),
        },
        {
            key: "Date",
            type: "date",
            title: translate("pages.cost.fields.date"),
        },
        {
            key: "DepartmentID",
            type: "number",
            title: translate("pages.cost.fields.department"),
            selectProps: departmentSelectProps
        },
        {
            key: "CostStatusTypeID",
            type: "number",
            title: translate("pages.cost.fields.coststatustype"),
            selectProps: costStatusTypeSelectProps
        }
    ]

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.cost.fields.id"),
            sortable: true,
            entityName: "ID"
        },
        {
            dataIndex: "date",
            title: translate("pages.cost.fields.date"),
            render: (props: RenderComponentProps) => (
                <DateField format="LL" value={props.value} />
            ),
            sortable: true,
            entityName: "Date"
        },
        {
            dataIndex: "description",
            title: translate("pages.cost.fields.name"),
        },
        {
            dataIndex: ["department", "name"],
            title: translate("pages.cost.fields.department"),
        },
        {
            dataIndex: "totalPrice",
            title: translate("pages.cost.fields.totalPrice"),
            render: (props: RenderComponentProps) => (
                <NumberField
                    value={props.value}
                />
            )
        },
        {
            dataIndex: "tax",
            title: translate("pages.cost.fields.tax"),
            render: (props: RenderComponentProps) => (
                <NumberField
                    value={props.value}
                />
            )
        },
        {
            dataIndex: "costStatusType",
            title: translate("pages.cost.fields.coststatustype"),
            render: (props: RenderComponentProps) => (
                <TagField value={props.value.name} color={ConvertCostStatusToColor(props.value.id)} />
            )
        },
        {
            dataIndex: ["waitingSupervisor", "label"],
            title: translate("pages.cost.fields.waitingSupervisor"),
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <EditButton
                    size="small"
                    recordItemId={props.record.id}
                />
            )
        }
    ]

    tableProps.expandable = {
        expandedRowRender
    };

    // Create Modal
    const {
        modalProps,
        formProps,
        show,
        onFinish,
        form,
        formLoading,
        open: bulkCreateModalIsOpen,
        close,
        overtime: formOvertime
    } = useModalForm({
        action: "create",
        resource: "Cost",
        redirect: false,
        meta: {
            controller: "bulkCreate"
        },
        warnWhenUnsavedChanges: false,
        successNotification(data: any, values, resource) {
            close();
            console.log()


            let result: OpenNotificationParams = {
                message: `Talep Edilen: ${data.data.demandedExpenseCount}, Başarılı: ${data.data.successExpenseCount}, Başarısız: ${data.data.errorExpenseCount}`,
                description: "",
                type: data.data.successExpenseCount > 0 ? "success" : "error",
            }

            return result;
        },
    });

    const { selectProps: currencySelectProps } = useSelect<ICurrency>({
        resource: "Currency",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 5000,
        queryOptions: {
            enabled: bulkCreateModalIsOpen
        }
    });


    const { isLoading, onChange } = useFileUploadState();

    const onFileUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.status == "done") {
            let newMedia: IMultiMediaSaveRequest = {
                mediaID: info.file.response.id,
                uid: info.file.uid,
            };
        }

        onChange(info);
    };

    const onBulkCreateSubmit = (values: any) => {

        let { medias, ...cost } = values;

        let model = {
            cost,
            medias
        };

        onFinish(model);
    }

    return (
        <>
            <Modal {...modalProps}>
                <Form {...formProps} layout="vertical" resource="Cost" onFinish={onBulkCreateSubmit}>
                    <Row gutter={24}>
                        <Col span={12} key="description">
                            <Form.Item
                                label={translate("pages.cost.fields.description")}
                                name="description"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                rules={[{ required: true }]}
                                label={translate("pages.cost.fields.date")}
                                name="date"
                                getValueProps={(value) => ({
                                    value: value ? dayjs(value) : dayjs(),
                                })}
                                initialValue={dayjs()}
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>
                        <Col span={12} key="departmentID">
                            <Form.Item
                                label={translate("pages.cost.fields.department")}
                                name="departmentID"
                            >
                                <Select {...departmentSelectProps} showSearch={true} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={translate("pages.costitem.fields.currency")}
                                name="currencyID"
                                rules={[{ required: true }]}
                                initialValue={(applicationSettings?.defaultCurrencyID ?? 0 > 0) ? applicationSettings?.defaultCurrencyID : undefined}
                            >
                                <Select {...currencySelectProps} showSearch={true} />
                            </Form.Item>
                        </Col>
                        <Col span={12} key="Status" hidden>
                            <Form.Item
                                label={translate("general.status.title")}
                                name="statusID"
                                initialValue={1}
                            >
                                <Switch
                                    defaultChecked={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} key="medias">
                            <Form.Item
                                name="medias"
                                valuePropName="fileList"
                                getValueFromEvent={getValueFromEvent}
                            >
                                <Upload.Dragger
                                    name="file"
                                    action={`${apiUrl}/Media/upload`}
                                    listType="text"
                                    onChange={onFileUploadChange}
                                    headers={{
                                        Authorization: `bearer ${identity?.token}`,
                                    }}
                                    multiple
                                    showUploadList={{
                                        showDownloadIcon: true,
                                    }}
                                >
                                    <p className="ant-upload-text">
                                        {translate("pages.expense.fields.uploadFile")}
                                    </p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <ListPage listProps={{
                title: translate("pages.cost.title"),
                canCreate: CheckPermissionsV2("Add", "Business.Concrete.Expense.CostManager"),
                headerButtons: <Button
                    onClick={() => { show(); }}
                    icon={<AppstoreAddOutlined spin={formLoading} />}
                    type="primary"
                >
                    {translate("pages.cost.bulkCreate")}
                </Button>
            }}

                tableProps={tableProps}
                columns={columns}
                rowKey="id"
                isLoading={tableQueryResult.isFetching}
                setFilters={setFilters}
                filters={filters}
                sorter={sorter}
            />
        </>

    );
};