import { useTable, EditButton } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import IMailTemplate from "entities/configuration/IMailTemplate";
import { CheckPermissionsV2 } from "helpers/permissionHelper";

export const MailTemplateList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {

    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IMailTemplate>({
        syncWithLocation: true
    });
    const translate = useTranslate();

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.MailTemplate.fields.id"),
            type: "number"
        },
        {
            key: "Code",
            title: translate("pages.MailTemplate.fields.code"),
            type: "string"
        },
        {
            key: "Subject",
            title: translate("pages.MailTemplate.fields.subject"),
            type: "string"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.MailTemplate.fields.id")
        },
        {
            dataIndex: "code",
            title: translate("pages.MailTemplate.fields.code")
        },
        {
            dataIndex: "subject",
            title: translate("pages.MailTemplate.fields.subject")
        }
    ]

    if (CheckPermissionsV2("Update", "Business.Concrete.Configuration.MailTemplateManager")) {
        columns.push(
            {
                dataIndex: "actions",
                title: translate("general.button.actions"),
                render: (props: RenderComponentProps) => (
                    <EditButton
                        size="small"
                        recordItemId={props.record.id}
                    />
                )
            });
    }

    return (
        <ListPage listProps={{
            title: translate("pages.MailTemplate.title"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Configuration.MailTemplateManager"),
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={queryResult.isFetching} />
    );
};