import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IAnnouncement } from "../../../entities/definition/IAnnouncement";
import { useState } from "react";
import { ConvertStatusID, ConvertBoolean } from "helpers/statusHelper";
import React from "react";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import "./style.less";
import { IUserForView } from "entities/visa/IUserForView";
import { useConstantValueFromService, useConstantValueFromServiceString } from "hooks";
import { IMultiMediaSaveRequest } from "entities/expense/IMultiMediaSaveRequestd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import dayjs from "dayjs";
import TextArea from "antd/lib/input/TextArea";
import { useApiUrl, useGetIdentity, useNavigation, useParsed, useTranslate } from "@refinedev/core";
import { Edit, getValueFromEvent, useFileUploadState, useForm, useSelect } from "@refinedev/antd";
import { Col, DatePicker, Form, Input, Row, Select, Switch, Upload } from "antd";
import { IRoleResponse } from "entities/visa/IRoleResponse";
import { css } from "aphrodite";
import sheet from './style';
import { useGetIdentityInterface } from "models/useGetIdentityInterface";
import { IDepartment } from "entities/definition/IDepartment";



export const AnnouncementEdit: React.FC = () => {
    const apiUrl = useApiUrl();
    const { data: identity } = useGetIdentity<useGetIdentityInterface>();
    const { isLoading, onChange } = useFileUploadState();

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    var actionData = "create";

    if (parsedId) {
        actionData = "edit";
    }

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<IAnnouncement>({
            redirect: false,
            action: actionData == "create" ? "create" : "edit",
            mutationMode: "undoable"
        });
    const translate = useTranslate();
    const Data = queryResult?.data?.data;
    var initialStatus = Data?.statusID ?? 1;
    var initialRoles: any[] = Data?.roleIds ?? [];
    var initialDepartmant: any[]= Data?.departmantIds ?? [];
    const { list } = useNavigation();

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    if (mutationResult.isSuccess) {
        redirect("list");
    }

    const onFileUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.status == "done") {
            let newMedia: IMultiMediaSaveRequest = {
                mediaID: info.file.response.id,
                uid: info.file.uid
            };
        }

        onChange(info);
    }

    const { selectProps } = useSelect<IRoleResponse>({
        resource: "Role",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 5000,

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: departmentSelectProps } = useSelect<IDepartment>({
    resource: "Department",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 1000,
    debounce: 5000,
    pagination: {
        mode: "server"
    }
    });


    return (
        <Edit isLoading={formLoading} canDelete={(CheckPermissionsV2("Delete", "Business.Concrete.Definition.AnnouncementManager") && parsedId != undefined)}
            saveButtonProps={saveButtonProps}
            title={translate("buttons.edit")} recordItemId={parsedId}>
            <Form {...formProps} layout="vertical" ref={formRef}>
                <Form.Item label={translate("pages.announcement.fields.id")} name="id" initialValue={Data?.id ?? 0} hidden>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12} key="Title">
                        <Form.Item label={translate("pages.announcement.fields.title")} name="title" rules={[{ required: true, message: translate("rules.requiredTitle") }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Description">
                        <Form.Item label={translate("pages.announcement.fields.description")} name="description" rules={[{ required: true, message: translate("rules.requiredDescription") }]}>
                            <Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="roleIds">
                        <Form.Item label={translate("users.fields.roles")} name="roleIds" initialValue={initialRoles} rules={[{required:false}]}>
                            <Select {...selectProps} mode="multiple" className={css(sheet.permissionList)} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="departmentId">
                        <Form.Item label={translate("pages.employee.fields.department")} name="departmentIds" initialValue={initialDepartmant} rules={[{required:false}]}>
                            <Select {...departmentSelectProps} mode="multiple" className={css(sheet.permissionList)} />
                        </Form.Item>
                    </Col>
                    <Col span={24} key="medias">
                        <Form.Item
                            name="medias"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                        >
                            <Upload.Dragger
                                name="file"
                                action={`${apiUrl}/Media/upload`}
                                listType="text"
                                onChange={onFileUploadChange}
                                headers={
                                    {
                                        "Authorization": `bearer ${identity?.token}`
                                    }
                                }
                                maxCount={5}
                                multiple
                                showUploadList={{
                                    showDownloadIcon: true
                                }}
                            >
                                <p className="ant-upload-text">
                                    {translate("pages.expense.fields.uploadFile")}
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label={translate("pages.announcement.fields.startDate")}
                            name="startDate"
                            getValueProps={(value: string | number | dayjs.Dayjs | Date | null | undefined) => ({
                                value: value ? dayjs(value) : dayjs(),
                            })}
                            initialValue={dayjs()}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            label={translate("pages.announcement.fields.endDate")}
                            name="endDate"
                            getValueProps={(value: string | number | dayjs.Dayjs | Date | null | undefined) => ({
                                value: value ? dayjs(value) : dayjs().add(1, 'year'),
                            })}
                            initialValue={dayjs()}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col span={16} key="Status" >
                        <Form.Item label={translate("general.status.title")} name="statusID" initialValue={initialStatus == 1} style={{ float: 'right' }}>
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                        </Form.Item>
                    </Col>


                </Row>
            </Form>
        </Edit>
    );
};
