import { List, TagField, useTable, EditButton } from "@refinedev/antd";
import { Table } from "antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { IForbiddenUsernameResponse } from "../../../entities/visa/IForbiddenUsernameResponse";
import { DataType,OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor,ConvertBoolean,ConvertBooleanToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { useDarkMode } from "usehooks-ts";

export const ForbiddenUsernameList: React.FC<IResourceComponentsProps<DataType,OptionType>> = (props) => {
    const { tableProps } = useTable<IForbiddenUsernameResponse>();
    const translate = useTranslate();        
    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }

    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)
    return (
        <List title={translate("forbiddenUsername.title")} canCreate={CheckPermissionsV2("Add","Business.Concrete.Visa.ForbiddenUsernameManager")}>
            <Table {...tableProps} rowKey="id"
                className={isDarkMode ? "table-striped-rows-dark" : "table-striped-rows"}
                bordered
                size="large">
                <Table.Column dataIndex="id" title= {translate("forbiddenUsername.fields.id")} />
                <Table.Column dataIndex="username" title= {translate("forbiddenUsername.fields.username")} />
                <Table.Column
                    dataIndex="statusID"
                    title={translate("general.status.title")}
                    render={(value:any) => <TagField value={ConvertStatusID(value)} color={ConvertStatusIDToColor(value)}/>}
                />                
                <Table.Column<IForbiddenUsernameResponse>
                    title={translate("general.button.actions")}  
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <EditButton
                                size="small"
                                recordItemId={record.id}                          
                            />
                        );
                    }}
                />
            </Table>
        </List>
    );
};