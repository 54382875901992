

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IMainCostCategory } from "../../../entities/definition/IMainCostCategory";
import { ConvertStatusID } from "helpers/statusHelper";
import React from "react";
import "./style.less";
import { ISubCostCategory } from "entities/definition/ISubCostCategory";
import { IDepartment } from "entities/definition/IDepartment";
import { IWorkflow } from "entities/definition/IWorkflow";
import { useNavigation, useParsed, useTranslate } from "@refinedev/core";
import { DeleteButton, Edit, EditButton, List, useForm, useModalForm, useSelect, useTable } from "@refinedev/antd";
import { Col, Form, Input, Modal, Row, Select, Space, Switch, Table, Tabs } from "antd";
import { useDarkMode } from "usehooks-ts";

const { TabPane } = Tabs;

export const MainCostCategoryEdit: React.FC = () => {
    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }

    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    var actionData = "create";

    if (parsedId) {
        actionData = "edit";
    }

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<IMainCostCategory>({
            redirect: false
            , action: actionData == "create" ? "create" : "edit",
            mutationMode: "undoable"
        });
    const translate = useTranslate();
    const Data = queryResult?.data?.data;
    var initialStatus = Data?.statusID ?? 1;
    const { list } = useNavigation();

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    if (mutationResult.isSuccess) {
        redirect("list");
    }


    const { tableProps, tableQueryResult } = useTable<ISubCostCategory>({
        resource: "SubCostCategory",
        syncWithLocation: false,
        permanentFilter: [
            {
                field: "MainCostCategoryID",
                operator: "eq",
                value: parsedId ?? 0
            }
        ]
    });

    // Create Modal
    const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
        onFinish: createModelOnFinish,
        form: createModelForm
    } = useModalForm<ISubCostCategory>({
        action: "create",
        resource: "SubCostCategory",
        redirect: false
    });

    // Edit Modal
    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        onFinish: editModelOnFinish,
        form: editModelForm,
        queryResult: editModalQueryResult
    } = useModalForm<ISubCostCategory>({
        action: "edit",
        resource: "SubCostCategory",
        warnWhenUnsavedChanges: true,
        redirect: false
    });


    let formRefCreateProperty = React.useRef<any>(null);

    const onSelectedChangeCreateEnterProperty = (checked: boolean) => {
        formRefCreateProperty.current.setFieldsValue({
            statusID: checked ? 1 : 0,
            MainCostCategoryID: Data?.id ?? 0,
        });

    }

    let formRefEditProperty = React.useRef<any>(null);

    const onSelectedChangeEditEnterProperty = (checked: boolean) => {
        formRefEditProperty.current.setFieldsValue({
            statusID: checked ? 1 : 0,
            MainCostCategoryID: Data?.id ?? 0
        });
    }

    const editModelSubmit = () => {
        editModelOnFinish?.();
        editModelForm.resetFields();
    }

    const createModelSubmit = () => {
        createModelOnFinish?.();
        createModelForm.resetFields();
    }


    const { selectProps } = useSelect<IWorkflow>({
        resource: "Workflow",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "name",
                operator: "contains",
                value
            }
        ]
    });

    return (
        <>
            <Edit isLoading={formLoading}
                canDelete={(CheckPermissionsV2("Delete", "Business.Concrete.Definition.MainCostCategoryManager") && parsedId != undefined)}
                title={translate("buttons.edit")} recordItemId={parsedId} saveButtonProps={saveButtonProps}>

                <Tabs>
                    <TabPane tab={translate("menu.definition.maincostcategory")} key="1">
                        <Form {...formProps} layout="vertical" ref={formRef}>
                            <Form.Item label={translate("pages.currency.fields.id")} name="id" initialValue={Data?.id ?? 0} hidden>
                                <Input />
                            </Form.Item>
                            <Row gutter={24}>
                                <Col span={24} key="Name">
                                    <Form.Item label={translate("pages.maincostcategory.fields.name")} name="name" rules={[{ required: true, message: translate("rules.requiredName") }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24} key="Status">
                                    <Form.Item label={translate("pages.maincostcategory.fields.status")} name="statusID" initialValue={initialStatus}>
                                        <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </TabPane>
                    <TabPane tab={translate("pages.maincostcategory.fields.subcostcategories")} key="2" disabled={actionData == "create"}>
                        <Row gutter={24} hidden={actionData == "create"}>
                            <Col span={24} key="subcostcategories">
                                <Form.Item label="" name="subcostcategories">
                                    <List title="" breadcrumb={<></>} createButtonProps={{
                                        onClick: () => {
                                            onSelectedChangeCreateEnterProperty(true);
                                            createModalShow();
                                        },
                                    }}>
                                        <Table
                                            {...tableProps}
                                            rowKey="id"
                                            pagination={{
                                                ...tableProps.pagination,
                                                size: "small",
                                            }}
                                            className={isDarkMode ? "table-striped-rows-dark" : "table-striped-rows"}
                                        >
                                            <Table.Column dataIndex="id" title={translate("pages.subcostcategory.fields.id")} />
                                            <Table.Column dataIndex="name" title={translate("pages.subcostcategory.fields.name")} />
                                            <Table.Column dataIndex={["workflow", "name"]} title={translate("pages.maincostcategory.fields.workflowname")} />
                                            <Table.Column<ISubCostCategory>
                                                title="Actions"
                                                dataIndex="actions"
                                                key="actions"
                                                render={(_, record) => (
                                                    <Space>
                                                        <EditButton
                                                            hideText
                                                            size="small"
                                                            recordItemId={record.id}
                                                            onClick={() => {
                                                                onSelectedChangeEditEnterProperty(true);
                                                                editModalShow(record.id.toString())
                                                            }}
                                                        />
                                                        <DeleteButton
                                                            data-testid="edit-delete-button"
                                                            mutationMode="undoable"
                                                            onSuccess={() => { }}
                                                            resource="SubCostCategory"
                                                            recordItemId={record.id}
                                                        />
                                                    </Space>
                                                )}
                                            />
                                        </Table>
                                    </List>
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </Edit>
            <Modal {...createModalProps}>
                <Form {...createFormProps} layout="vertical" resource="SubCostCategory" ref={formRefCreateProperty} onFinish={createModelSubmit}>
                    <Form.Item label={translate("pages.subcostcategory.fields.name")} name="name" rules={[{ required: true, message: translate("rules.requiredName") }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="workflowID" label={translate("pages.subcostcategory.fields.workflowname")}>
                        <Select {...selectProps} />
                    </Form.Item>
                    <Form.Item label={translate("general.status.title")} name="statusID">
                        <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={true} onChange={onSelectedChangeCreateEnterProperty} />
                    </Form.Item>
                    <Form.Item name="MainCostCategoryID" hidden>
                        <Input disabled={true} value={parsedId} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal {...editModalProps}>
                <Form {...editFormProps} layout="vertical" resource="SubCostCategory" ref={formRefEditProperty} onFinish={editModelSubmit}>
                    <Form.Item label={translate("pages.subcostcategory.fields.name")} name="name" rules={[{ required: true, message: translate("rules.requiredName") }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="workflowID" label={translate("pages.subcostcategory.fields.workflowname")}>
                        <Select {...selectProps} />
                    </Form.Item>
                    <Form.Item label={translate("general.status.title")} name="statusID">
                        <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={true} onChange={onSelectedChangeEditEnterProperty} />
                    </Form.Item>
                    <Form.Item name="MainCostCategoryID" hidden>
                        <Input disabled={true} value={parsedId} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
