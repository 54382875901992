import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Select, Input, Row, Col, InputNumber, Switch } from "antd";
import { CrudFilters, CrudOperators, useTranslate } from "@refinedev/core";
import { ColumnFilterInfo } from "components/page/list/ListPage";
import { DatePicker, FormInstance } from "antd";
import { useState } from "react";
import { v4 as uuid } from 'uuid';
import dayjs from "dayjs";
import i18next from '../../i18n';

export interface DynamicAreaProperties {
    filters: Array<ColumnFilterInfo>,
    form: FormInstance,
    clearFilters: any
}


const Operators: CrudOperators[] = [
    "eq",
    "ne",
    "lt",
    "gt",
    "lte",
    "gte",
    "contains",
    "startswith",
    "endswith",
]



export const DynamicFormField = ({ filters, form, clearFilters }: DynamicAreaProperties) => {
    const translate = useTranslate();
    const [className, setClassName] = useState("");

    const getFieldType = (index: number) => {
        let filtersArea = form.getFieldValue("filters");

        if (filtersArea) {
            let filterArea = form.getFieldValue(["filters", index]);

            if (filterArea) {
                let value: string = form.getFieldValue(["filters", index])["field"];

                var fields = filters.filter((item: ColumnFilterInfo) => item.key == value);

                if (fields && fields.length > 0) {
                    let field = fields[0];
                    return field.type;
                }

            }
        }

        return "unknown";
    }

    const isSelectField = (index: number) => {
        let filtersArea = form.getFieldValue("filters");
        if (filtersArea) {
            let filterArea = form.getFieldValue(["filters", index]);

            if (filterArea) {
                let fieldName: string = form.getFieldValue(["filters", index])["field"];

                let resource = filters.filter((item: ColumnFilterInfo) => {
                    return item.key == fieldName && item.selectProps
                });

                if (resource && resource.length > 0) return true;

                return false;

            }
        }

        return false;
    }

    const getSelectProps = (index: number) => {
        let filtersArea = form.getFieldValue("filters");
        if (filtersArea) {
            let filterArea = form.getFieldValue(["filters", index]);

            if (filterArea) {
                let fieldName: string = form.getFieldValue(["filters", index])["field"];

                let resource = filters.filter((item: ColumnFilterInfo) => {
                    return item.key == fieldName && item.selectProps
                });

                if (resource && resource.length > 0) {
                    return resource[0].selectProps;
                }

                return null;

            }
        }

        return null;
    }

    const getOperatorName = (index: number): string => {
        return i18next.t("Operators." + Operators[index].toString())
    }

    const reRenderValueArea = (index: number) => {
        var formFilters = form.getFieldValue("filters");
        formFilters[index]["value"] = "";
        form.setFieldsValue({
            filters: formFilters
        });
        setClassName(uuid());
    }

    return (
        <Form.List name="filters">
            {
                (fields, { add, remove }) => {
                    return (
                        <div>
                            {fields.map((field, index) => (
                                <div key={field.key}>
                                    <Row gutter={24}>
                                        <Col span={6}>
                                            <Form.Item name={[index, "field"]} label={translate("ListPage.components.dynamicformfield.field")} required>
                                                <Select onChange={() => {
                                                    reRenderValueArea(index)
                                                }}>
                                                    {filters.map((filter: ColumnFilterInfo) => {
                                                        return (
                                                            <Select.Option key={filter.key} value={filter.key}>{filter.title}</Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Row gutter={24} className={className}>
                                                {
                                                    isSelectField(index) ?
                                                        <>
                                                            <Col span={24}>
                                                                <Form.Item name={[index, "operator"]} label={translate("ListPage.components.dynamicformfield.operation")} hidden initialValue={"eq"}>
                                                                </Form.Item>
                                                                <Form.Item name={[index, "value"]} label={translate("ListPage.components.dynamicformfield.value")} rules={[{ required: true }]}>
                                                                    <Select {...getSelectProps(index)} />
                                                                </Form.Item>
                                                            </Col>
                                                        </> :
                                                        <>
                                                            <Col span={12}>
                                                                <Form.Item name={[index, "operator"]} label={translate("ListPage.components.dynamicformfield.operation")} rules={[{ required: true }]}>
                                                                    <Select>
                                                                        {Object.keys(Operators).map((key: string, index) => (
                                                                            <Select.Option key={key} value={Operators[index]}>{getOperatorName(index)}</Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[index, "value"]} label={translate("ListPage.components.dynamicformfield.value")} rules={[{ required: true }]} >
                                                                    {
                                                                        (getFieldType(index) == "string") ? <Input placeholder={translate("ListPage.components.dynamicformfield.value")} /> :
                                                                            ((getFieldType(index) == "number") ? <InputNumber placeholder={translate("ListPage.components.dynamicformfield.value")} /> :
                                                                                ((getFieldType(index) == "date") ? <DatePicker /> :
                                                                                    ((getFieldType(index) == "datetime") ? <DatePicker /> :
                                                                                        ((getFieldType(index) == "boolean") ? <Switch /> : <></>))))
                                                                    }

                                                                </Form.Item>
                                                            </Col>
                                                        </>
                                                }


                                            </Row>
                                        </Col>

                                        <Col span={6}>
                                            {fields.length > 0 ? (
                                                <Button
                                                    danger
                                                    block
                                                    className="dynamic-delete-button"
                                                    onClick={() => {
                                                        remove(field.name)
                                                    }}
                                                    icon={<MinusCircleOutlined />}
                                                >
                                                    {translate("ListPage.components.dynamicformfield.removeField")}
                                                </Button>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Divider />
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    block
                                    onClick={() => {
                                        add()
                                    }}
                                >
                                    <PlusOutlined /> {translate("ListPage.components.dynamicformfield.addField")}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    danger
                                    block
                                    onClick={() => {
                                        clearFilters();

                                        fields.map((field, index) => {
                                            remove(field.name);
                                        });
                                    }}
                                >
                                    <MinusCircleOutlined /> {translate("ListPage.components.dynamicformfield.removeAllField")}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    block
                                >
                                    {translate("ListPage.buttons.filter")}
                                </Button>
                            </Form.Item>
                        </div>
                    );
                }
            }
        </Form.List>

    );
}
