
import { IInformation } from "entities/dashboard/IInformation";
import NumberCard from "components/card/numbercard";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useApiUrl, useCustom, useNavigation, useTranslate } from "@refinedev/core";
import { Card, Col, Row } from "antd";
import { DashboardTotalCountCard } from "components/dashboard/total-count-card";
import { Line, Pie, Plot } from "@ant-design/plots";
import { LineOptions } from "@antv/g2plot";

export const DashboardPage: React.FC = () => {
    const translate = useTranslate();
    const API_URL = useApiUrl();
    const { create, edit, clone, show, list, push, replace, goBack } = useNavigation();
    const url = `${API_URL}/Cost/GetDashboardInformation`;

    const { data: response, isLoading } = useCustom<{
        data: IInformation
    }>({ url, method: "get", queryOptions: { refetchInterval: 60000, refetchIntervalInBackground: true } });


    let pie_data = [];
    let color = [];

    if (response?.data?.data?.draftCount ?? 0 > 0) {
        pie_data.push({
            type: translate("pages.dashboard.draftcount"),
            value: response?.data.data.draftCount,
        });

        color.push("amber");
    }

    if (response?.data?.data?.waitingApprovalCount ?? 0 > 0) {
        pie_data.push({
            type: translate("pages.dashboard.waitingApprovalCount"),
            value: response?.data.data.waitingApprovalCount,
        });
        color.push("blue");
    }

    if (response?.data?.data?.approvedCount ?? 0 > 0) {
        pie_data.push({
            type: translate("pages.dashboard.approvedCount"),
            value: response?.data.data.approvedCount,
        });
        color.push("green");
    }

    if (response?.data?.data?.deniedCount ?? 0 > 0) {
        pie_data.push({
            type: translate("pages.dashboard.deniedCount"),
            value: response?.data.data.deniedCount,
        });
        color.push("red");
    }

    if (response?.data?.data?.willFixedCount ?? 0 > 0) {
        pie_data.push({
            type: translate("pages.dashboard.willFixedCount"),
            value: response?.data.data.willFixedCount,
        });
        color.push("orange");
    }

    if (response?.data?.data?.paymentCompletedCount ?? 0 > 0) {
        pie_data.push({
            type: translate("pages.dashboard.paymentCompletedCount"),
            value: response?.data.data.paymentCompletedCount,
        });
        color.push("purple");
    }

    if (response?.data?.data?.waitingMyApproval ?? 0 > 0) {
        pie_data.push({
            type: translate("pages.dashboard.waitingMyApproval"),
            value: response?.data.data.waitingMyApproval,
        });
        color.push("Violet");
    }

    if (pie_data.length == 0) {
        pie_data = [
            {
                type: translate("pages.dashboard.draftcount"),
                value: response?.data.data.draftCount,
            },
            {
                type: translate("pages.dashboard.waitingApprovalCount"),
                value: response?.data.data.waitingApprovalCount,
            },
            {
                type: translate("pages.dashboard.approvedCount"),
                value: response?.data.data.approvedCount,
            },
            {
                type: translate("pages.dashboard.deniedCount"),
                value: response?.data.data.deniedCount,
            },
            {
                type: translate("pages.dashboard.willFixedCount"),
                value: response?.data.data.willFixedCount,
            },
            {
                type: translate("pages.dashboard.paymentCompletedCount"),
                value: response?.data.data.paymentCompletedCount,
            },
            {
                type: translate("pages.dashboard.waitingMyApproval"),
                value: response?.data.data.waitingMyApproval,
            }
        ];

        color = ['amber', 'blue', 'green', 'red', 'orange', 'purple', 'Violet'];
    }


    const pie_data_config = {
        appendPadding: 10,
        data: pie_data,
        angleField: 'value',
        colorField: 'type',
        color,
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: translate("pages.dashboard.rates"),
            },
        },
    };




    const lineConfig = {
        data: response?.data.data.expenses ?? [],
        width: 800,
        height: 400,
        autoFit: false,
        xField: 'date',
        yField: 'totalPrice',
        point: {
            size: 5,
            shape: 'diamond',
        },
        label: {
            style: {
                fill: '#aaa',
            },
        },
    };


    return (
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Card loading={isLoading} title={translate("pages.dashboard.rates")}>
                    <Pie {...pie_data_config} />
                </Card>
            </Col>
            <Col span={12}>
                <Card loading={isLoading} title={translate("pages.dashboard.lastFiveExpenses")}>
                    <Line {...lineConfig} />
                </Card>
            </Col>
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    <Col xl={6} lg={24} md={24} sm={24} xs={24}>

                        <DashboardTotalCountCard variant="DraftCount" canRunQuery={false} value={response?.data.data.draftCount} url="/expense/costs?pageSize=10&current=1&filters[0][field]=CostStatusTypeID&filters[0][value]=0&filters[0][operator]=eq" />

                    </Col>
                    <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                        <DashboardTotalCountCard variant="WaitingApproval" canRunQuery={false} value={response?.data.data.waitingApprovalCount} url="/expense/costs?pageSize=10&current=1&filters[0][field]=CostStatusTypeID&filters[0][value]=1&filters[0][operator]=eq" />
                    </Col>
                    <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                        <DashboardTotalCountCard variant="ApprovedCount" canRunQuery={false} value={response?.data.data.approvedCount} url="/expense/costs?pageSize=10&current=1&filters[0][field]=CostStatusTypeID&filters[0][value]=4&filters[0][operator]=eq" />
                    </Col>
                    <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                        <DashboardTotalCountCard variant="DeniedCount" canRunQuery={false} value={response?.data.data.deniedCount} url="/expense/costs?pageSize=10&current=1&filters[0][field]=CostStatusTypeID&filters[0][value]=3&filters[0][operator]=eq" />
                    </Col>
                    <Col xl={3} lg={0} md={0} sm={0} xs={0}>

                    </Col>
                    <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                        <DashboardTotalCountCard variant="WillFixed" canRunQuery={false} value={response?.data.data.willFixedCount} url="/expense/costs?pageSize=10&current=1&filters[0][field]=CostStatusTypeID&filters[0][value]=2&filters[0][operator]=eq" />
                    </Col>
                    <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                        <DashboardTotalCountCard variant="PaymentCompleted" canRunQuery={false} value={response?.data.data.paymentCompletedCount} url="/expense/costs?pageSize=10&current=1&filters[0][field]=CostStatusTypeID&filters[0][value]=5&filters[0][operator]=eq" />
                    </Col>
                    <Col xl={6} lg={24} md={24} sm={24} xs={24}>

                        <DashboardTotalCountCard variant="WaitingMyApproval" canRunQuery={false} value={response?.data.data.waitingMyApproval} redirectResource="Approval" />
                    </Col>
                    <Col xl={3} lg={0} md={0} sm={0} xs={0}>

                    </Col>
                </Row>
            </Col>
        </Row>
    )
}