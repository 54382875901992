import { useEffect, useMemo } from "react";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { List, useTable } from "@refinedev/antd";
import { Card, Table, Typography } from "antd";
import GaugeChart from 'react-gauge-chart';
import ReactSpeedometer from "react-d3-speedometer"
import Config from "../../../config.json";

import { css } from 'aphrodite'
import sheet from './style';

import { IServiceCollectionResult } from "interfaces/Results/ServiceCollectionResult";
import { useDarkMode } from "usehooks-ts";

interface EndpointInfo {
    api: string,
    totalCount: number,
    errorCount: number,
    errorPercentage: number,
    rpm: number,
    apdexScore: number
}

export const RequestInfoPerEndpoint: React.FC = () => {
    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }

    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)
    const t = useTranslate();
    const API_URL = useApiUrl();

    const url = `${API_URL}/monitoring/getrequestinfopertimeperendpoint?minute=${Config.dashboardMonitorTime}`;

    const { tableProps } = useTable<EndpointInfo>({
        resource: "monitoring",
        syncWithLocation: false,

        meta: {
            method: "GET",
            endpoint: url
        }
    });

    let colors = ['#ff481a', '#f6951e', '#ecdb23', '#ade228', '#6bd72d'];
    let colorsReverse = ['#6bd72d', '#ade228', '#ecdb23', '#f6951e', '#ff481a'];

    return (
        <List title={t("dashboard.monitoring.requestinfoperendpoint.title") + " (" + Config.dashboardMonitorTime + ")"} >
            <Table
                {...tableProps}
                rowKey="api"
                className={isDarkMode ? "table-striped-rows-dark" : "table-striped-rows"}
                bordered
                size="large"
            >
                <Table.Column<EndpointInfo>
                    className={css(sheet.endpointCol)}
                    key="summary"
                    dataIndex="api"
                    title={t("dashboard.monitoring.requestinfoperendpoint.api")}
                />
                <Table.Column<EndpointInfo>
                    className={css(sheet.endpointCol)}
                    key="summary"
                    title={t("dashboard.monitoring.requestinfoperendpoint.totalCount")}
                    dataIndex="totalCount"
                />
                <Table.Column<EndpointInfo>
                    className={css(sheet.endpointCol)}
                    key="summary"
                    title={t("dashboard.monitoring.requestinfoperendpoint.errorCount")}
                    dataIndex="errorCount"
                />
                <Table.Column<EndpointInfo>
                    className={css(sheet.endpointCol)}
                    key="summary"
                    title={t("dashboard.monitoring.requestinfoperendpoint.rpm")}
                    dataIndex="rpm"
                />
                <Table.Column<EndpointInfo>
                    className={css(sheet.endpointCol)}
                    key="errorPercentage"
                    title={t("dashboard.monitoring.requestinfoperendpoint.errorPercentage")}
                    render={(_, record) => (
                        <ReactSpeedometer
                            width={200}
                            height={200}
                            key="errorPercentage"
                            maxValue={1}
                            value={Number(record.errorPercentage.toFixed(2))}
                            segments={5}
                            segmentColors={colorsReverse}
                            needleHeightRatio={0.6}
                        />

                    )}
                />
                <Table.Column<EndpointInfo>
                    className={css(sheet.endpointCol)}
                    key="apdexScore"
                    title={t("dashboard.monitoring.requestinfoperendpoint.apdexScore")}
                    render={(_, record) => (
                        <ReactSpeedometer
                            width={200}
                            height={200}
                            key="apdexScore"
                            maxValue={1}
                            value={Number(record.apdexScore.toFixed(2))}
                            segments={5}
                            segmentColors={colors}
                            needleHeightRatio={0.6}
                        />

                    )}
                />
            </Table>
        </List>

    );
};