import { IResourceComponentsProps, useGetIdentity,useNavigation,useParsed,useTranslate } from "@refinedev/core";
import { IFAQ } from "entities/content/IFAQ";
import { Dropdown, List, Menu, Table, Tabs } from "antd";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { DateField, DeleteButton, EditButton, FileField, NumberField, TagField, useFileUploadState, useTable } from "@refinedev/antd";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { text } from "stream/consumers";
import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { redirect } from "react-router-dom";

const { TabPane } = Tabs;
export const SSSList: React.FC<IResourceComponentsProps<DataType, OptionType>>= (props)=>{

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
      } = useParsed();

      const { list } = useNavigation();
      const { isLoading, onChange } = useFileUploadState();

        const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IFAQ>({
                syncWithLocation: true,
                sorters: {
                    initial: [
                        {
                            field: "ID",
                            order: "asc"
                        }
                    ]
                }
            });
        const translate = useTranslate();
        let filters: Array<ColumnFilterInfo> = [
                {
                    key: "ID",
                    title: translate("menu.faq.id"),
                    type: "number"
                },
                {
                    key: "question",
                    title: translate("menu.faq.question"),
                    type: "string"
                },
                {
                    key: "description",
                    title: translate("menu.faq.description"),
                    type: "string"
                },
                {
                    key: "action",
                    title: translate("menu.faq.action"),
                    type: "string",
                    
                    
                }
            ];
        
            let columns: Array<ColumnInfo> = [
                {
                    dataIndex: "id",
                    title: translate("menu.faq.id"),
                    sortable: true,
                    entityName: "ID"
                },
                {
                    dataIndex: "question",
                    title: translate("menu.faq.question")
                },
                {
                    dataIndex: "description",
                    title: translate("menu.faq.description")
                },
                {
                    dataIndex: "action",
                    title: translate("menu.faq.action"),
                    render: (props: RenderComponentProps) => (
                        <EditButton
                    size="small"
                    recordItemId={props.record.id}
                    value={translate("general.button.show")}
                
                    />
                    )
                  }
                ];
              
              
                return (
                  <ListPage
                    listProps={{
                      title: translate("menu.faq.title"),
                      canCreate: true,
                    }}
                    tableProps={tableProps}
                    columns={columns}
                    filters={filters}
                    rowKey="id"
                    setFilters={setFilters}
                    setSorter={setSorter}
                    isLoading={queryResult.isFetching}
                  />
                );
   
}