
import { ICost } from "../../../entities/expense/ICost";
import { ConvertStatusID } from "helpers/statusHelper";
import React, { useState } from "react";
import "./style.less";
import { IDepartment } from "entities/definition/IDepartment";
import { ICurrency } from "entities/definition/ICurrency";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { IMultiMediaSaveRequest } from "entities/expense/IMultiMediaSaveRequestd";
import dayjs from "dayjs";
import { useApiUrl, useGetIdentity, useNavigation, useNotification, useParsed, useTranslate } from "@refinedev/core";
import { Col, DatePicker, Form, Input, Row, Select, Switch, Tabs } from "antd";
import { Edit, useFileUploadState, useForm, useSelect } from "@refinedev/antd";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { useReadLocalStorage } from "usehooks-ts";
import { ApplicationSettings } from "entities/configuration/IApplicationSettings";
import { useGetIdentityInterface } from "models/useGetIdentityInterface";

const { TabPane } = Tabs;


export const CostCreate: React.FC = () => {
    const applicationSettings = useReadLocalStorage<ApplicationSettings>('ApplicationSettings')
    const apiUrl = useApiUrl();
    const { data: identity } = useGetIdentity<useGetIdentityInterface>();

    const { isLoading, onChange } = useFileUploadState();

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const [activeKey, setActiveKey] = useState("1");
    const { list, push } = useNavigation();

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult, form: costForm } = useForm<ICost>({
            redirect: false
            , action: "create",
            mutationMode: "undoable"
        });

    if (mutationResult.isSuccess) {
        push(`/Expense/Costs/${mutationResult.data.data.id}/edit?from=create`, {
            state: { from: 'create' },
        });
    }

    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 1;

    var costStatus = userData?.costStatusTypeID;
    const { open, close } = useNotification();

    const onSelectedChange = (checked: boolean) => {
        costForm.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    const { selectProps: departmentSelectProps } = useSelect<IDepartment>({
        resource: "Department",
        optionLabel: "name",
        optionValue: "id",
        defaultValue: [identity?.employee.departmentID ?? 0],
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "name",
                operator: "contains",
                value
            }
        ]
    });

    const { selectProps: currencySelectProps } = useSelect<ICurrency>({
        resource: "Currency",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 5000
    });

    return (
        <>
            <Edit isLoading={formLoading}
                saveButtonProps={saveButtonProps}
                title={translate("buttons.edit")} recordItemId={parsedId}
                canDelete={(CheckPermissionsV2("Delete", "Business.Concrete.Definition.CostManager") && parsedId != undefined)}>

                <Tabs activeKey={activeKey}>
                    <TabPane tab={translate("menu.expense.cost")} key="1">
                        <Form {...formProps} layout="vertical">
                            <Form.Item label={translate("pages.cost.fields.id")} name="id" initialValue={userData?.id ?? 0} hidden>
                                <Input />
                            </Form.Item>
                            <Row gutter={24}>
                                <Col span={12} key="description">
                                    <Form.Item label={translate("pages.cost.fields.description")} name="description">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[{ required: true }]}
                                        label={translate("pages.cost.fields.date")}
                                        name="date"
                                        getValueProps={(value) => ({
                                            value: value ? dayjs(value) : dayjs(),
                                        })}
                                        initialValue={dayjs()}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12} key="departmentID">
                                    <Form.Item label={translate("pages.cost.fields.department")} name="departmentID" initialValue={identity?.employee.departmentID}>
                                        <Select {...departmentSelectProps} showSearch={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={translate("pages.costitem.fields.currency")} name="currencyID" rules={[{ required: true }]} initialValue={applicationSettings?.defaultCurrencyID}>
                                        <Select {...currencySelectProps} showSearch={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} key="Status">
                                    <Form.Item label={translate("general.status.title")} name="statusID" initialValue={initialStatus == 1}>
                                        <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </TabPane>
                </Tabs>
            </Edit>
        </>
    );
};
