

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { ICostType } from "../../../entities/definition/ICostType";
import { useState } from "react";
import { ConvertStatusID, ConvertBoolean } from "helpers/statusHelper";
import React from "react";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import "./style.less";
import { IUserForView } from "entities/visa/IUserForView";
import { useConstantValueFromService, useConstantValueFromServiceString } from "hooks";
import { useNavigation, useParsed, useTranslate } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Col, Form, Input, Row, Switch } from "antd";



export const CostTypeEdit: React.FC = () => {

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    var actionData = "create";

    if (parsedId) {
        actionData = "edit";
    }

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<ICostType>({
            redirect: false,
            action: actionData == "create" ? "create" : "edit",
            mutationMode: "undoable"
        });
    const translate = useTranslate();
    const Data = queryResult?.data?.data;
    var initialStatus = Data?.statusID ?? 1;

    const { list } = useNavigation();

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    if (mutationResult.isSuccess) {
        redirect("list");
    }


    return (
        <Edit isLoading={formLoading}
            saveButtonProps={saveButtonProps}
            canDelete={(CheckPermissionsV2("Delete", "Business.Concrete.Definition.CostTypeManager") && parsedId != undefined)}
            title={translate("buttons.edit")} recordItemId={parsedId}>
            <Form {...formProps} layout="vertical" ref={formRef}>
                <Form.Item label={translate("pages.costtype.fields.id")} name="id" initialValue={Data?.id ?? 0} hidden>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12} key="Name">
                        <Form.Item label={translate("pages.costtype.fields.name")} name="name" rules={[{ required: true, message: translate("rules.requiredName") }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Status">
                        <Form.Item label={translate("general.status.title")} name="statusID" initialValue={initialStatus == 1}>
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
