import { StyleSheet } from 'aphrodite'

const sheet = StyleSheet.create({
    percentagecardWrapper: {
        height: '152px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '@media screen and (max-width: 576px)': {
            height: '112px'
        }
    },
    percentagecardWrapper_titleArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },

    percentagecardWrapper_titleArea__number: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1.1',
        width:'270px',
        '@media screen and (max-width: 576px)': {
            width:'240px'
        }
    },
    percentagecardWrapper_titleArea__number_span: {
        fontSize: '28px',        
        marginRight: '5px',
        '@media screen and (max-width: 576px)': {
            fontSize: '30px !important',
            lineHeight: '0.9'
        }
    }
});

export default sheet;