import { List, TagField, useTable, ShowButton } from "@refinedev/antd";
import { Table, Button } from "antd";
import { IResourceComponentsProps, useNotification, useTranslate } from "@refinedev/core";
import { IRequestLogResponse } from "../../../entities/configuration/IRequestLogResponse";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertRequestStatusToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { Breadcrumb } from "components/breadcrumb";
import { useState } from "react";
import { RedoOutlined } from "@ant-design/icons";
import { axiosInstance } from "providers/authProvider";
import { IServiceObjectResult } from "interfaces/Results/ServiceObjectResult";
import { getAllMessages } from "helpers/serviceResponseHelper";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { DateField } from "components/fields/date";

export const RequestLogList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IRequestLogResponse>({
        sorters: {
            initial: [
                {
                    field: "ID",
                    order: "desc"
                }
            ]
        }
    });
    const translate = useTranslate();
    const { open, close } = useNotification();

    const [isLoading, setLoading] = useState(false);

    const truncateLogs = () => {
        setLoading(true);
        axiosInstance.post("requestlog/truncate").then((response) => {
            var serviceResponse: IServiceObjectResult = response.data;
            var messages = getAllMessages(serviceResponse);

            if (messages.success && messages.success.length > 0) {
                var message = "";

                messages.success.map((item, index) => {

                    if (index == 0) message = item;
                    else message += " | " + item;
                });

                open?.({
                    message: message,
                    type: "success",
                    key: "updateallpermissions"
                });
            }

            if (messages.warning && messages.warning.length > 0) {
                var message = "";

                messages.warning.map((item, index) => {

                    if (index == 0) message = item;
                    else message += " | " + item;
                });

                open?.({
                    message: message,
                    type: "success",
                    key: "updateallpermissions"
                });
            }

            if (messages.error && messages.error.length > 0) {
                var message = "";

                messages.error.map((item, index) => {

                    if (index == 0) message = item;
                    else message += " | " + item;
                });

                open?.({
                    message: message,
                    type: "error",
                    key: "updateallpermissions"
                });
            }


        }).catch((error) => {
            if (error.response) {
                var messages = getAllMessages(error.response);
                if (messages.error && messages.error.length > 0) {
                    var message = "";

                    messages.error.map((item, index) => {

                        if (index == 0) message = item;
                        else message += " | " + item;
                    });

                    open?.({
                        message: message,
                        type: "error",
                        key: "updateallpermissions"
                    });
                }
            }
            else {
                open?.({
                    message: error.message,
                    type: "success",
                    key: "updateallpermissions"
                });
            }
        }).finally(() => {
            queryResult.refetch().finally(() => {
                setLoading(false);
            });
        });
    };

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.RequestLog.fields.id"),
            type: "number"
        },
        {
            key: "RequestMethod",
            title: translate("pages.RequestLog.fields.requestMethod"),
            type: "string"
        },
        {
            key: "RequestPath",
            title: translate("pages.RequestLog.fields.requestPath"),
            type: "string"
        },
        {
            key: "IpAdress",
            title: translate("pages.RequestLog.fields.ipAdress"),
            type: "string"
        },
        {
            key: "UserCreatedName",
            title: translate("pages.RequestLog.fields.userCreatedName"),
            type: "string"
        },
        {
            key: "TraceIdentifier",
            title: translate("pages.RequestLog.fields.traceIdentifier"),
            type: "string"
        },
        {
            key: "StatusCode",
            title: translate("pages.RequestLog.fields.statusCode"),
            type: "number"
        },
        {
            key: "DateCreated",
            title: translate("pages.RequestLog.fields.dateCreated"),
            type: "date"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.RequestLog.fields.id")
        },
        {
            dataIndex: "requestMethod",
            title: translate("pages.RequestLog.fields.requestMethod")
        },
        {
            dataIndex: "requestPath",
            title: translate("pages.RequestLog.fields.requestPath")
        },
        {
            dataIndex: "statusCode",
            title: translate("pages.RequestLog.fields.statusCode"),
            render: (props: RenderComponentProps) => (
                <TagField value={props.value} color={ConvertRequestStatusToColor(props.value)} />
            )
        },
        {
            dataIndex: "ipAdress",
            title: translate("pages.RequestLog.fields.ipAdress")
        },
        {
            dataIndex: "userCreatedName",
            title: translate("pages.RequestLog.fields.userCreatedName")
        },
        {
            dataIndex: "traceIdentifier",
            title: translate("pages.RequestLog.fields.traceIdentifier")
        },
        {
            dataIndex: "dateCreated",
            title: translate("pages.RequestLog.fields.dateCreated"),
            render: (props: RenderComponentProps) => (
                <DateField value={props.value} format="LLL" />

            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <ShowButton
                    size="small"
                    recordItemId={props.record.id}
                />

            )
        }
    ]

    return (
        <ListPage listProps={{
            title: translate("pages.RequestLog.title"),
            canCreate: false,
            headerButtons: <Button
                onClick={() => truncateLogs()}
                disabled={isLoading}
                icon={<RedoOutlined spin={isLoading} />}
            >
                {translate("pages.RequestLog.truncate")}
            </Button>
        }}
            tableProps={tableProps}
            columns={columns}
            filters={filters}
            rowKey="id"
            setFilters={setFilters}
            setSorter={setSorter}
            isLoading={queryResult.isFetching}
            standartOnRowRedirectToShow={true} />
    );
};