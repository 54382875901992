import { useEffect, useMemo } from "react";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Card, Spin, Typography } from "antd";
import GaugeChart from 'react-gauge-chart';

import NumberCard from "components/card/numbercard";

export const ActiveRequest: React.FC = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();

    const url = `${API_URL}/monitoring/getactiverequestcount`;
    const { data:response, isLoading,isLoadingError,refetch } = useCustom<{
        data: number
    }>({ url, method: "get",queryOptions:{refetchInterval:60000,refetchIntervalInBackground:true} });

    if(isLoadingError)
        refetch();

    const { Text, Title } = Typography;
    
    let percent = response?.data?.data ?? 0;

    return (
        <NumberCard title={t("dashboard.monitoring.activerequest")} value={percent} isLoading={isLoading} onClick={undefined}/>        
    );
};