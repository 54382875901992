import { Card, Typography } from "antd";
import GaugeChart from 'react-gauge-chart';
import { theme } from 'antd';

import { css } from 'aphrodite'
import sheet from './style';



export type PercentageCardProps = {
    backgroundColor?: string,
    title: string,
    chartLevel?: number,
    percent: number,
    colors?: Array<string>,
    isLoading: boolean,
    onClick: any
};

const defaultProps = {
    chartLevel: 20,
    colors: ["#FF0000", "#00FF00"]
}

const PercentageCard: React.FC<PercentageCardProps> = ({
    title,
    chartLevel,
    percent,
    colors,
    isLoading,
    onClick
}) => {
    const { Title } = Typography;
    const {
        token: { colorText },
    } = theme.useToken();
    return (
        <Card
            bodyStyle={{
                padding: 10,
                paddingBottom: 0,
            }}
            style={{
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
            }}
            loading={isLoading}
            onClick={onClick}
        >
            <div className={css(sheet.percentagecardWrapper)}>
                <div className={css(sheet.percentagecardWrapper_titleArea)}>
                    <Title level={4}>{title}</Title>
                    <div className={css(sheet.percentagecardWrapper_titleArea__number)}>
                        <GaugeChart id="gauge-apdex-chart"
                            nrOfLevels={chartLevel}
                            percent={percent}
                            colors={colors}
                            textColor={colorText}
                        />
                    </div>
                </div>
            </div>
        </Card>

    );
};

PercentageCard.defaultProps = defaultProps;

export default PercentageCard;