import React, { FC, PropsWithChildren, Suspense } from "react";

import { IResourceItem, useList, useNavigation, useTranslate } from "@refinedev/core";

import { AuditOutlined, ShopOutlined, TeamOutlined } from "@ant-design/icons";
import { AreaConfig } from "@ant-design/plots";
import { Card, Skeleton } from "antd";

import { Text } from "../../fields/text";

import styles from "./index.module.css";
import { UseListProps } from "@refinedev/core/dist/hooks/data/useList";

const Area = React.lazy(() => import("@ant-design/plots/es/components/area"));

type Type = "DraftCount" | "WaitingApproval" | "ApprovedCount"
    | "DeniedCount" | "WillFixed" | "PaymentCompleted" | "WaitingMyApproval" ;

export const DashboardTotalCountCard: React.FC<{
    color?: string;
    listProps?: any;
    variant: Type;
    value?: any,
    canRunQuery?: boolean,
    redirectResource?: string | IResourceItem,
    url?: string
}> = ({ color, listProps, variant, value, canRunQuery = true, redirectResource, url }) => {
    const { data, isLoading, isError, error } = useList({
        ...listProps,
        queryOptions: {
            enabled: canRunQuery
        }
    });
    const { list, push } = useNavigation();

    const translate = useTranslate();

    if (isError) {
        console.error("Error fetching dashboard data", error);
        return null;
    }

    const { primaryColor, secondaryColor, icon, title } = variants[variant];

    const config: AreaConfig = {
        className: styles.area,
        appendPadding: [1, 0, 0, 0],
        padding: 0,
        syncViewPadding: true,
        data: variants[variant].data,
        autoFit: true,
        tooltip: false,
        animation: false,
        xField: "index",
        yField: "value",
        xAxis: false,
        yAxis: {
            tickCount: 12,
            label: {
                style: {
                    fill: "transparent",
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: "transparent",
                    },
                },
            },
        },
        smooth: true,
        areaStyle: () => {
            return {
                fill: `l(270) 0:#fff 0.2:${secondaryColor} 1:${primaryColor}`,
            };
        },
        line: {
            color: primaryColor,
        },
    };


    return (
        <Card
            style={{ height: "96px", padding: 0, cursor: "pointer" }}
            bodyStyle={{
                padding: "8px 8px 8px 12px",
            }}
            size="small"
            onClick={() => {
                if (url) push(url);
                else
                    list(redirectResource ?? "");
            }}
            color={color}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    whiteSpace: "nowrap",
                }}
            >
                {icon}
                <Text
                    size="md"
                    className="secondary"
                    style={{ marginLeft: "8px" }}
                >
                    {translate(title)}
                </Text>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Text
                    size="xxl"
                    strong
                    style={{
                        textAlign: "start",
                        marginLeft: "48px",
                        fontVariantNumeric: "tabular-nums",
                    }}
                >
                    {(isLoading && canRunQuery) ? (
                        <Skeleton.Button
                            style={{
                                marginTop: "8px",
                                width: "74px",
                            }}
                        />
                    ) : canRunQuery ? (
                        data?.total
                    ) : (
                        value
                    )}
                </Text>
                <Suspense>
                    <Area {...config} />
                </Suspense>
            </div>
        </Card>
    );
};

const IconWrapper: FC<PropsWithChildren<{ color: string }>> = ({
    color,
    children,
}) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                backgroundColor: color,
            }}
        >
            {children}
        </div>
    );
};

const variants: {
    [key in Type]: {
        primaryColor: string;
        secondaryColor?: string;
        icon: React.ReactNode;
        title: string;
        data: { index: string; value: number }[];
    };
} = {
    DraftCount: {
        primaryColor: "#1677FF",
        secondaryColor: "#BAE0FF",
        icon: (
            <IconWrapper color="amber">
                <ShopOutlined
                    className="md"
                    style={{
                        color: "black",
                    }}
                />
            </IconWrapper>
        ),
        title: "pages.dashboard.draftcount",
        data: [],
    },
    WaitingApproval: {
        primaryColor: "#1677FF",
        secondaryColor: "#BAE0FF",
        icon: (
            <IconWrapper color="blue">
                <ShopOutlined
                    className="md"
                    style={{
                        color: "white",
                    }}
                />
            </IconWrapper>
        ),
        title: "pages.dashboard.waitingApprovalCount",
        data: [],
    },
    ApprovedCount: {
        primaryColor: "#1677FF",
        secondaryColor: "#BAE0FF",
        icon: (
            <IconWrapper color="green">
                <ShopOutlined
                    className="md"
                    style={{
                        color: "white",
                    }}
                />
            </IconWrapper>
        ),
        title: "pages.dashboard.approvedCount",
        data: [],
    },
    DeniedCount: {
        primaryColor: "#1677FF",
        secondaryColor: "#BAE0FF",
        icon: (
            <IconWrapper color="red">
                <ShopOutlined
                    className="md"
                    style={{
                        color: "white",
                    }}
                />
            </IconWrapper>
        ),
        title: "pages.dashboard.deniedCount",
        data: [],
    },
    WillFixed: {
        primaryColor: "#1677FF",
        secondaryColor: "#BAE0FF",
        icon: (
            <IconWrapper color="orange">
                <ShopOutlined
                    className="md"
                    style={{
                        color: "white",
                    }}
                />
            </IconWrapper>
        ),
        title: "pages.dashboard.willFixedCount",
        data: [],
    },
    PaymentCompleted: {
        primaryColor: "#1677FF",
        secondaryColor: "#BAE0FF",
        icon: (
            <IconWrapper color="purple">
                <ShopOutlined
                    className="md"
                    style={{
                        color: "white",
                    }}
                />
            </IconWrapper>
        ),
        title: "pages.dashboard.paymentCompletedCount",
        data: [],
    },
    WaitingMyApproval: {
        primaryColor: "#1677FF",
        secondaryColor: "#BAE0FF",
        icon: (
            <IconWrapper color="Violet">
                <ShopOutlined
                    className="md"
                    style={{
                        color: "white",
                    }}
                />
            </IconWrapper>
        ),
        title: "pages.dashboard.waitingMyApproval",
        data: [],
    },
  
   
};

