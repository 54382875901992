

import { IMainCostCategory } from "../../../entities/definition/IMainCostCategory";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor, ConvertBoolean, ConvertBooleanToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { ISubCostCategory } from "entities/definition/ISubCostCategory";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { EditButton, TagField, useTable } from "@refinedev/antd";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";

export const MainCostCategoryList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IMainCostCategory>();
    const translate = useTranslate();

    const expandedRowRender = (record: IMainCostCategory) => {
        var items = record.subCostCategories.map((item: ISubCostCategory) => {
            return <TagField value={item.name} color="success" />
        });
        return (
            <>
                {items}
            </>
        );
    };

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.maincostcategory.fields.id"),
            type: "number"
        },
        {
            key: "Name",
            title: translate("pages.maincostcategory.fields.name"),
            type: "string"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.maincostcategory.fields.id")
        },
        {
            dataIndex: "name",
            title: translate("pages.maincostcategory.fields.name")
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <EditButton
                    size="small"
                    recordItemId={props.record.id}
                />

            )
        }
    ]

    
    tableProps.expandable = {
        expandedRowRender
    };

    return (
        <ListPage listProps={{
            title: translate("pages.maincostcategory.title"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Definition.MainCostCategoryManager")
        }}
            tableProps={tableProps}
            columns={columns}
            filters={filters}
            rowKey="id"
            setFilters={setFilters}
            setSorter={setSorter}
            isLoading={queryResult.isFetching}
            standartOnRowRedirectToShow={false} />
    );
};