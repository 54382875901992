import React, { Component, useEffect, useState } from "react";
import { Page, Document, PDFViewer, View } from "@react-pdf/renderer";
import { CostList } from "pages/expense/cost/list";
import { ICost } from "entities/expense/ICost";
import {
  Button,
  Card,
  Col,
  Image,
  Input,
  QRCode,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import { ICostItem } from "entities/expense/ICostItem";
import { TitleProps, useTranslate } from "@refinedev/core";
import { ApplicationSettingsEdit } from "pages/configuration/applicationsettings";
import { ApplicationTitle } from "components/layout";
import { StyleSheet } from "aphrodite";
import { Text } from "../../components/fields/text";
import { DateField } from "components/fields/date";
import { usePDF } from "react-to-pdf";
import { Show } from "@refinedev/antd";
import { useDarkMode, useEffectOnce, useLocalStorage } from "usehooks-ts";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",

    container: {
      flex: 1,
    },
  },
  section: {
    margin: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    maxWidth: "20%",
    marginTop: "100px",
  },
});

const PDFFile = ({
  cost,
  isLoading,
}: {
  cost: ICost | undefined;

  isLoading: boolean;
}) => {
  const qrtext =
    "https://expense.appouse.com/verification/" + cost?.verificationKey;

  const translate = useTranslate();
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const { toPDF, targetRef } = usePDF({
    filename: "masraf_summary.pdf",
    method: "save",
    canvas: {
      useCORS: false,
      logging: true,
    },
    overrides: {
      canvas: {
        useCORS: false,
      },
    },
  });

  var initialDarkMode: boolean = false;
  var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

  if (localStorageTheme === "true") {
    initialDarkMode = true;
  }

  const { disable } = useDarkMode(initialDarkMode);

  useEffectOnce(() => {
    disable();
  });

  return (
    <Spin spinning={isLoading}>
      {cost !== undefined ? (
      
        <Document > 
          <Page size={"A4"} style={styles.page}> 
       
        <View style={styles.section}>
        <Row gutter={[20, 20]} ref={targetRef}>

            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Row>
                    <Col span={10}>
                      <ApplicationTitle
                        collapsed={false}
                        ignoreBottomLine={true}
                        classes={[styles.logo]}
                        onLoad={() => {
                          setIsImageLoaded(true);
                        }}
                      />
                    </Col>
                    <Col span={8}></Col>

                    <Col span={2}>
                      <QRCode
                        value={qrtext}
                        size={100}
                        style={{ marginTop: 30 }}
                      />
                    </Col>
                    <Col span={2}></Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <Col span={1}></Col>
                    <Col span={15}>
                      <Row gutter={[24, 24]}>
                        <Col span={24}>
                          <Card
                            title={
                              <Text size="xl">
                                {translate("pages.pdf.fields.title")}
                              </Text>
                            }
                          >
                            <Row>
                              <Col>
                                <Text>
                                  <Row gutter={[24, 24]}>
                                    <Col span={8}>
                                      <Text size="lg">
                                        {translate("pages.pdf.fields.date")}:
                                      </Text>{" "}
                                      <Text size="md">
                                        <DateField value={cost?.date} />
                                      </Text>
                                    </Col>
                                    <Col span={8}>
                                      <Text size="lg">
                                        {translate("pages.pdf.fields.firmName")}
                                        :{" "}
                                      </Text>
                                      <Text size="md">
                                        {cost?.department.name}{" "}
                                      </Text>
                                    </Col>
                                    <Col span={8}>
                                      <Text size="lg">
                                        {translate("pages.pdf.fields.currency")}
                                        :{" "}
                                      </Text>
                                      <Text size="md">
                                        {" "}
                                        {cost?.currency?.name}
                                      </Text>
                                    </Col>

                                    <Col span={24}>
                                      <Text size="lg">
                                        {translate(
                                          "pages.pdf.fields.description"
                                        )}
                                        :
                                      </Text>{" "}
                                      <Text size="md">
                                        {cost?.description}{" "}
                                      </Text>
                                    </Col>
                                  </Row>
                                </Text>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={7}>
                      <Card title={<Text size="lg">Çalışan Bilgileri</Text>}>
                        <Row gutter={[24, 24]}>
                          <Col span={24}>
                            <Text size="lg">
                              {translate("pages.pdf.fields.employee")}:{" "}
                            </Text>
                            <Text size="md">
                              {cost?.employee?.user?.firstname}{" "}
                              {cost?.employee?.user?.lastname}
                            </Text>
                          </Col>
                          <Col span={24}>
                            <Text size="xl">
                              {" "}
                              {translate("pages.pdf.fields.department")}:{" "}
                            </Text>
                            <Text size="lg">
                              {cost?.employee?.department?.name}
                            </Text>
                          </Col>
                          <Col span={24}>
                            <Text size="xl">
                              {" "}
                              {translate("pages.pdf.fields.email")}:
                            </Text>{" "}
                            <Text size="lg">{cost?.employee?.user?.email}</Text>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={1}></Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={1}></Col>
                   
                      <Col span={22}>
                        <Table dataSource={cost?.costItems} pagination={false}>
                          <Table.Column<ICostItem>
                            dataIndex="date"
                            title={translate("pages.pdf.fields.date")}
                            render={(value, record) => {
                              return <DateField value={value} />;
                            }}
                          />
                          <Table.Column<ICostItem>
                            dataIndex="description"
                            title={translate("pages.pdf.fields.description")}
                          />
                          <Table.Column<ICostItem>
                            dataIndex={["subCostCategory", "label"]}
                            title={translate(
                              "pages.pdf.fields.subCostCategory"
                            )}
                          />
                          <Table.Column<ICostItem>
                            dataIndex="categoryDescription"
                            title={translate(
                              "pages.pdf.fields.categoryDescription"
                            )}
                          />
                          <Table.Column<ICostItem>
                            dataIndex={["costType", "name"]}
                            title={translate("pages.pdf.fields.costtype")}
                          />
                          <Table.Column<ICostItem>
                            dataIndex="documentNo"
                            title={translate("pages.pdf.fields.documentNo")}
                          />
                          <Table.Column<ICostItem>
                            dataIndex="firmName"
                            title={translate("pages.pdf.fields.firmName")}
                          />
                          <Table.Column<ICostItem>
                            dataIndex="totalPrice"
                            title={translate("pages.pdf.fields.totalPrice")}
                          />
                          <Table.Column<ICostItem>
                            dataIndex="tax"
                            title={translate("pages.pdf.fields.tax")}
                          />
                           <Table.Column<ICostItem>
                            dataIndex={["medias", "url"]}
                            title={translate("pages.pdf.fields.medias")}
                            render={()=> <Image src="https://cdnexpensev2.appouse.com/2023-10-15/market-fis_195407904.jpg" width={80} height={80}/>}
                          />
                        </Table>
                      </Col>
                      <Col span={1}> </Col>
                 
                <Col span={1}></Col>
              </Row>
            </Col>
          </Row>
        </View>
       
        </Page>
        </Document>
       
      ) : (
        "Masraf Bulunamadı"
      )}
     
    </Spin>
   
  );
  
  /*
  return (
    <Spin spinning={isLoading}>
      {cost !== undefined ? (
        <Document ref={targetRef}>
          <Page size="A4" style={styles.page}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Row>
                      <Col span={10}>
                        <ApplicationTitle
                          collapsed={false}
                          ignoreBottomLine={true}
                          classes={[styles.logo]}
                          onLoad={() => {
                            setIsImageLoaded(true);
                          }}
                        />
                      </Col>
                      <Col span={8}></Col>

                      <Col span={2}>
                        <QRCode
                          value={qrtext}
                          size={100}
                          style={{ marginTop: 30 }}
                        />
                      </Col>
                      <Col span={2}></Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[24, 24]}>
                      <Col span={1}></Col>
                      <Col span={15}>
                        <Row gutter={[24, 24]}>
                          <Col span={24}>
                            <Card
                              title={
                                <Text size="xl">
                                  {translate("pages.pdf.fields.title")}
                                </Text>
                              }
                            >
                              <Row>
                                <Col>
                                  <Text>
                                    <Row gutter={[24, 24]}>
                                      <Col span={8}>
                                        <Text size="lg">
                                          {translate("pages.pdf.fields.date")}:
                                        </Text>{" "}
                                        <Text size="md">
                                          <DateField value={cost?.date} />
                                        </Text>
                                      </Col>
                                      <Col span={8}>
                                        <Text size="lg">
                                          {translate(
                                            "pages.pdf.fields.firmName"
                                          )}
                                          :{" "}
                                        </Text>
                                        <Text size="md">
                                          {cost?.department.name}{" "}
                                        </Text>
                                      </Col>
                                      <Col span={8}>
                                        <Text size="lg">
                                          {translate(
                                            "pages.pdf.fields.currency"
                                          )}
                                          :{" "}
                                        </Text>
                                        <Text size="md">
                                          {" "}
                                          {cost?.currency?.name}
                                        </Text>
                                      </Col>

                                      <Col span={24}>
                                        <Text size="lg">
                                          {translate(
                                            "pages.pdf.fields.description"
                                          )}
                                          :
                                        </Text>{" "}
                                        <Text size="md">
                                          {cost?.description}{" "}
                                        </Text>
                                      </Col>
                                    </Row>
                                  </Text>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={7}>
                        <Card title={<Text size="lg">Çalışan Bilgileri</Text>}>
                          <Row gutter={[24, 24]}>
                            <Col span={24}>
                              <Text size="lg">
                                {translate("pages.pdf.fields.employee")}:{" "}
                              </Text>
                              <Text size="md">
                                {cost?.employee?.user?.firstname}{" "}
                                {cost?.employee?.user?.lastname}
                              </Text>
                            </Col>
                            <Col span={24}>
                              <Text size="xl">
                                {" "}
                                {translate("pages.pdf.fields.department")}:{" "}
                              </Text>
                              <Text size="lg">
                                {cost?.employee?.department?.name}
                              </Text>
                            </Col>
                            <Col span={24}>
                              <Text size="xl">
                                {" "}
                                {translate("pages.pdf.fields.email")}:
                              </Text>{" "}
                              <Text size="lg">
                                {cost?.employee?.user?.email}
                              </Text>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col span={1}></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row gutter={[24, 24]}>
                  <Col span={1}></Col>
                  <Col span={22}>
                    <Card>
                      <Row>
                        <Col span={22}>
                          <View>
                            <Table
                              dataSource={cost?.costItems}
                              pagination={false}
                            >
                              <Table.Column<ICostItem>
                                dataIndex="date"
                                title={translate("pages.pdf.fields.date")}
                                render={(value, record) => {
                                  return <DateField value={value} />;
                                }}
                              />
                              <Table.Column<ICostItem>
                                dataIndex="description"
                                title={translate(
                                  "pages.pdf.fields.description"
                                )}
                              />
                              <Table.Column<ICostItem>
                                dataIndex={["subCostCategory", "label"]}
                                title={translate(
                                  "pages.pdf.fields.subCostCategory"
                                )}
                              />
                              <Table.Column<ICostItem>
                                dataIndex="categoryDescription"
                                title={translate(
                                  "pages.pdf.fields.categoryDescription"
                                )}
                              />
                              <Table.Column<ICostItem>
                                dataIndex={["costType", "name"]}
                                title={translate("pages.pdf.fields.costtype")}
                              />
                              <Table.Column<ICostItem>
                                dataIndex="documentNo"
                                title={translate("pages.pdf.fields.documentNo")}
                              />
                              <Table.Column<ICostItem>
                                dataIndex="firmName"
                                title={translate("pages.pdf.fields.firmName")}
                              />
                              <Table.Column<ICostItem>
                                dataIndex={["currency", "label"]}
                                title={translate("pages.pdf.fields.currency")}
                              />
                              <Table.Column<ICostItem>
                                dataIndex="totalPrice"
                                title={translate("pages.pdf.fields.totalPrice")}
                              />
                              <Table.Column<ICostItem>
                                dataIndex="tax"
                                title={translate("pages.pdf.fields.tax")}
                              />
                            </Table>
                          </View>
                        </Col>
                        <Col span={1}> </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={1}></Col>
                </Row>
              </Col>
            </Row>
          </Page>
        </Document>
      ) : (
        "Masraf Bulunamadı"
      )}
    </Spin>
  );
  */
};
export default PDFFile;
