import { MouseEventHandler, useEffect, useMemo } from "react";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Card, Col, Row, Spin, Typography } from "antd";
import GaugeChart from 'react-gauge-chart';

import { css } from 'aphrodite'
import sheet from './style';

export type NumberCardProps = {
    value: number | undefined,
    backgroundColor?: string,
    title: string,
    isLoading: boolean,
    onClick: any,
    image?: any | undefined
};


const NumberCard: React.FC<NumberCardProps> = ({
    value,
    title,
    isLoading,
    onClick,
    image
}) => {
    const { Title } = Typography;



    return (
        <Card

            bodyStyle={{
                padding: 10,
                paddingBottom: 0,
            }}
            style={{
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
            }}
            loading={isLoading}
            onClick={onClick}
        >

            {
                image ? (
                    <Row gutter={24}>
                        <Col span={18}>
                            <div className={css(sheet.numbercardWrapper)}>
                                <div className={css(sheet.numbercardWrapper_titleArea)}>
                                    <Title level={3}>{title} </Title>
                                </div>
                                <div className={css(sheet.numbercardWrapper_valueArea)}>
                                    <Title level={3} className={css(sheet.numbercardWrapper_valueArea__number)}>{value} </Title>
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            {image}
                        </Col>
                    </Row>
                ) : (
                    <div className={css(sheet.numbercardWrapper)}>
                        <div className={css(sheet.numbercardWrapper_titleArea)}>
                            <Title level={4}>{title} </Title>
                        </div>
                        <div className={css(sheet.numbercardWrapper_valueArea)}>
                            <Title level={4} className={css(sheet.numbercardWrapper_valueArea__number)}>{value} </Title>
                        </div>
                    </div>
                )
            }
        </Card>
    );
};


export default NumberCard;