import { useMemo } from "react";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Card, Spin, Typography } from "antd";
import GaugeChart from 'react-gauge-chart';

import PercentageCard from "components/card/percentagecard";

export const ErrorPercentage: React.FC = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();

    const url = `${API_URL}/monitoring/geterrorpercentage`;
    const { data:response, isLoading } = useCustom<{
        data: number
    }>({ url, method: "get" });

    const { Text, Title } = Typography;
    
    let percent = response?.data?.data ?? 0;

    return (
        <PercentageCard title={t("dashboard.monitoring.errorpercentage")} percent={percent} isLoading={isLoading} onClick={undefined} colors={["#00FF00","#FF0000"]} chartLevel={20}/>        
    );
};