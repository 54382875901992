import { useTranslate, IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, MarkdownField } from "@refinedev/antd";
import { Grid, Typography, Table } from "antd";

import { IRequestLogResponse } from "./../../../entities/configuration/IRequestLogResponse";


const { Title, Text } = Typography;



export const RequestLogShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult: RequestLogQueryResult } = useShow<IRequestLogResponse>();
    const { data, isLoading } = RequestLogQueryResult;
    const record = data?.data;

    const translate = useTranslate();

    return (
        <Show isLoading={isLoading} title={record?.traceIdentifier + " | Status: " + record?.statusCode}>
            <Title level={5}>{translate("pages.RequestLog.fields.id")}</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.traceIdentifier")}</Title>
            <Text>{record?.traceIdentifier}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.ipAdress")}</Title>
            <Text>{record?.ipAdress}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.executeTime")}</Title>
            <Text>{record?.executeTime}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.userAgent")}</Title>
            <Text>{record?.userAgent}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.userCreatedName")}</Title>
            <Text>{record?.userCreatedName}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.dataOut")}</Title>
            <Text>{record?.dataOut}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.controllerName")}</Title>
            <Text>{record?.controllerName}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.actionName")}</Title>
            <Text>{record?.actionName}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.requestMethod")}</Title>
            <Text>{record?.requestMethod}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.requestPath")}</Title>
            <Text>{record?.requestPath}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.queryString")}</Title>
            <Text>{record?.queryString}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.parameters")}</Title>
            <Text>{record?.parameters}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.requestHeaders")}</Title>
            <Text>{record?.requestHeaders}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.requestBody")}</Title>
            <Text>{record?.requestBody}</Text>

            <Title level={5}>{translate("pages.RequestLog.fields.responseBody")}</Title>
            <Text>{record?.responseBody}</Text>

        </Show>
    );
};