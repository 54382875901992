import { ICurrency } from "../../../entities/definition/ICurrency";
import React, { useState } from "react";
import { useConstantValueFromService, useFormValues } from "hooks";
import {
  useApiUrl,
  useCustom,
  useNotification,
  useParsed,
  useTranslate,
} from "@refinedev/core";
import {
  DeleteButton,
  Edit,
  List,
  ListButton,
  RefreshButton,
  SaveButton,
  useForm,
  useSelect,
} from "@refinedev/antd";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { ICostReport } from "entities/report/ICostReport";
import { ICostType } from "entities/definition/ICostType";
import { IEmployee } from "entities/visa/IEmployee";
import { IDepartment } from "entities/definition/IDepartment";
import { ISubCostCategory } from "entities/definition/ISubCostCategory";
import { IMainCostCategory } from "entities/definition/IMainCostCategory";
import { ExportOutlined } from "@ant-design/icons";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { axiosInstance } from "providers/authProvider";
import { AxiosResponse } from "axios";
import { IServiceObjectResult } from "interfaces/Results/ServiceObjectResult";
import dayjs from "dayjs";

export const CostReportEdit: React.FC = () => {
  const {
    resource: parsedResource,
    action: parsedAction,
    id: parsedId,
    pathname: parsedPathname,
    params: parsedParams,
  } = useParsed();

  var actionData = "create";

  if (parsedId) {
    actionData = "edit";
  }

  const {
    formProps,
    saveButtonProps,
    queryResult,
    onFinish,
    redirect,
    formLoading,
    mutationResult,
    form,
  } = useForm<ICostReport>({
    redirect: false,
    action: actionData == "create" ? "create" : "edit",
    mutationMode: "undoable",
    warnWhenUnsavedChanges: false,
  });

  const translate = useTranslate();
  const { open, close } = useNotification();

  const apiUrl = useApiUrl();

  const [isLoading, setIsLoading] = useState(false);

  const getReportInstant = () => {
    console.log("getReportInstant");
    setIsLoading(true);

    axiosInstance
      .post(`${apiUrl}/CostReport/GenerateExcelInstant`, form.getFieldsValue())
      .then((data: AxiosResponse<IServiceObjectResult>) => {
        const link = document.createElement("a");
        link.href = data.data.data;
        link.download = "report.xlsx";
        link.click();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { selectProps: mainCategorySelectProps } = useSelect<IMainCostCategory>(
    {
      resource: "MainCostCategory",
      optionLabel: "name",
      optionValue: "id",
      fetchSize: 1000,
      debounce: 5000,
    }
  );

  const { selectProps: subCategorySelectProps } = useSelect<ISubCostCategory>({
    resource: "SubCostCategory",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 1000,
    debounce: 5000,
  });

  const { selectProps: departmentSelectProps } = useSelect<IDepartment>({
    resource: "Department",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 1000,
    debounce: 5000,
  });

  const { selectProps: employeeSelectProps } = useSelect<IEmployee>({
    resource: "Employee",
    optionLabel: "label",
    optionValue: "id",
    fetchSize: 1000,
    debounce: 5000,
  });

  const { selectProps: currencySelectProps } = useSelect<ICurrency>({
    resource: "Currency",
    optionLabel: "label",
    optionValue: "id",
    fetchSize: 1000,
    debounce: 5000,
  });

  const { selectProps: costTypeSelectProps } = useSelect<ICostType>({
    resource: "CostType",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 1000,
    debounce: 5000,
  });

  const { selectProps: costStatusTypeSelectProps } =
    useConstantValueFromService("getcoststatustype");

  if (mutationResult.isSuccess) {
    redirect("list");
  }

  const [requireReportName, setRequireReportName] = useState(true);

  return (
    <Edit
      isLoading={formLoading}
      headerButtons={({ defaultButtons }) => (
        <>
          <ListButton
            data-testid="edit-list-button"
            resource={parsedResource?.name}
            children={translate("menu.report.costreport")}
          />
          {parsedAction == "edit" && (
            <RefreshButton
              resource={parsedResource?.name}
              recordItemId={parsedId}
            />
          )}
        </>
      )}
      footerButtons={({ defaultButtons }) => (
        <>
          <SaveButton
            {...saveButtonProps}
            onClick={async () => {
              await onFinish?.();
            }}
          />
          <Button onClick={getReportInstant} loading={isLoading}>
            {translate("pages.categorycostreport.fields.GetNow")}
          </Button>
          {CheckPermissionsV2(
            "Delete",
            "Business.Concrete.Expense.CostReportManager"
          ) &&
            parsedId && (
              <DeleteButton
                data-testid="edit-delete-button"
                mutationMode="undoable"
                onSuccess={() => {
                  redirect("list");
                }}
              />
            )}
        </>
      )}
      title={translate("menu.report.costreport")}
      recordItemId={parsedId}
    >
      <Form {...formProps}>
        <Row gutter={[24, 24]}>
          <Col span={14}>
            <Form.Item
              label={translate("pages.categorycostreport.fields.reportName")}
              name="name"
              rules={[{ required: requireReportName }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={translate("pages.categorycostreport.fields.startDate")}
              name="startDate"
              getValueProps={(
                value: string | number | dayjs.Dayjs | Date | null | undefined
              ) => ({
                value: value ? dayjs(value) : dayjs(),
              })}
              initialValue={dayjs()}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={translate("pages.categorycostreport.fields.endDate")}
              name="endDate"
              getValueProps={(
                value: string | number | dayjs.Dayjs | Date | null | undefined
              ) => ({
                value: value ? dayjs(value) : dayjs().add(7, "day"),
              })}
              initialValue={dayjs()}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={translate("pages.categorycostreport.fields.reportType")}
              name="reportTypeID"
              initialValue={0}
            >
              <Select
                options={[
                  {
                    value: 0,
                    label: "Date",
                  },
                  {
                    value: 1,
                    label: "Personel",
                  },
                  {
                    value: 2,
                    label: "Main Category",
                  },
                  {
                    value: 3,
                    label: "Sub Category",
                  },
                ]}
                defaultValue={0}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={translate(
                "pages.categorycostreport.fields.mainCostCategory"
              )}
              name="mainCostCategory"
            >
              <Select {...mainCategorySelectProps} mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={translate(
                "pages.categorycostreport.fields.subCostCategory"
              )}
              name="subCostCategory"
            >
              <Select {...subCategorySelectProps} mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={translate("pages.categorycostreport.fields.department")}
              name="departmentIDs"
            >
              <Select {...departmentSelectProps} mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={translate("pages.categorycostreport.fields.employee")}
              name="employeeIDs"
            >
              <Select {...employeeSelectProps} mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={translate("pages.categorycostreport.fields.currency")}
              name="currencyIDs"
            >
              <Select {...currencySelectProps} mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={translate("pages.categorycostreport.fields.costType")}
              name="costTypeIDs"
            >
              <Select {...costTypeSelectProps} mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={translate(
                "pages.categorycostreport.fields.costStatusType"
              )}
              name="costStatusTypeIDs"
            >
              <Select {...costStatusTypeSelectProps} mode="multiple" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
