import styled from "@emotion/styled";

export const Logo = styled.div`
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InnerLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;
