import { List, useTable } from "@refinedev/antd";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import React, { useEffect, useState } from "react";
import Config from "../../../config.json";
import { Card, Col, Row, Statistic, Table } from "antd";
import ReactSpeedometer from "react-d3-speedometer";
import { useDarkMode } from "usehooks-ts";
import { css } from "aphrodite";
import sheet from "./style";
import { Pie } from "@ant-design/plots";


interface OcrInfo {
    ocrCount: number,
    weeklyCount: number,
    monthlyCount: number,
    threeMonthCount:number,
    yearlyCount:number

}


export const OcrCounter: React.FC= () =>{

    const translate = useTranslate();
    const API_URL = useApiUrl();

    const url = `${API_URL}/OcrCounter/getocr`;

    const { data: response, isLoading } = useCustom<{        
        data: OcrInfo
    }>({ url, method: "get", queryOptions: { refetchInterval: 60000, refetchIntervalInBackground: true } });

    let dailyCount = response?.data?.data.ocrCount ?? 0;
    let weeklyCount = response?.data?.data.weeklyCount ?? 0;
    let monthlyCount = response?.data?.data.monthlyCount ?? 0;
    let threeMonthCount = response?.data?.data.threeMonthCount ?? 0;
    let yearlyCount = response?.data?.data.yearlyCount ?? 0;
    

    var initialDarkMode: boolean = false;
    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)

 console.log(response);

 return (
    <Card  
        title={<div style={{ textAlign: 'center' }}>Ocr Kullanım Aracı</div>}
        style={{ width: '100%' }}
    >
        <Row gutter={[24, 24]} justify="space-around">
            <Col span={4}>
                <Statistic title={translate("dashboard.monitoring.ocrcounter.daily")} value={dailyCount} />
            </Col>
            <Col span={4}>
                <Statistic title={translate("dashboard.monitoring.ocrcounter.weekly")} value={weeklyCount} />
            </Col>
            <Col span={4}>
                <Statistic title={translate("dashboard.monitoring.ocrcounter.monthly")} value={monthlyCount} />
            </Col>
            <Col span={4}>
                <Statistic title={translate("dashboard.monitoring.ocrcounter.3months")} value={threeMonthCount} />
            </Col>
           
            <Col span={5}>
                <Statistic title={translate("dashboard.monitoring.ocrcounter.yearly")} value={yearlyCount} />
            </Col>
        </Row>
    </Card>
);
 }