import { useTranslate, IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, MarkdownField, TagField } from "@refinedev/antd";
import { Grid, Typography, Table, Row, Col } from "antd";

import { IEntityLogResponse } from "./../../../entities/configuration/IEntityLogResponse";
import { ConvertAuditStatusToText, ConvertAuditStatusToColor } from "helpers/statusHelper";
import { Breadcrumb } from "components/breadcrumb";


import ReactDiffViewer from 'react-diff-viewer-continued';


//const format = require("prettier-format");

const { Title, Text } = Typography;



export const EntityLogShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult: EntityLogQueryResult } = useShow<IEntityLogResponse>();
    const { data, isLoading } = EntityLogQueryResult;
    const record = data?.data;

    const translate = useTranslate();


    let _oldValue = record?.oldValue ?? "{}";
    let oldValue = JSON.stringify(JSON.parse(_oldValue == "" ? "{}" : _oldValue), null, 2);
    let newValue = JSON.stringify(JSON.parse(record?.newValue ?? "{}"), null, 2);

    return (
        <Show isLoading={isLoading} title={translate("pages.EntityLog.titlesingular")} breadcrumb={<Breadcrumb />}>
            <Row gutter={24}>
                <Col span={24}>
                    <Title level={5}>{translate("pages.EntityLog.fields.id")}</Title>
                    <Text>{record?.id}</Text>
                </Col>
                <Col span={24}>
                    <Title level={5}>{translate("pages.EntityLog.fields.entityName")}</Title>
                    <Text>{record?.entityName}</Text>
                </Col>
                <Col span={24}>
                    <Title level={5}>{translate("pages.EntityLog.fields.entityID")}</Title>
                    <Text>{record?.entityID}</Text>
                </Col>
                <Col span={24}>
                    <Title level={5}>{translate("pages.EntityLog.fields.date")}</Title>
                    <Text>{record?.date}</Text>
                </Col>
                <Col span={24}>
                    <Title level={5}>{translate("pages.EntityLog.fields.operation")}</Title>
                    <TagField value={ConvertAuditStatusToText(record?.operation ?? 5)} color={ConvertAuditStatusToColor(record?.operation ?? 5)} />
                </Col>
                <Col span={24}>
                    <Title level={5}>{translate("pages.EntityLog.fields.difference")}</Title>
                    <ReactDiffViewer oldValue={oldValue} newValue={newValue} splitView={true} />
                </Col>
            </Row>
        </Show>
    );
};