import { StyleSheet } from 'aphrodite'

const sheet = StyleSheet.create({
    numbercardWrapper: {
        height: '152px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '@media screen and (max-width: 576px)': {
            height: '112px'
        }
    },
    numbercardWrapper_titleArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    numbercardWrapper_titleArea__number: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1.1',
        marginTop: '0px !important'
    },
    numbercardWrapper_titleArea__number_span: {
        fontSize: '28px',
        marginRight: '5px',
        '@media screen and (max-width: 576px)': {
            fontSize: '30px !important',
            lineHeight: '0.9'
        }
    },
    numbercardWrapper_valueArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    numbercardWrapper_valueArea__number: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1.1',
        marginTop: '0px !important'
    },
    numbercardWrapper_valueArea__number_span: {
        fontSize: '28px',
        marginRight: '5px',
        '@media screen and (max-width: 576px)': {
            fontSize: '30px !important',
            lineHeight: '0.9'
        }
    },
});

export default sheet;