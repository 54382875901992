
import { ResourceRouterParams, useApiUrl, useGetIdentity, useNavigation, useNotification, useParsed, useRouterContext, useTranslate } from "@refinedev/core";
import "./style.less";
import { useFileUploadState, Edit, ListButton, RefreshButton, SaveButton, DeleteButton, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import { UploadFile, Space, Form, Row, Col, Select, Upload, Switch, Input } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { IMultiMediaSaveRequest } from "entities/content/IMultiMediaSaveRequest";
import { IDepartment } from "entities/definition/IDepartment";
import { IEmployee } from "entities/visa/IEmployee";
import { IUserForView } from "entities/visa/IUserForView";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { ConvertStatusID } from "helpers/statusHelper";
import { useConstantValueFromService } from "hooks";
import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useGetIdentityInterface } from "models/useGetIdentityInterface";


export const EmployeeEdit: React.FC = () => {
    const { open, close } = useNotification();
    const apiUrl = useApiUrl();
    const { data: identity } = useGetIdentity<useGetIdentityInterface>();
    const { isLoading, onChange } = useFileUploadState();
    const { useParams } = useRouterContext();

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();


    var actionData = "create";

    if (parsedId) {
        actionData = "edit";
    }

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<IEmployee>({
            redirect: false,
            action: actionData == "create" ? "create" : "edit",
            mutationMode: "undoable"
        });
    const translate = useTranslate();
    const Data = queryResult?.data?.data;
    var initialStatus = Data?.statusID ?? 1;

    const { list } = useNavigation();

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    if (mutationResult.isSuccess) {
        redirect("list");
    }

    const { selectProps: userSelectProps } = useSelect<IUserForView>({
        resource: "User",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 500,
        onSearch: (value) => [
            {
                operator: "or",
                value: [
                    {
                        field: "Firstname",
                        operator: "contains",
                        value: value,
                    },
                    {
                        field: "Lastname",
                        operator: "contains",
                        value: value,
                    },
                    {
                        field: "Email",
                        operator: "contains",
                        value: value,
                    },
                    {
                        field: "Username",
                        operator: "contains",
                        value: value,
                    }
                ]
            }
        ]
    });

    const { selectProps: employeeSelectProps } = useSelect<IEmployee>({
        resource: "Employee",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 500,
        onSearch: (value) => [
            {
                operator: "or",
                value: [
                    {
                        field: "User.Firstname",
                        operator: "contains",
                        value: value,
                    },
                    {
                        field: "User.Lastname",
                        operator: "contains",
                        value: value,
                    },
                    {
                        field: "User.Email",
                        operator: "contains",
                        value: value,
                    },
                    {
                        field: "User.Username",
                        operator: "contains",
                        value: value,
                    }
                ]
            }
        ]
    });

    const { selectProps: departmentSelectProps } = useSelect<IDepartment>({
        resource: "Department",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "name",
                operator: "contains",
                value
            }
        ]
    });

    const { selectProps: employeeTypeSelectProps } = useConstantValueFromService("getemployeetype")


    const [imageUrl, setImageUrl] = useState<string>(Data?.medias?.at(0)?.url ?? "");
    const [loading, setLoading] = useState(false);

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onFileUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.status == "done") {
            let newMedia: IMultiMediaSaveRequest = {
                mediaID: info.file.response.id,
                uid: info.file.uid
            };

            setImageUrl(info.file.response.url);
        }

        onChange(info);
    }

    const onBeforeUpload = (file: any) => {
        const isJpgOrPng = (file.type === 'image/png' || file.type === 'image/jpeg');

        if (!isJpgOrPng) {
            open?.({
                type: "error",
                message: "Sadece resim formatı yükleyebilirsiniz. Denenen format: " + file.type
            });
        }

        return isJpgOrPng;
    }


    return (
        <Edit isLoading={formLoading} canDelete={(CheckPermissionsV2("Delete", "Business.Concrete.Visa.EmployeeManager") && parsedId != undefined)}
            title={translate("buttons.edit")} recordItemId={parsedId} saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" ref={formRef}>
                <Row gutter={24}>
                    <Form.Item label={translate("pages.employee.fields.user")} name="id" initialValue={Data?.id ?? 0} hidden>
                        <Input />
                    </Form.Item>
                    <Col span={12} key="userID">
                        <Form.Item label={translate("pages.employee.fields.user")} name="userID" initialValue={Data?.userID}>
                            <Select {...userSelectProps} showSearch={true} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="superVisorID">
                        <Form.Item label={translate("pages.employee.fields.supervisor")} name="superVisorID" initialValue={Data?.superVisorID}>
                            <Select {...employeeSelectProps} showSearch={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12} key="employeeTypeID">
                        <Form.Item label={translate("pages.employee.fields.employeetype")} name="employeeTypeID" initialValue={Data?.employeeTypeID}>
                            <Select {...employeeTypeSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="departmentID">
                        <Form.Item label={translate("pages.employee.fields.department")} name="departmentID" initialValue={Data?.departmentID}>
                            <Select {...departmentSelectProps} showSearch={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} key="medias">
                        <Form.Item
                            name="medias"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                        >
                            <Upload.Dragger
                                name="file"
                                action={`${apiUrl}/Media/upload`}
                                listType="picture-card"
                                onChange={onFileUploadChange}
                                beforeUpload={onBeforeUpload}
                                accept="image/png, image/jpeg"
                                maxCount={1}
                                headers={
                                    {
                                        "Authorization": `bearer ${identity?.token}`
                                    }
                                }
                                showUploadList={{
                                    showDownloadIcon: true
                                }}
                            >
                                {uploadButton}
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={24} key="Status">
                        <Form.Item label={translate("pages.employee.fields.status")} name="statusID" initialValue={initialStatus}>
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
