import { useMemo } from "react";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Card, Spin, Typography } from "antd";
import GaugeChart from 'react-gauge-chart';

import PercentageCard from "components/card/percentagecard";

export const ApdexScore: React.FC = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();

    const url = `${API_URL}/monitoring/getapdexscore`;
    const { data:response, isLoading } = useCustom<{
        data: number
    }>({ url, method: "get" });

    const { Text, Title } = Typography;
    
    let percent = response?.data?.data ?? 0;

    return (
        <PercentageCard title={t("dashboard.monitoring.apdexscore")} percent={percent} isLoading={isLoading} onClick={undefined} colors={["#FF0000","#00FF00"]} chartLevel={20}/>
    );
};