


import { ICurrency } from "../../../entities/definition/ICurrency";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor, ConvertBoolean, ConvertBooleanToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { EditButton, TagField, useTable } from "@refinedev/antd";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";

export const CurrencyList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<ICurrency>();
    const translate = useTranslate();

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.currency.fields.id"),
            type: "number"
        },
        {
            key: "Name",
            title: translate("pages.currency.fields.name"),
            type: "string"
        },
        {
            key: "Code",
            title: translate("pages.currency.fields.code"),
            type: "string"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.currency.fields.id")
        },
        {
            dataIndex: "name",
            title: translate("pages.currency.fields.name")
        },
        {
            dataIndex: "code",
            title: translate("pages.currency.fields.code")
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },
         {
             dataIndex: "actions",
             title: translate("general.button.actions"),
             render: (props: RenderComponentProps) => (
                 <EditButton
                     size="small"
                     recordItemId={props.record.id}
                />

             )
         }
    ]

    return (
        <ListPage listProps={{
            title: translate("pages.currency.title"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Definition.CurrencyManager")
        }}
            tableProps={tableProps}
            columns={columns}
            filters={filters}
            rowKey="id"
            setFilters={setFilters}
            setSorter={setSorter}
            isLoading={queryResult.isFetching}
            standartOnRowRedirectToShow={false} />

    );
};