import { useTranslate, IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, MarkdownField } from "@refinedev/antd";
import { Grid, Typography, Table } from "antd";

import { IErrorLogResponse } from "./../../../entities/configuration/IErrorLogResponse";
import { IErrorLogParameterResponse } from "./../../../entities/configuration/IErrorLogParameterResponse";
import { useDarkMode } from "usehooks-ts";


const { Title, Text } = Typography;



export const ErrorLogShow: React.FC<IResourceComponentsProps> = () => {
    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }

    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)

    const { queryResult: errorLogQueryResult } = useShow<IErrorLogResponse>();
    const { data, isLoading } = errorLogQueryResult;
    const record = data?.data;
    const parameters = data?.data.errorLogParameters;

    const translate = useTranslate();

    return (
        <Show isLoading={isLoading} title={translate("pages.errorlog.titlesingular")}>
            <Title level={5}>{translate("pages.errorlog.fields.id")}</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>{translate("pages.errorlog.fields.methodName")}</Title>
            <Text>{record?.methodName}</Text>

            <Title level={5}>{translate("pages.errorlog.fields.logTypeName")}</Title>
            <Text>{record?.logType?.name}</Text>

            {record?.logTypeID != 2 && (
                <>
                    <Title level={5}>{translate("pages.errorlog.fields.stackTrace")}</Title>
                    <MarkdownField value={record?.stackTrace ?? translate("general.data.nodata")} />

                    <Title level={5}>{translate("pages.errorlog.fields.exceptionMessage")}</Title>
                    <MarkdownField value={record?.exceptionMessage ?? translate("general.data.nodata")} />
                </>
            )}



            <Title level={5}>{translate("pages.errorlog.fields.errorLogParameters.title")}</Title>
            <Table
                rowKey="id"
                dataSource={parameters}
                className={isDarkMode ? "table-striped-rows-dark" : "table-striped-rows"}
                bordered
                size="large"
            >
                <Table.Column<IErrorLogParameterResponse>
                    key="summary"
                    dataIndex="name"
                    title={translate("pages.errorlog.fields.errorLogParameters.name")}
                />
                <Table.Column<IErrorLogParameterResponse>
                    key="summary"
                    dataIndex="type"
                    title={translate("pages.errorlog.fields.errorLogParameters.type")}
                />
                <Table.Column<IErrorLogParameterResponse>
                    key="summary"
                    dataIndex="value"
                    title={translate("pages.errorlog.fields.errorLogParameters.value")}
                />

            </Table>
        </Show>
    );
};