import { IAnnouncement } from "../../../entities/definition/IAnnouncement";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor, ConvertBoolean, ConvertBooleanToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { IMedia } from "entities/content/IMedia";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { FileField, TagField, useTable } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Dropdown, Menu } from "antd";
import { DateField } from "components/fields/date";
import { MoreOutlined } from "@ant-design/icons";

export const AnnouncementList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IAnnouncement>({
        sorters: {
            initial: [
                {
                    field: "ID",
                    order: "desc"
                }
            ]
        }
    });
    const translate = useTranslate();

    const moreMenu = (record: IAnnouncement) => {
        return (
            <Menu
                mode="vertical"
                onClick={({ domEvent }) => domEvent.stopPropagation()}
            >
                {record?.medias?.map((item: IMedia) => {
                    return (<Menu.Item
                        key={item.uid}
                    >
                        <FileField src={item.url} title={item.name} />
                    </Menu.Item>)
                })}

            </Menu>
        )
    };

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.announcement.fields.id"),
            type: "number"
        },
        {
            key: "Title",
            title: translate("pages.announcement.fields.title"),
            type: "string"
        },
        {
            key: "Description",
            title: translate("pages.announcement.fields.description"),
            type: "string"
        },
        {
            key: "StartDate",
            title: translate("pages.announcement.fields.startDate"),
            type: "date"
        },
        {
            key: "EndDate",
            title: translate("pages.announcement.fields.endDate"),
            type: "date"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.announcement.fields.id")
        },
        {
            dataIndex: "title",
            title: translate("pages.announcement.fields.title")
        },
        {
            dataIndex: "description",
            title: translate("pages.announcement.fields.description")
        },
        {
            dataIndex: "startDate",
            title: translate("pages.announcement.fields.startDate"),
            render: (props: RenderComponentProps) => (
                <DateField value={props.value} format="LLL" />

            )
        },
        {
            dataIndex: "endDate",
            title: translate("pages.announcement.fields.endDate"),
            render: (props: RenderComponentProps) => (
                <DateField value={props.value} format="LLL" />

            )
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <Dropdown
                    overlay={moreMenu(props.record)}
                    trigger={["click"]}
                >
                    <MoreOutlined
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            fontSize: 24,
                        }}
                    />
                </Dropdown>

            )
        }
    ]

    return (
        <ListPage listProps={{
            title: translate("pages.announcement.title"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Definition.AnnouncementManager")
        }}
            tableProps={tableProps}
            columns={columns}
            filters={filters}
            rowKey="id"
            setFilters={setFilters}
            setSorter={setSorter}
            isLoading={queryResult.isFetching}
            standartOnRowRedirectToShow={false} />

    );
};