import React from "react";
import { Button, Card, Modal, Space, Spin } from "antd";
import { useTranslate } from "@refinedev/core";

import { RedoOutlined } from "@ant-design/icons";
import { PageHeader } from "@refinedev/antd";



export const CustomModal: React.FC<any> = ({
    title,
    actionButtons,
    isLoading = false,
    children,
    hideText = false,
    pageHeaderProps,
    onClickRefresh,
    isFetching,
    disableRefreshButton = false,
    visible,
    visibility,
    ...rest
}) => {
    const translate = useTranslate();

    const handleOk = () => { visibility(false) }
    const handleCancel = () => { visibility(false) }

    return (
        <Modal open={visible} onOk={handleOk} onCancel={handleCancel}>
            <PageHeader
                ghost={false}
                onBack={undefined}
                title={title}
                extra={disableRefreshButton ? undefined :
                    <Space key="extra-buttons" wrap style={{
                        padding: "2vh",
                    }}>
                        <Button
                            onClick={(e) => (onClickRefresh ? onClickRefresh(e) : undefined)}
                            icon={<RedoOutlined spin={isFetching} />}
                            {...rest}
                        >
                            {!hideText && (children ?? translate("buttons.refresh", "Refresh"))}
                        </Button>
                    </Space>
                }
                {...pageHeaderProps}
            >
                <Spin spinning={isLoading}>
                    <Card
                        bordered={false}
                        actions={actionButtons ? [actionButtons] : undefined}
                    >
                        {children}
                    </Card>
                </Spin>
            </PageHeader>
        </Modal>
    );
};
