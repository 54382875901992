import { useTranslate, IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, DateField, TagField } from "@refinedev/antd";
import { Typography, Row, Col, Card } from "antd";
import IMailTask from "entities/configuration/IMailTask";
import { ConvertBoolean, ConvertBooleanToColor } from "helpers/statusHelper";
import sanitizeHtml from 'sanitize-html';

const parse = require('html-react-parser');

const { Title, Text } = Typography;

export const MailTaskShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult: errorLogQueryResult } = useShow<IMailTask>();
    const { data, isLoading } = errorLogQueryResult;
    const record = data?.data;

    const translate = useTranslate();

    return (
        <Show isLoading={isLoading} title={translate("pages.MailTask.titlesingular")}>
            <Row gutter={24}>
                <Col span={8}>
                    <Title level={5}>{translate("pages.MailTask.fields.subject")}</Title>
                    <Text>{record?.subject}</Text>
                </Col>
                <Col span={8}>
                    <Title level={5}>{translate("pages.MailTask.fields.sendDate")}</Title>
                    <DateField value={record?.sendDate} format="LLL" />
                </Col>
                <Col span={8}>
                    <Title level={5}>{translate("pages.MailTask.fields.nextSendDate")}</Title>
                    <DateField value={record?.nextSendDate} format="LLL" />
                </Col>
            </Row>
            <br />
            <Row gutter={24}>
                <Col span={8}>
                    <Title level={5}>{translate("pages.MailTask.fields.isSended")}</Title>
                    <TagField value={ConvertBoolean(record?.isSended ?? false)} color={ConvertBooleanToColor(record?.isSended ?? false)} />
                </Col>
                <Col span={8}>
                    <Title level={5}>{translate("pages.MailTask.fields.tryCount")}</Title>
                    <Text>{record?.tryCount}</Text>
                </Col>
                <Col span={8}>
                    <Title level={5}>{translate("pages.MailTask.fields.result")}</Title>
                    <Text>{record?.result}</Text>
                </Col>
            </Row>
            <br />
            <Card title={translate("pages.MailTask.fields.body")} >
                {parse(sanitizeHtml(record?.body ?? ""))}
            </Card>
        </Show>
    );
};