import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';

import "./style.css";
import { InputNumber, Typography } from 'antd';

const handleStyle = { left: 10 };

export const ApprovalNode = ({ data, isConnectable }: { data: any, isConnectable: boolean }) => {
    const onChange = useCallback((value: any) => {
        console.log(value);
    }, []);


    return (
        <div className="text-updater-node">
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div>
                <Typography.Text>Value: </Typography.Text>
                <InputNumber onChange={onChange} />
            </div>
        </div>
    );
};