import {
    useForm,
    Edit,
    useSelect,
    SaveButton,
    DeleteButton,
    ListButton,
    RefreshButton,
} from "@refinedev/antd";

import { Form, Input, Select, Space, Row, Col, Switch, InputNumber } from "antd";

import {
    ResourceRouterParams,
    useNavigation,
    useParsed,
    useResourceWithRoute,
    useRouterContext,
    useTranslate,
} from "@refinedev/core";

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IUserForUpdate } from "../../../entities/visa/IUserForUpdate";
import { useState } from "react";
import { ConvertBoolean, ConvertStatusID } from "helpers/statusHelper";
import React from "react";
import { IRoleResponse } from "entities/visa/IRoleResponse";
import { css } from 'aphrodite'
import sheet from './style';
import { IMailAccount } from "entities/configuration/IMailAccount";

declare type ActionParams = {
    action?: "edit" | "create" | "clone";
};

export const MailAccountEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<IMailAccount>({ redirect: false, action: parsedAction == "edit" ? "edit" : "create", mutationMode: "undoable" });
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 1;
    var initialIsDefault = userData?.isDefault ?? false;
    var initialRequireSSL = userData?.requireSSL ?? true;

    const { goBack, list } = useNavigation();

    if (mutationResult.isSuccess) {
        redirect("list");
    }

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    return (
        <Edit isLoading={formLoading}
            headerButtons={({ defaultButtons }) => (
                <>
                    <ListButton
                        data-testid="edit-list-button"
                        resource={parsedResource?.name}
                        children={translate("pages.user.title")}
                    />
                    {parsedAction == "edit" && (
                        <RefreshButton
                            resource={parsedResource?.name}
                            recordItemId={parsedId}
                        />
                    )}
                </>
            )}
            footerButtons={({ defaultButtons }) => (
                <>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                        }}
                    />
                    {CheckPermissionsV2("Delete", "Business.Concrete.Configuration.MailAccountManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </>
            )}
            title={translate("buttons.edit")} recordItemId={parsedId}>
            <Form {...formProps} layout="vertical" ref={formRef}>
                <Row gutter={24}>
                    <Col span={12} key="name">
                        <Form.Item label={translate("pages.mailaccount.fields.name")} name="name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="smtpServerAdress">
                        <Form.Item label={translate("pages.mailaccount.fields.smtpServerAdress")} name="smtpServerAdress" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="smtpUsername">
                        <Form.Item label={translate("pages.mailaccount.fields.smtpUsername")} name="smtpUsername" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="smtpPassword">
                        <Form.Item label={translate("pages.mailaccount.fields.smtpPassword")} name="smtpPassword" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="senderEmail">
                        <Form.Item label={translate("pages.mailaccount.fields.senderEmail")} name="senderEmail" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="senderDisplayName">
                        <Form.Item label={translate("pages.mailaccount.fields.senderDisplayName")} name="senderDisplayName" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="requireSSL">
                        <Form.Item label={translate("pages.mailaccount.fields.requireSSL")} name="requireSSL" initialValue={initialRequireSSL}>
                            <Switch checkedChildren={ConvertBoolean(true)} unCheckedChildren={ConvertBoolean(false)} defaultChecked={initialRequireSSL}/>
                        </Form.Item>    
                    </Col>
                    <Col span={12} key="isDefault">
                        <Form.Item label={translate("pages.mailaccount.fields.isDefault")} name="isDefault" initialValue={initialIsDefault}>
                            <Switch checkedChildren={ConvertBoolean(true)} unCheckedChildren={ConvertBoolean(false)} defaultChecked={initialIsDefault}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} key="port">
                        <Form.Item label={translate("pages.mailaccount.fields.port")} name="port" rules={[{ required: true }]}>
                            <InputNumber />
                        </Form.Item>
                    </Col>

                    <Col span={12} key="Status">
                        <Form.Item label="Status" name="statusID" initialValue={initialStatus}>
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
