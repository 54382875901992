import {
    useForm,
    Edit,
    useSelect,
    SaveButton,
    DeleteButton,
    useCheckboxGroup,
    ListButton,
    RefreshButton,
} from "@refinedev/antd";

import { Form, Input, Select, Space, Row, Col, Switch, Checkbox } from "antd";

import {
    ResourceRouterParams,
    useNavigation,
    useParsed,
    useResourceWithRoute,
    useRouterContext,
    useTranslate,
} from "@refinedev/core";

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IForbiddenUsernameRequest } from "../../../entities/visa/IForbiddenUsernameRequest";
import { useState } from "react";
import { ConvertStatusID,ConvertBoolean } from "helpers/statusHelper";
import React from "react";
import { IPermissionResponse } from "entities/visa/IPermissionResponse";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { IForbiddenUsernameResponse } from "entities/visa/IForbiddenUsernameResponse";




export const ForbiddenUsernameEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();
    
    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect,formLoading } = useForm<IForbiddenUsernameRequest>({ redirect: false,action: parsedAction == "create" ? "create":"edit",mutationMode:"undoable" });
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 0;
    const { goBack, list } = useNavigation();

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked:boolean) => {
        formRef.current.setFieldsValue({
            statusID:checked ? 1 : 0
        });
    }

    return (
        <Edit isLoading={formLoading}
        headerProps={{
            extra:(<Space wrap>
                <ListButton
                        data-testid="edit-list-button"
                        resource={parsedResource?.name}
                    />
                {parsedAction == "edit" && (
                    <RefreshButton
                    resource={parsedResource?.name}
                    recordItemId={parsedId}
                />
                )}
                
            </Space>)
        }} 
            headerButtons={
                <Space>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                            redirect("list");
                        }}
                    />
                    { CheckPermissionsV2("Delete","Business.Concrete.Visa.ForbiddenUsernameManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </Space>
            }
         title={translate("buttons.edit")} recordItemId={parsedId}>
            <Form {...formProps} layout="vertical" ref={formRef}>
                <Row gutter={24}>
                    <Col span={12} key="Name">
                        <Form.Item label={translate("forbiddenUsername.fields.username")} name="username" rules={[{ required: true}]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Status">
                        <Form.Item label={translate("forbiddenUsername.fields.status")} name="statusID">
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange}/>                            
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
