import { Checkbox, Col, FormInstance, Input, Row, Skeleton, Typography } from "antd";
import { useTranslate } from "@refinedev/core";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IPermissionGrouped } from "entities/visa/IPermissionGrouped";
import { IPermissionResponse } from "entities/visa/IPermissionResponse";
import { usePermissionGroup } from "hooks";
import { Tooltip } from 'antd';

export type PermissionGroupProps = {
    form: FormInstance<{}>
}

export const PermissionGroup = ({ form }: PermissionGroupProps) => {
    const { Title } = Typography;
    const { permissions, onSearch, loading } = usePermissionGroup();
    const translate = useTranslate();
    var permissionIds = form.getFieldValue("permissionIds") ?? [];

    const isDefaultChecked = (id: number) => {
        return (permissionIds?.filter(function (value: number, index: number, arr: number[]) {
            return value == id;
        })?.length ?? 0) > 0;
    }

    const onCheckBoxChange = (event: CheckboxChangeEvent, form: FormInstance<{}>) => {

        var checked = event.target.checked;
        var id = event.target.id;

        if (checked && id) {
            permissionIds = permissionIds?.filter(function (value: number, index: number, arr: number[]) {
                return value != parseInt(id!);
            })

            permissionIds.push(parseInt(id));
        }
        else if (id) {
            const index = permissionIds?.indexOf(parseInt(id));

            if (index && index > -1) {
                permissionIds.splice(index, 1);
            }
        }

        form.setFieldsValue({
            permissionIds: permissionIds
        });
    }

    return (
        <Row gutter={24}>
            <Col span={8}>
                <Input.Search placeholder={translate("role.fields.searchbar")} onSearch={onSearch} loading={loading} allowClear onChange={(event: any) => {
                    var value = event.target.value;
                    onSearch(value);
                }} />
            </Col>
            <br /><br />



            <Col span={24}>
                <Skeleton loading={loading} active>
                    {
                        permissions.map((value: IPermissionGrouped) => {
                            var key = value.manager;
                            var _permissions = value.permissions;

                            return (
                                <>
                                    <Col span={24}>
                                        <Title level={5}>{translate("PermissionDefinition.Manager." + key)}</Title>
                                        <Row gutter={24}>
                                            {_permissions.map((permission: IPermissionResponse) => {
                                                var method = permission.method;

                                                if (method.includes('MENU')) method = method.split('_')[1];

                                                return (
                                                    <Col xs={{ span: 24 }} lg={{ span: 4 }} key={permission.id}>
                                                        <Tooltip title={translate("PermissionDefinition.Tooltip." + key + "." + permission.method)}>
                                                            <Checkbox onChange={(event: CheckboxChangeEvent) => {
                                                                onCheckBoxChange(event, form);
                                                            }} id={"" + permission.id} defaultChecked={isDefaultChecked(permission.id)}>
                                                                {translate("PermissionDefinition.Method." + method)}
                                                            </Checkbox >
                                                        </Tooltip>

                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                        <hr />
                                    </Col>
                                </>
                            )
                        })
                    }
                </Skeleton>

            </Col>
        </Row>
    )
}