import { useState } from "react";
import {
    ITreeMenu,
    CanAccess,
    useIsExistAuthentication,
    useTranslate,
    useLogout,
    useMenu,
    useWarnAboutChange,
    useTitle,
    useRouterType,
    useLink,
    useRouterContext,
    useRefineContext,
    useActiveAuthProvider,
    pickNotDeprecated,
} from "@refinedev/core";
import { Link } from "react-router-dom";
import { ThemedSiderV2, ThemedTitleV2 } from "@refinedev/antd";
import { Layout as AntdLayout, Menu, Grid, theme, Button, MenuProps, Drawer, Layout, ConfigProvider } from "antd";
import {
    LogoutOutlined,
    UnorderedListOutlined,
    RightOutlined,
    LeftOutlined,
    DashboardOutlined,
    BarsOutlined
} from "@ant-design/icons";


import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import { ApplicationTitle } from "../title";

import { CheckMenu } from "helpers/permissionHelper";
import IMenuPermission from "components/model/IMenuPermission";
import { Title as DefaultTitle } from "@refinedev/antd";

import { drawerButtonStyles } from "./styles";
import { useDarkMode } from "usehooks-ts";

const { useToken } = theme;

export const CustomSider: typeof ThemedSiderV2 = ({ Title: TitleFromProps, render, meta }) => {

    const { token } = useToken();
    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }

    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)

    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const isExistAuthentication = useIsExistAuthentication();
    const routerType = useRouterType();
    const NewLink = useLink();
    const { warnWhen, setWarnWhen } = useWarnAboutChange();
    const { Link: LegacyLink } = useRouterContext();
    const Link = routerType === "legacy" ? LegacyLink : NewLink;
    const TitleFromContext = useTitle();
    const translate = useTranslate();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu({ meta });
    const breakpoint = Grid.useBreakpoint();
    const { hasDashboard } = useRefineContext();
    const authProvider = useActiveAuthProvider();
    const { mutate: mutateLogout } = useLogout({
        v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
    });

    const isMobile =
        typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

    const RenderToTitle = TitleFromProps ?? TitleFromContext ?? DefaultTitle;

    const { SubMenu } = Menu;

    const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
        return tree.map((item: ITreeMenu) => {
            const {
                key,
                name,
                children,
                parentName,
                meta,
                options,
                list
            } = item;

            const icon = meta?.icon;
            const label = meta?.label ?? name;
            const parent = meta?.parent;
            const route =
                typeof list === "string"
                    ? list
                    : typeof list !== "function"
                        ? (list?.path ?? key)
                        : key;

            const menuPermList: IMenuPermission[] = meta?.permissions ?? [];

            if (children.length > 0) {

                return CheckMenu(menuPermList, true, children) ? (
                    <CanAccess
                        key={key}
                        resource={name.toLowerCase()}
                        action="list"
                        params={{ resource: item }}
                    >
                        <SubMenu
                            key={key}
                            icon={icon ?? <UnorderedListOutlined />}
                            title={label}
                        >
                            {renderTreeView(children, selectedKey)}
                        </SubMenu>
                    </CanAccess>

                ) : (<></>);
            }
            const isSelected = route === selectedKey;

            const isRoute = !(
                pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
                undefined && children.length === 0
            );

            return CheckMenu(menuPermList) ? (
                <CanAccess
                    key={key}
                    resource={name.toLowerCase()}
                    action="list"
                    params={{ resource: item }}
                >
                    <Menu.Item
                        key={key}
                        style={{
                            fontWeight: isSelected ? "bold" : "normal",
                            textTransform: "capitalize",
                        }}
                        icon={icon ?? (isRoute && <UnorderedListOutlined />)}
                    >
                        <Link to={route ?? ""}>{label}</Link>
                        {!collapsed && isSelected && (
                            <div className="ant-menu-tree-arrow" />
                        )}
                    </Menu.Item>
                </CanAccess>
            ) : (<></>);
        });
    };

    const handleLogout = () => {
        if (warnWhen) {
            const confirm = window.confirm(
                translate(
                    "warnWhenUnsavedChanges",
                    "Are you sure you want to leave? You have unsaved changes.",
                ),
            );

            if (confirm) {
                setWarnWhen(false);
                mutateLogout();
            }
        } else {
            mutateLogout();
        }
    };

    const logout = isExistAuthentication && (
        <Menu.Item
            key="logout"
            onClick={handleLogout}
            icon={<LogoutOutlined />}
        >
            {translate("buttons.logout", "Logout")}
        </Menu.Item>
    );

    const dashboard = hasDashboard ? (
        <Menu.Item
            key="dashboard"
            style={{
                fontWeight: selectedKey === "/" ? "bold" : "normal",
            }}
            icon={<DashboardOutlined />}
        >
            <Link to="/">{translate("dashboard.title", "Dashboard")}</Link>
            {!collapsed && selectedKey === "/" && (
                <div className="ant-menu-tree-arrow" />
            )}
        </Menu.Item>
    ) : null;

    const items = renderTreeView(menuItems, selectedKey);

    const renderSider = () => {
        if (render) {
            return render({
                dashboard: null,
                items,
                logout,
                collapsed,
            });
        }
        return (
            <>
                {dashboard}
                {items}
                {logout}
            </>
        );
    };

    const [openKeys, setOpenKeys] = useState(defaultOpenKeys);


    const rootSubmenuKeys: string[] = menuItems.map((item: ITreeMenu) => {
        if (item.meta?.parent) {
            return "";
        }
        const {
            key,
            list
        } = item;

        const route =
            typeof list === "string"
                ? list
                : typeof list !== "function"
                    ? (list?.path ?? key)
                    : key;

        return item.key ?? route ?? "";
    });

    const onOpenChange: MenuProps['onOpenChange'] = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const renderMenu = () => (
        <Menu
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            theme={isDarkMode ? "dark" : "light"}
            selectedKeys={selectedKey ? [selectedKey] : []}
            defaultOpenKeys={defaultOpenKeys}
            mode="inline"
            onClick={() => {
                setDrawerOpen(false);
                if (!breakpoint.lg) {
                    setCollapsed(true);
                }
            }}
        >
            {renderSider()}
        </Menu>

    );

    const renderDrawerSider = () => (
        <>
            <Drawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                placement="left"
                closable={false}
                width={200}
                bodyStyle={{
                    padding: 0,
                }}
                maskClosable={true}
            >
                <Layout>
                    <Layout.Sider
                        style={{ height: "100vh", overflow: "hidden" }}
                        theme={isDarkMode ? "dark" : "light"}
                    >
                        <RenderToTitle collapsed={false} />
                        {renderMenu()}
                    </Layout.Sider>
                </Layout>
            </Drawer>
            <Button
                style={drawerButtonStyles}
                size="large"
                onClick={() => setDrawerOpen(true)}
                icon={<BarsOutlined />}
            ></Button>
        </>
    );

    const renderContent = () => {
        if (isMobile) {
            return renderDrawerSider();
        }

        return (
            <Layout.Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(collapsed: boolean): void =>
                    setCollapsed(collapsed)
                }
                collapsedWidth={80}
                breakpoint="lg"
                theme={isDarkMode ? "dark" : "light"}
            >
                <RenderToTitle collapsed={collapsed} />
                {renderMenu()}
            </Layout.Sider>
        );
    }

    return (
        <>
            {renderContent()}
        </>
    );
};


