import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { ICost } from "../../../entities/expense/ICost";
import { ICostItem } from "../../../entities/expense/ICostItem";
import { ICostActionHistory } from "../../../entities/expense/ICostActionHistory";
import {
  ConvertStatusID,
  ConvertCostStatusToColor,
} from "helpers/statusHelper";
import React, { useState, useEffect, useRef } from "react";
import "./style.less";
import { IDepartment } from "entities/definition/IDepartment";
import { ICurrency } from "entities/definition/ICurrency";
import { IEmployee } from "entities/visa/IEmployee";
import { useConstantValueFromService } from "hooks";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { IMultiMediaSaveRequest } from "entities/expense/IMultiMediaSaveRequestd";
import { SendOutlined } from "@ant-design/icons";
import { axiosInstance } from "providers/authProvider";
import { IServiceObjectResult } from "interfaces/Results/ServiceObjectResult";
import { IMainCostCategory } from "entities/definition/IMainCostCategory";
import { ISubCostCategory } from "entities/definition/ISubCostCategory";
import { useLocation } from "react-router-dom";
import { IMedia } from "entities/content/IMedia";
import { ICostType } from "entities/definition/ICostType";
import { SiCircuitverse } from "react-icons/si";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  Typography,
  Upload,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import {
  useApiUrl,
  useCustom,
  useGetIdentity,
  useNavigation,
  useNotification,
  useParsed,
  useTranslate,
} from "@refinedev/core";
import {
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  FileField,
  List,
  ListButton,
  NumberField,
  RefreshButton,
  SaveButton,
  TagField,
  getValueFromEvent,
  useFileUploadState,
  useForm,
  useModalForm,
  useSelect,
  useTable,
} from "@refinedev/antd";
import {
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
  FormOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useDarkMode, useEffectOnce, useReadLocalStorage } from "usehooks-ts";
import { ApplicationSettings } from "entities/configuration/IApplicationSettings";
import { useGetIdentityInterface } from "models/useGetIdentityInterface";
import { File } from "buffer";

const { TabPane } = Tabs;

const sendApproval = (costId: string) => {
  return axiosInstance.post("CostApprovement/sendApproval?costId=" + costId);
};

export const CostEdit: React.FC = () => {
  const applicationSettings = useReadLocalStorage<ApplicationSettings>(
    "ApplicationSettings"
  );
  const { Text, Title } = Typography;
  const { state }: { state: any } = useLocation();
  const apiUrl = useApiUrl();
  const { isLoading, onChange } = useFileUploadState();
  const { data: identity } = useGetIdentity<useGetIdentityInterface>();
  const {
    resource: parsedResource,
    action: parsedAction,
    id: parsedId,
    pathname: parsedPathname,
    params: parsedParams,
  } = useParsed();

  const from = state?.from || parsedParams?.from || "";

  const [activeKey, setActiveKey] = useState(from == "create" ? "2" : "1");

  var actionData = "create";

  if (parsedId) {
    actionData = "edit";
  }


  const {
    formProps,
    saveButtonProps,
    queryResult,
    onFinish,
    redirect,
    formLoading,
    mutationResult,
    form: costForm,
  } = useForm<ICost>({
    redirect: false,
    action: actionData == "create" ? "create" : "edit",
    mutationMode: "optimistic",
    resource: "Cost",
    id: parsedId
  });



  const translate = useTranslate();
  const costData = queryResult?.data?.data;
  var initialStatus = costData?.statusID ?? 0;
  var costStatus = costData?.costStatusTypeID;
  const { list } = useNavigation();
  const { open, close } = useNotification();

  let formRef = React.useRef<any>(null);

  const onSelectedChange = (checked: boolean) => {
    formRef.current.setFieldsValue({
      statusID: checked ? 1 : 0,
    });
  };

  const onFileUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status == "done") {
      let newMedia: IMultiMediaSaveRequest = {
        mediaID: info.file.response.id,
        uid: info.file.uid,
      };
    }

    onChange(info);
  };



  const { tableProps, tableQueryResult: costItemTableQueryResult } = useTable<ICostItem>({
    resource: "CostItem",
    syncWithLocation: false,
    permanentFilter: [
      {
        field: "CostID",
        operator: "eq",
        value: parsedId ?? 0,
      },
    ],
    queryOptions: {
      onSuccess(data) {
        let totalPrice = data.data.reduce((partialSum, item) => partialSum + item.totalPrice, 0);
        setShowWarningForApproval((totalPrice ?? 0) > (applicationSettings?.minimumAmountRequiredForApproval ?? 0))
      },
    }
  });

  const [showWarningForApproval, setShowWarningForApproval] = useState(false);


  const { tableProps: costActionHistoryTableProps } =
    useTable<ICostActionHistory>({
      resource: "CostActionHistory",
      syncWithLocation: false,
      permanentFilter: [
        {
          field: "CostID",
          operator: "eq",
          value: parsedId ?? 0,
        },
      ],
      permanentSorter: [
        {
          field: "dateCreated",
          order: "desc",
        },
      ],
    });

  // Create Modal
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
    onFinish: createModelOnFinish,
    form: createModelForm,
    close: createModalClose,
  } = useModalForm<ICostItem>({
    action: "create",
    resource: "CostItem",
    redirect: false,
    warnWhenUnsavedChanges: false,
    mutationMode: "optimistic"
  });

  // Edit Modal
  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
    onFinish: editModelOnFinish,
    form: editModelForm,
    queryResult: editModalQueryResult,
    formLoading: editModalFormLoading,
    close: editModalClose,
  } = useModalForm<ICostItem>({
    action: "edit",
    resource: "CostItem",
    warnWhenUnsavedChanges: false,
    redirect: false,
    mutationMode: "optimistic"
  });

  /*
        const [taxCreate, setTaxCreate] = useState(0);
        const [taxPercentCreate, setTaxPercentCreate] = useState(18);
        const [totalPriceCreate, setTotalPriceCreate] = useState(0);
        const [taxEdit, setTaxEdit] = useState(0);
        const [taxPercentEdit, setTaxPercentEdit] = useState(0);
        const [totalPriceEdit, setTotalPriceEdit] = useState(0);
    
    
        useEffect(() => {
            if (!editModalFormLoading) {
                let initialTaxValue = editModalQueryResult?.data?.data.tax ?? 0;
                let initialTotalPriceValue = editModalQueryResult?.data?.data.totalPrice ?? 0;
                let initialTaxPercentValue = editModalQueryResult?.data?.data.taxPercent ?? 0;
    
                setTaxEdit(initialTaxValue);
                setTaxPercentEdit(initialTaxPercentValue);
                setTotalPriceEdit(initialTotalPriceValue);
            }
        }, [editModalFormLoading]);
    */

  let formRefCreateProperty = React.useRef<any>(null);

  const onSelectedChangeCreateEnterProperty = (checked: boolean) => {
    formRefCreateProperty.current.setFieldsValue({
      statusID: checked ? 1 : 0,
      costID: costData?.id ?? 0,
    });
  };

  let formRefEditProperty = React.useRef<any>(null);

  const onSelectedChangeEditEnterProperty = (checked: boolean) => {
    formRefEditProperty.current.setFieldsValue({
      statusID: checked ? 1 : 0,
      costID: costData?.id ?? 0,
    });
  };

  const editModelSubmit = () => {
    editModelOnFinish?.();
    editModelForm.resetFields();
    editModalClose();
  };

  const createModelSubmit = () => {
    createModelOnFinish?.().then(() => {
      createModelForm.resetFields();
      createModalClose();
    });
  };

  const [ocrModalVisible, setOcrModalVisible] = useState(false);
  const [ocrData, setOcrData] = useState({}); 
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);

  const handleOcr = async () => {
    setOcrModalVisible(true);
    setLoading(true);
    try {
        if (!selectedFile) {
            console.error('Dosya bulunamadı');
            return;
        }
        const formData = new FormData();
        formData.append("image", selectedFile);

        const response = await fetch(`${apiUrl}/Cost/exportDataFromImage`, {
            method: 'POST',
            headers: { 
                'Authorization': `Bearer ${identity?.token}`
            },
            body: formData
        });

        if (!response.ok) {
            throw new Error('HTTP error, status = ' + response.status);
        }

        const responseData = await response.json();
        setOcrData(responseData.data);
        fillFormFields(responseData.data);
        setLoading(false);
    } catch (error) {
        console.error('OCR API Hatası:', error);
        setLoading(false);
    } 
};

const fillFormFields = (data: { total?: any; totalTax?: any; transactionDate?: any; vendorName?: any; invoiceId?:any; 
  description?:any;
}) => {
 
  const { total, totalTax, transactionDate, vendorName,invoiceId,description} = data;
console.log(data);
  formRefCreateProperty.current.setFieldsValue({
      totalPrice: total,
      tax: totalTax,
      date: dayjs(transactionDate), 
      firmName: vendorName,
      documentNo:invoiceId,
      categoryDescription:description,
  });
};

const handleFileChange = (info: UploadChangeParam<any>) => {
  setSelectedFile(info.file.originFileObj);
  console.log(info.file.originFileObj)
  setShowConfirmation(true);

  onChange(info);
};

const handleConfirmationYes = () => {
  setShowConfirmation(false);
  if (selectedFile) {
    handleOcr();
  } else {
    console.error('Dosya seçilmedi. İşlem iptal edildi.');
  }
};

  const [currentMainCategoryId, setCurrentMainCategoryId] = useState(0);

  const onMainCategorySelectChange = (value: any) => {
    setCurrentMainCategoryId(value);
  };

  const { selectProps: mainCategorySelectProps } = useSelect<IMainCostCategory>(
    {
      resource: "MainCostCategory",
      optionLabel: "name",
      optionValue: "id",
      fetchSize: 1000,
      debounce: 5000,
    }
  );

  const {
    selectProps: subCategorySelectProps,
    defaultValueQueryResult: subCategoryDefaultValueQueryResult,
  } = useSelect<ISubCostCategory>({
    resource: "SubCostCategory",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 1000,
    debounce: 5000,
    queryOptions: {
      enabled: currentMainCategoryId ? currentMainCategoryId > 0 : false,
    },
    filters: [
      {
        field: "MainCostCategoryID",
        operator: "eq",
        value: currentMainCategoryId,
      },
    ],
  });

  const { selectProps: costTypeSelectProps } = useSelect<ICostType>({
    resource: "CostType",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 1000,
    debounce: 1000,
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value,
      },
    ],
  });

  const { selectProps: departmentSelectProps } = useSelect<IDepartment>({
    resource: "Department",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 1000,
    debounce: 1000,
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value,
      },
    ],
  });

  const [approvalLoading, setApprovalLoading] = useState(false);

  const sendCostToApprove = () => {
    if (parsedId) {
      sendApproval(parsedId.toString())
        .then((response) => {
          var serviceResponse: IServiceObjectResult = response.data;

          if (serviceResponse.success) {
            open?.({
              message: translate("pages.cost.sendedApproval"),
              type: "success",
              key: "sendedApproval",
            });
            redirect("list");
          } else {
            var message = serviceResponse.messages.at(0)?.description;
            open?.({
              message: message ?? translate("pages.cost.errorSendedApproval"),
              type: "error",
              key: "sendedApproval",
            });
          }
        })
        .catch((err) => {
          var message = err.response?.data?.messages.at(0)?.description;
          open?.({
            message: message ?? (err.message as string),
            type: "error",
            key: "sendedApproval",
          });
        })
        .finally(() => {
          setApprovalLoading(false);
        });
    } else {
      setApprovalLoading(false);
    }
  };

  const moreMenu = (record: ICostItem) => {
    return (
      <Menu
        mode="vertical"
        onClick={({ domEvent }) => domEvent.stopPropagation()}
      >
        {record?.medias?.map((item: IMedia) => {
          return (
            <Menu.Item key={item.uid}>
              <FileField src={item.url} title={item.name} />
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const { selectProps: currencySelectProps } = useSelect<ICurrency>({
    resource: "Currency",
    optionLabel: "label",
    optionValue: "id",
    fetchSize: 1000,
    debounce: 5000,
  });
  const { push } = useNavigation();

  var initialDarkMode: boolean = false;
  var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

  if (localStorageTheme === "true") {
    initialDarkMode = true;
  }

  return (
    <>
      <Edit
        isLoading={formLoading}
        headerButtons={({ defaultButtons }) => (
          <>
            {costData?.costStatusTypeID == 4 &&
              (
                <Button title="Doğrulama" onClick={() => {
                  window.open(apiUrl + "/PDF/createpdf/" + costData.verificationKey, "_blank", "noreferrer");
                }}>
                  {translate("pages.cost.showpdf")}
                </Button>
              )}
            <ListButton
              data-testid="edit-list-button"
              resource={parsedResource?.name}
              children={translate("menu.expense.cost")}
            />
            {parsedAction == "edit" && (
              <RefreshButton
                resource={parsedResource?.name}
                recordItemId={parsedId}
              />
            )}
          </>
        )}
        footerButtons={({ defaultButtons }) => (
          <Space>
            {(costStatus == 0 || costStatus == 2 || actionData == "create") && (
              <SaveButton
                {...saveButtonProps}
                disabled={isLoading}
                onClick={async () => {
                  await onFinish?.();
                }}
              />
            )}

            {CheckPermissionsV2(
              "Delete",
              "Business.Concrete.Expense.CostManager"
            ) &&
              costStatus == 0 &&
              parsedId && (
                <DeleteButton
                  disabled={isLoading}
                  data-testid="edit-delete-button"
                  mutationMode="undoable"
                  onSuccess={() => {
                    list(parsedResource?.name ?? "");
                  }}
                />
              )}

            {(costStatus == 0 || costStatus == 2) && showWarningForApproval && (
              <Popconfirm
                title={translate("pages.cost.fields.sendApprove")}
                description={translate("pages.cost.fields.sendApproveDesc")}
                placement="topLeft"
                onConfirm={() => {
                  setApprovalLoading(true);
                  sendCostToApprove();
                }}
              >
                <Button disabled={isLoading || approvalLoading} loading={approvalLoading} icon={<SendOutlined />} type="dashed">
                  {translate("buttons.sendApproval")}
                </Button>
              </Popconfirm>
            )}

            {(costStatus == 0 || costStatus == 2) && !showWarningForApproval && (
              <Button disabled={isLoading || approvalLoading} loading={approvalLoading} icon={<SendOutlined />} type="dashed" onClick={() => {
                setApprovalLoading(true);
                sendCostToApprove();
              }}>
                {translate("buttons.sendApproval")}
              </Button>
            )}
          </Space>
        )}
        title={translate("buttons.edit")}
        recordItemId={parsedId}
      >
        <Tabs
          activeKey={activeKey}
          onChange={(value: any) => {
            setActiveKey(value);
          }}
        >
          <TabPane tab={translate("menu.expense.cost")} key="1">
            <Form
              {...formProps}
              layout="vertical"
              ref={formRef}
              disabled={!(costStatus == 0 || costStatus == 2)}
            >
              <Row gutter={24}>
                <Col span={12} key="description">
                  <Form.Item
                    label={translate("pages.cost.fields.description")}
                    name="description"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={translate("pages.cost.fields.date")}
                    name="date"
                    getValueProps={(value) => ({
                      value: value ? dayjs(value) : dayjs(),
                    })}
                    initialValue={dayjs()}
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12} key="departmentID">
                  <Form.Item
                    label={translate("pages.cost.fields.department")}
                    name="departmentID"
                  >
                    <Select {...departmentSelectProps} showSearch={true} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={translate("pages.costitem.fields.currency")}
                    name="currencyID"
                    rules={[{ required: true }]}
                    initialValue={applicationSettings?.defaultCurrencyID}
                  >
                    <Select {...currencySelectProps} showSearch={true} />
                  </Form.Item>
                </Col>
                <Col span={12} key="Status">
                  <Form.Item
                    label={translate("general.status.title")}
                    name="statusID"
                  >
                    <Switch
                      checkedChildren={ConvertStatusID(1)}
                      unCheckedChildren={ConvertStatusID(0)}
                      defaultChecked={initialStatus == 1}
                      onChange={onSelectedChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane
            tab={translate("menu.expense.costitem")}
            key="2"
            disabled={actionData == "create"}
          >
            <Row gutter={24} hidden={actionData == "create"}>
              <Col span={24} key="costitem">
                <Form.Item label="" name="costItems">
                  <List
                    title=""
                    breadcrumb={<></>}
                    canCreate={costStatus == 0 || costStatus == 2}
                    createButtonProps={{
                      onClick: () => {
                        onSelectedChangeCreateEnterProperty(true);
                        createModalShow();
                      },
                    }}
                  >
                    <Table
                      {...tableProps}
                      rowKey="id"
                      pagination={{
                        ...tableProps.pagination,
                        size: "small",
                      }}
                    >
                      <Table.Column<ICostItem>
                        dataIndex="date"
                        title={translate("pages.cost.fields.date")}
                        render={(value: any) => (
                          <DateField format="LLL" value={value} />
                        )}
                      />
                      <Table.Column
                        dataIndex="description"
                        title={translate("pages.costitem.fields.description")}
                      />
                      <Table.Column
                        dataIndex={["subCostCategory", "label"]}
                        title={translate(
                          "pages.costitem.fields.subCostCategory"
                        )}
                      />
                      <Table.Column
                        dataIndex="categoryDescription"
                        title={translate(
                          "pages.costitem.fields.categoryDescription"
                        )}
                      />
                      <Table.Column
                        dataIndex={["costType", "name"]}
                        title={translate("pages.costitem.fields.costtype")}
                      />
                      <Table.Column
                        dataIndex="documentNo"
                        title={translate("pages.costitem.fields.documentNo")}
                      />
                      <Table.Column
                        dataIndex="firmName"
                        title={translate("pages.costitem.fields.firmName")}
                      />
                      <Table.Column<ICost>
                        dataIndex={["currency", "name"]}
                        title={translate("pages.costitem.fields.currency")}
                      />
                      <Table.Column<ICostItem>
                        key="totalPrice"
                        title={translate("pages.costitem.fields.totalPrice")}
                        dataIndex="totalPrice"
                        render={(value) => <NumberField value={value} />}
                      />

                      <Table.Column<ICostItem>
                        key="tax"
                        title={translate("pages.costitem.fields.taxPrice")}
                        dataIndex="tax"
                        render={(value) => <NumberField value={value} />}
                      />

                      <Table.Column<ICostItem>
                        title={translate("general.button.files")}
                        dataIndex="actions"
                        render={(_text, record): React.ReactNode => {
                          return (
                            <Dropdown
                              overlay={moreMenu(record)}
                              trigger={["click"]}
                            >
                              <MoreOutlined
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                  fontSize: 24,
                                }}
                              />
                            </Dropdown>
                          );
                        }}
                      />

                      {(costStatus == 0 || costStatus == 2) && (
                        <Table.Column<ICostItem>
                          title={translate("general.button.actions")}
                          dataIndex="actions"
                          key="actions"
                          render={(_, record) => (
                            <Space>
                              <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={() => {
                                  onSelectedChangeEditEnterProperty(true);
                                  setCurrentMainCategoryId(
                                    record?.mainCostCategoryID ?? 0
                                  );
                                  editModalShow(record.id.toString());
                                }}
                              />
                              <DeleteButton
                                data-testid="edit-delete-button"
                                mutationMode="undoable"
                                onSuccess={() => { }}
                                resource="CostItem"
                                recordItemId={record.id}
                              />
                            </Space>
                          )}
                        />
                      )}
                    </Table>
                  </List>
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tab={translate("menu.expense.costactionhistory")}
            key="3"
            disabled={actionData == "create"}
          >
            <Row gutter={24} hidden={actionData == "create"}>
              <Col span={24} key="costitem">
                <Form.Item label="" name="costItems">
                  <List title="" breadcrumb={<></>} canCreate={false}>
                    <Table
                      {...costActionHistoryTableProps}
                      rowKey="id"
                      pagination={{
                        ...costActionHistoryTableProps.pagination,
                        size: "small",
                      }}
                    >
                      <Table.Column
                        dataIndex="dateCreated"
                        title={translate("pages.cost.fields.actiondate")}
                      />
                      <Table.Column
                        dataIndex="description"
                        title={translate("pages.cost.fields.description")}
                      />
                      <Table.Column
                        dataIndex="oldCostStatusType"
                        title={translate("pages.cost.fields.oldcoststatustype")}
                        render={(value: any) => (
                          <TagField
                            value={value.name}
                            color={ConvertCostStatusToColor(value.id)}
                          />
                        )}
                      />
                      <Table.Column
                        dataIndex="newCostStatusType"
                        title={translate("pages.cost.fields.newcoststatustype")}
                        render={(value: any) => (
                          <TagField
                            value={value.name}
                            color={ConvertCostStatusToColor(value.id)}
                          />
                        )}
                      />
                      <Table.Column
                        dataIndex="oldCurrentLevel"
                        title={translate("pages.cost.fields.oldCurrentLevel")}
                      />
                      <Table.Column
                        dataIndex="newCurrentLevel"
                        title={translate("pages.cost.fields.newCurrentLevel")}
                      />
                      <Table.Column
                        dataIndex="approvementLevel"
                        title={translate("pages.cost.fields.approvementLevel")}
                      />
                      <Table.Column
                        dataIndex={["referenceUser", "label"]}
                        title={translate("pages.costitem.fields.referenceuser")}
                      />
                    </Table>
                  </List>
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Edit>
      
      <Modal {...createModalProps}>
      <Spin spinning={loading}> 
        <Form
          {...createFormProps}
          layout="vertical"
          resource="CostItem"
          ref={formRefCreateProperty}
          onFinish={createModelSubmit}
        >
          <Form.Item name="CostID" hidden>
            <Input disabled={true} value={parsedId} />
          </Form.Item>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={translate("pages.costitem.fields.description")}
                name="description"
              >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate("pages.costitem.fields.costtype")}
                name="costTypeID"
                rules={[{ required: true }]}
              >
                <Select {...costTypeSelectProps} showSearch={true} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={translate("pages.costitem.fields.mainCostCategory")}
                name="mainCostCategoryID"
                rules={[{ required: true }]}
              >
                <Select
                  {...mainCategorySelectProps}
                  showSearch={true}
                  onChange={onMainCategorySelectChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate("pages.costitem.fields.subCostCategory")}
                name="subCostCategoryID"
                rules={[{ required: true }]}
              >
                <Select {...subCategorySelectProps} showSearch={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={translate("pages.costitem.fields.categoryDescription")}
                name="categoryDescription"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={translate("pages.costitem.fields.documentNo")}
                name="documentNo"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label={translate("pages.costitem.fields.totalPrice")}
                name="totalPrice"
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={0.1}
                  step={0.01}
                  onChange={(value: number | string | null) => {
                    //setTotalPriceCreate(value)
                    //setTaxCreate(Number((value * taxPercentCreate / 100).toFixed(3)))
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={translate("pages.costitem.fields.taxPrice")}
                name="tax"
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={0.1}
                  step={0.01}
                  onChange={(value: number | string | null) => {
                    //setTaxPercentCreate(value);
                    //setTaxCreate(Number((totalPriceCreate * value / 100).toFixed(3)))
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={translate("pages.costitem.fields.firmName")}
                name="firmName"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                rules={[
                  { required: true, message: translate("rules.required") },
                ]}
                label={translate("pages.costitem.fields.date")}
                name="date"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : dayjs(),
                })}
                initialValue={dayjs()}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={translate("general.status.title")}
                name="statusID"
              >
                <Switch
                  checkedChildren={ConvertStatusID(1)}
                  unCheckedChildren={ConvertStatusID(0)}
                  defaultChecked={true}
                  onChange={onSelectedChangeCreateEnterProperty}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} key="medias">
              <Form.Item
                name="medias"
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
              >
                <Upload.Dragger
                  name="file"
                  action={`${apiUrl}/Media/upload`}
                  listType="text"
                  onChange={handleFileChange}
                  headers={{
                    Authorization: `bearer ${identity?.token}`,
                  }}
                  maxCount={5}
                  multiple
                  showUploadList={{
                    showDownloadIcon: true,
                  }}
                >
                  <p className="ant-upload-text">
                    {translate("pages.expense.fields.uploadFile")}
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        </Spin>
        {showConfirmation && (
            <Modal
            centered={true}
                title={translate("general.button.titleconfirmation1")}
                visible={showConfirmation}
                onCancel={() => setShowConfirmation(false)}
                footer={[
                    <Button key="cancel" onClick={() => setShowConfirmation(false)}>
                        {translate("general.button.cansel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleConfirmationYes}>
                        {translate("general.button.yes")}
                    </Button>,
                ]}
            >
                <p>{translate("general.button.titleconfirmation2")}</p>
            </Modal>
)}
      </Modal>

      <Modal {...editModalProps}>
        <Spin spinning={editModalFormLoading}>
          <Form
            {...editFormProps}
            layout="vertical"
            resource="CostItem"
            ref={formRefEditProperty}
            onFinish={editModelSubmit}
            
          >
            <Form.Item name="CostID" hidden>
              <Input disabled={true} value={parsedId} />
            </Form.Item>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={translate("pages.costitem.fields.description")}
                  name="description"
                >
                  <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={translate("pages.costitem.fields.costtype")}
                  name="costTypeID"
                  rules={[{ required: true }]}
                >
                  <Select {...costTypeSelectProps} showSearch={true} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={translate("pages.costitem.fields.mainCostCategory")}
                  name="mainCostCategoryID"
                  rules={[{ required: true }]}
                >
                  <Select
                    {...mainCategorySelectProps}
                    showSearch={true}
                    onChange={onMainCategorySelectChange}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={translate("pages.costitem.fields.subCostCategory")}
                  name="subCostCategoryID"
                  rules={[{ required: true }]}
                >
                  <Select {...subCategorySelectProps} showSearch={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={translate("pages.costitem.fields.categoryDescription")}
                  name="categoryDescription"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={translate("pages.costitem.fields.documentNo")}
                  name="documentNo"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  label={translate("pages.costitem.fields.totalPrice")}
                  name="totalPrice"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0.1}
                    step={0.01}
                    onChange={(value: number | string | null) => {
                      //setTotalPriceEdit(value)
                      //setTaxEdit(Number((value * taxPercentEdit / 100).toFixed(3)))
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={translate("pages.costitem.fields.taxPrice")}
                  name="tax"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0.1}
                    step={0.01}
                    onChange={(value: number | string | null) => {
                      //setTaxPercentCreate(value);
                      //setTaxCreate(Number((totalPriceCreate * value / 100).toFixed(3)))
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={translate("pages.costitem.fields.firmName")}
                  name="firmName"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  rules={[
                    { required: true, message: translate("rules.required") },
                  ]}
                  label={translate("pages.costitem.fields.date")}
                  name="date"
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : dayjs(),
                  })}
                  initialValue={dayjs()}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={translate("general.status.title")}
                  name="statusID"
                >
                  <Switch
                    checkedChildren={ConvertStatusID(1)}
                    unCheckedChildren={ConvertStatusID(0)}
                    defaultChecked={true}
                    onChange={onSelectedChangeCreateEnterProperty}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} key="medias">
                <Form.Item
                  name="medias"
                  valuePropName="fileList"
                  getValueFromEvent={getValueFromEvent}
                >
                  <Upload.Dragger
                    name="file"
                    action={`${apiUrl}/Media/upload`}
                    listType="text"
                    onChange={onFileUploadChange}
                    headers={{
                      Authorization: `bearer ${identity?.token}`,
                    }}
                    maxCount={5}
                    multiple
                    showUploadList={{
                      showDownloadIcon: true,
                    }}
                  >
                    <p className="ant-upload-text">
                      {translate("pages.expense.fields.uploadFile")}
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
