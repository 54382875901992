

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IWorkflow } from "../../../entities/definition/IWorkflow";
import { useState } from "react";
import { ConvertStatusID, ConvertBoolean } from "helpers/statusHelper";
import React from "react";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import "./style.less";
import { IUserForView } from "entities/visa/IUserForView";
import { useConstantValueFromService, useConstantValueFromServiceString } from "hooks";

import { IDepartment } from "entities/definition/IDepartment";
import { useNavigation, useParsed, useTranslate } from "@refinedev/core";
import { Edit, useForm, useModal, useSelect } from "@refinedev/antd";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Switch } from "antd";
import ReactFlow from 'reactflow';


import 'reactflow/dist/style.css';



export const WorkflowEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    var actionData = "create";

    if (parsedId) {
        actionData = "edit";
    }

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<IWorkflow>({
            redirect: false,
            action: actionData == "create" ? "create" : "edit",
            mutationMode: "undoable",
            metaData: {
                isFormRequest: true
            }
        });
    const translate = useTranslate();
    const Data = queryResult?.data?.data;
    var initialStatus = Data?.statusID ?? 1;

    const { list, push } = useNavigation();

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    if (mutationResult.isSuccess) {
        redirect("list");
    }

    const { selectProps: departmentSelectProps } = useSelect<IDepartment>({
        resource: "Department",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "name",
                operator: "contains",
                value
            }
        ]
    });

    return (
        <>
            <Edit isLoading={formLoading}
                saveButtonProps={saveButtonProps}
                headerButtons={({ defaultButtons }) => (
                    <Space>
                        {defaultButtons}
                        {parsedAction == "edit" && <Button onClick={() => {
                            push("/definition/workflows/" + parsedId + "/designer")
                        }}>
                            Design (Beta)
                        </Button>}
                    </Space>
                )}
                canDelete={(CheckPermissionsV2("Delete", "Business.Concrete.Definition.WorkflowManager") && parsedId != undefined)}
                title={translate("buttons.edit")} recordItemId={parsedId}>
                <Form {...formProps} layout="vertical" ref={formRef}>
                    <Form.Item label={translate("pages.workflow.fields.id")} name="id" initialValue={Data?.id ?? 0} hidden>
                        <Input />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={12} key="name">
                            <Form.Item label={translate("pages.workflow.fields.name")} name="name" required>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12} key="limits">
                            <Form.Item label={translate("pages.workflow.fields.limits")} name="limits" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12} key="department">
                            <Form.Item label={translate("pages.workflow.fields.department")} name="departmentID">
                                <Select {...departmentSelectProps} showSearch={true} />
                            </Form.Item>
                        </Col>
                        <Col span={12} key="isRequireEmployeeDepartmentManagerApprovement">
                            <Form.Item label={translate("pages.workflow.fields.isRequireEmployeeDepartmentManagerApprovement")} name="isRequireEmployeeDepartmentManagerApprovement">
                                <Switch defaultChecked={Data?.isRequireEmployeeDepartmentManagerApprovement ?? false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12} key="sortOrder">
                            <Form.Item label={translate("pages.workflow.fields.sortOrder")} name="sortOrder">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col span={12} key="Status">
                            <Form.Item label={translate("general.status.title")} name="statusID" initialValue={initialStatus}>
                                <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Edit>
        </>
    );
};
