import { useMemo } from "react";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Card, Spin, Typography } from "antd";
import GaugeChart from 'react-gauge-chart';
import Config from "../../../config.json";

import NumberCard from "components/card/numbercard";

export const DataOutPerTime: React.FC = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    let minute = 10;
    const url = `${API_URL}/monitoring/getdataoutpertime?minute=${Config.dashboardMonitorTime}`;
    const { data:response, isLoading } = useCustom<{
        data: number
    }>({ url, method: "get",queryOptions:{refetchInterval:60000,refetchIntervalInBackground:true} });

    const { Text, Title } = Typography;
    
    let count = response?.data?.data ?? 0;

    return (
        <NumberCard title={t("dashboard.monitoring.dataoutpertime")} value={count} isLoading={isLoading} onClick={undefined}/>               
        
    );
};