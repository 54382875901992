import { DeleteButton, Edit, ListButton, RefreshButton, SaveButton, useForm } from "@refinedev/antd";
import { Col, Form, Input, Row, Select, Space, Switch } from "antd";

import {
    useRouterContext,
    ResourceRouterParams,
    useResourceWithRoute,
    useTranslate,
    useNavigation,
    useParsed,
} from "@refinedev/core";

import { css } from "aphrodite";
import sheet from "components/offLayoutArea/style";
import { IUserForUpdate } from "entities/visa/IUserForUpdate";
import { selectProps } from "helpers/arrayHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { ConvertStatusID } from "helpers/statusHelper";


export const ParameterEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<IUserForUpdate>(
            {
                redirect: false,
                action: parsedAction == "create" ? "create" : "edit",
                mutationMode: "undoable"
            }
        );
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 1;
    const { list } = useNavigation();

    if (mutationResult.isSuccess) {
        redirect("list");
    }

    return (
        <Edit isLoading={formLoading}
            headerProps={{
                extra: (<Space wrap>
                    <ListButton data-testid="edit-list-button"
                        resource={parsedResource?.name}>

                        {translate("menu.configuration.parameter")}
                    </ListButton>
                    {parsedAction == "edit" && (
                        <RefreshButton
                            resource={parsedResource?.name}
                            recordItemId={parsedId}
                        />
                    )}

                </Space>)
            }}
            headerButtons={
                <Space>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                        }}
                    />
                    <SaveButton {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                            redirect("create");
                        }}>
                        {translate("buttons.saveandnew")}
                    </SaveButton>
                    {CheckPermissionsV2("Delete", "Business.Concrete.Configuration.ParameterManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </Space>
            }
            title={translate("buttons.edit")} recordItemId={parsedId}>
            <Form {...formProps} layout="vertical">
                <Row gutter={24}>
                    <Col span={12} key="Module">
                        <Form.Item label={translate("pages.Parameter.fields.module")} name="module">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Name">
                        <Form.Item label={translate("pages.Parameter.fields.name")} name="name">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Value">
                        <Form.Item label={translate("pages.Parameter.fields.value")} name="value">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Status">
                        <Form.Item label={translate("general.status.title")} name="statusID">
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Edit>
    );
}