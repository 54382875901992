import { DateField, DeleteButton, Edit, EditButton, SaveButton, useFileUploadState, useForm, useModalForm } from "@refinedev/antd";
import { useNavigation, useParsed, useTranslate } from "@refinedev/core";
import { Col, DatePicker, Form, Input, List, Row, Select, Space, Switch, Table, Tabs } from "antd";
import { ApplicationSettings } from "entities/configuration/IApplicationSettings";
import { IFAQ } from "entities/content/IFAQ";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import React, { useState, useEffect } from "react";
import { useReadLocalStorage } from "usehooks-ts";



const { TabPane } = Tabs;



export const SSSEdit: React.FC = () => {const applicationSettings = useReadLocalStorage<ApplicationSettings>(
    "ApplicationSettings"
  );



    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
      } = useParsed();
    var actionData = "create";
    
    if (parsedId) {
      actionData = "edit";
    }
    const {
        formProps,
        saveButtonProps,
        queryResult,
        onFinish,
        redirect,
        formLoading,
        mutationResult,
        form: costForm,
      } = useForm<IFAQ>({
        redirect: false,
        action: actionData == "create" ? "create" : "edit",
        mutationMode: "optimistic",
        resource: "FAQ",
        id: parsedId
      });

      const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        onFinish: editModelOnFinish,
        form: editModelForm,
        queryResult: editModalQueryResult,
        formLoading: editModalFormLoading,
        close: editModalClose,
      } = useModalForm<IFAQ>({
        action: "edit",
        resource: "FAQ",
        warnWhenUnsavedChanges: false,
        redirect: false,
        mutationMode: "optimistic"
      });
    
      const { list } = useNavigation();
      let formRef = React.useRef<any>(null);
      const { isLoading, onChange } = useFileUploadState();
      const translate = useTranslate();
    

    return (
        <>
        <Edit 
        isLoading={formLoading}
        footerButtons={({ defaultButtons }) => (
          <>
              <SaveButton
                  {...saveButtonProps}
                  onClick={async () => {
                      await onFinish?.();
                  }}
              />
              {CheckPermissionsV2("Delete", "Business.Concrete.Content.FAQManager") && parsedId &&
                  <DeleteButton
                      data-testid="edit-delete-button"
                      mutationMode="undoable"
                      onSuccess={() => {
                          redirect("list");
                      }}
                  />
              }
          </>
      )}
          title={translate("buttons.edit")}
          recordItemId={parsedId}

        
        >
      
      <Form {...formProps} layout="vertical" ref={formRef}>
                <Row gutter={24}>
                    <Col span={12} key="id">
                        <Form.Item label={translate("menu.faq.id")} name="id" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Question">
                        <Form.Item label={translate("menu.faq.question")} name="question" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={12} key="Description">
                        <Form.Item label={translate("menu.faq.description")} name="description" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    
                </Row>
                

            </Form>
        </Edit>
        </>
    );
};