export function selectProps(...props: any[]) {
  return function (obj: any) {
    let newObj: any = {};
    props.forEach(name => {
      newObj[name] = obj[name];
    });

    return newObj;
  }
}

/*
export function groupBy(list: any[], keyGetter: any) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection)
      map.set(key, [item]);
    else
      collection.push(item);
  });

  return map;
}
*/
export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });

export const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
  array.reduce((map, value, index, array) => {
    const key = predicate(value, index, array);
    map.get(key)?.push(value) ?? map.set(key, [value]);
    return map;
  }, new Map<Q, T[]>());