import { IEmployee } from "../../../entities/visa/IEmployee";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor, ConvertBoolean, ConvertBooleanToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { EditButton, List, TagField, useTable } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Table } from "antd";

export const EmployeeList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps } = useTable<IEmployee>();
    const translate = useTranslate();

    return (
        <List title={translate("pages.employee.title")} canCreate={CheckPermissionsV2("Add", "Business.Concrete.Visa.EmployeeManager")} >
            <Table {...tableProps}
                rowKey="id"
            >
                <Table.Column dataIndex="id" title={translate("pages.employee.fields.id")} />
                <Table.Column dataIndex={["user","label"]} title={translate("pages.employee.fields.label")} />                
                <Table.Column dataIndex={["employeeType","name"]} title={translate("pages.employee.fields.employeetype")} />                                  
                <Table.Column dataIndex={["department","name"]} title={translate("pages.employee.fields.department")} />                                  
                <Table.Column dataIndex={["superVisor","user","label"]} title={translate("pages.employee.fields.supervisor")} />                
                <Table.Column
                        dataIndex="statusID"
                        title={translate("general.status.title")}
                        render={(value:any,data:IEmployee) => {
                            return <TagField value={ConvertStatusID(value)} color={ConvertStatusIDToColor(value)}/>
                        }}
                    />       
                <Table.Column<IEmployee>
                    title={translate("general.button.actions")}
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <EditButton
                                size="small"
                                recordItemId={record.id}
                            />
                        );
                    }}
                />
            </Table>
        </List>
    );
};