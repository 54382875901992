import { useEffect, useState } from 'react';
import { Table } from 'antd';
import IRoleAssignmentLog from 'entities/configuration/IRoleAssignmentLog';
import { IResourceComponentsProps, useTranslate } from '@refinedev/core';
import { DataType, OptionType } from 'interfaces/IResourceComponentType';
import { useTable } from '@refinedev/antd';
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from 'components/page/list/ListPage';
import { DateField } from 'components/fields/date';

export const RoleAssignmentLogList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {

  const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IRoleAssignmentLog>({
    syncWithLocation: true,
    sorters: {
      initial: [
        {
          field: "ID",
          order: "desc"
        }
      ]
    },
    resource: "RoleAssignmentLog",
  });
  const translate = useTranslate();

  let filters: Array<ColumnFilterInfo> = [
    {
      key: "ID",
      title: translate("pages.RoleAssignmentLog.fields.id"),
      type: "number"
    },
    {
      key: "UserCreatedName",
      title: translate("pages.RoleAssignmentLog.fields.userCreatedName"),
      type: "string"
    },
    {
      key: "AssignedTo",
      title: translate("pages.RoleAssignmentLog.fields.assignedTo"),
      type: "string"
    },
    {
      key: "DateCreated",
      title: translate("pages.RoleAssignmentLog.fields.date"),
      type: "date"
    }
  ]

  let columns: Array<ColumnInfo> = [
    {
      dataIndex: "id",
      title: translate("pages.RoleAssignmentLog.fields.id")
    },
    {
      dataIndex: "userCreatedName",
      title: translate("pages.RoleAssignmentLog.fields.userCreatedName")
    },
    {
      dataIndex: "assignedTo",
      title: translate("pages.RoleAssignmentLog.fields.assignedTo")
    },
    {
      dataIndex: "oldRolesList",
      title: translate("pages.RoleAssignmentLog.fields.oldRoles"),

    },
    {
      dataIndex: "newRolesList",
      title: translate("pages.RoleAssignmentLog.fields.newRoles"),

    },
    {
      dataIndex: "dateCreated",
      title: translate("pages.RoleAssignmentLog.fields.date"),
      render: (props: RenderComponentProps) => (
        <DateField value={props.value} format="LLL" />
      )
    },
  ]

  return (
    <ListPage listProps={{
      title: translate("pages.RoleAssignmentLog.title"),
      canCreate: false,
    }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={queryResult.isFetching} />
  );
}