

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { ICost } from "../../../entities/expense/ICost";
import { ICostItem } from "../../../entities/expense/ICostItem";
import { ICostActionHistory } from "../../../entities/expense/ICostActionHistory";
import { ConvertStatusID, ConvertCostStatusToColor } from "helpers/statusHelper";
import React, { useState } from "react";
import "./style.less";
import { IDepartment } from "entities/definition/IDepartment";
import { ICurrency } from "entities/definition/ICurrency";
import { IEmployee } from "entities/visa/IEmployee";
import { useConstantValueFromService, useSelectCustomUrl } from "hooks";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { IMultiMediaSaveRequest } from "entities/expense/IMultiMediaSaveRequestd";
import dayjs from "dayjs";
import { SendOutlined } from "@ant-design/icons";
import { axiosInstance } from "providers/authProvider";
import { IServiceObjectResult } from "interfaces/Results/ServiceObjectResult";
import { IMainCostCategory } from "entities/definition/IMainCostCategory";
import { ISubCostCategory } from "entities/definition/ISubCostCategory";
import { useLocation } from 'react-router-dom';
import { IMedia } from "entities/content/IMedia";
import TextArea from "antd/lib/input/TextArea";
import { EyeOutlined, CheckOutlined, CloseOutlined, FormOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Input, Menu, Modal, Row, Select, Space, Table, Tabs, Typography } from "antd";
import { useApiUrl, useGetIdentity, useNavigation, useParsed, useShow, useTranslate } from "@refinedev/core";
import { FileField, List, ListButton, NumberField, RefreshButton, Show, TagField, useFileUploadState, useForm, useModalForm, useSelect, useTable } from "@refinedev/antd";
import { DateField } from "components/fields/date";
import { useDarkMode } from "usehooks-ts";
import { useGetIdentityInterface } from "models/useGetIdentityInterface";

const { TabPane } = Tabs;
const { Title, Text, Link } = Typography;

const approve = (costId: string, description: string) => {
    return axiosInstance.post("CostApprovement/approveExpense?costId=" + costId + "&description=" + description);
}

const reject = (costId: string, description: string) => {
    return axiosInstance.post("CostApprovement/rejectExpense?costId=" + costId + "&description=" + description);
}

const sendFix = (costId: string, description: string) => {
    return axiosInstance.post("CostApprovement/fixExpense?costId=" + costId + "&description=" + description);
}

export const CostShow: React.FC = () => {
    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }

    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)
    const apiUrl = useApiUrl();
    const { data: identity } = useGetIdentity<useGetIdentityInterface>();


    const { isLoading, onChange } = useFileUploadState();
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const { create, edit, clone, show, list, push, replace, goBack } = useNavigation();
    const { formProps, saveButtonProps, onFinish,
        redirect, formLoading, mutationResult, form: costForm } = useForm<ICost>({
            redirect: false,
            action: "edit",
            mutationMode: "undoable",
            resource: "Cost"
        });
    const translate = useTranslate();

    const { queryResult: costQueryResult } = useShow<ICost>({
        resource: "Cost",
        id: parsedId
    });

    const record = costQueryResult?.data?.data;

    let formRef = React.useRef<any>(null);


    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }


    const onFileUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.status == "done") {
            let newMedia: IMultiMediaSaveRequest = {
                mediaID: info.file.response.id,
                uid: info.file.uid
            };
        }

        onChange(info);
    }

    const { tableProps } = useTable<ICostItem>({
        resource: "CostItem",
        syncWithLocation: false,
        permanentFilter: [
            {
                field: "CostID",
                operator: "eq",
                value: parsedId ?? 0
            }
        ]
    });


    const { selectProps: currencySelectProps } = useSelect<ICurrency>({
        resource: "Currency",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 5000
    });



    const { selectProps: mainCategorySelectProps } = useSelect<IMainCostCategory>({
        resource: "MainCostCategory",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 5000
    });

    const [loading, setLoading] = useState(false);
    const [currentActID, setCurrentActID] = useState(0);
    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        setLoading(true);
        switch (currentActID) {
            case 1:
                approve(parsedId!.toString(), values.description).finally(() => {
                    list("Approval")
                });
                break;
            case 2:
                reject(parsedId!.toString(), values.description).finally(() => {
                    list("Approval")
                });
                break;
            case 3:
                sendFix(parsedId!.toString(), values.description).finally(() => {
                    list("Approval")
                });
                break;
            default: break;

        }

        setCurrentActID(0);
    }

    const {
        modalProps: previousEmployeeModalProps,
        formProps: previousEmployeeFormProps,
        show: previousEmployeeModalShow,
        close: previousEmployeeModalClose,
        onFinish: previousEmployeeModelOnFinish,
        form: previousEmployeeModelForm
    } = useModalForm<IEmployee>({
        redirect: false,
        action: "create",
        warnWhenUnsavedChanges: false,
        resource: "Employee"
    });

    const { selectProps, query: previousApproversQuery } = useSelectCustomUrl({
        url: "Cost/getpreviousapprovers?costId=" + parsedId!,
        optionIdName: "id",
        optionLabelName: "label"
    });

    const { tableProps: costActionHistoryTableProps } = useTable<ICostActionHistory>({
        resource: "CostActionHistory",
        syncWithLocation: false,
        permanentFilter: [
            {
                field: "CostID",
                operator: "eq",
                value: parsedId ?? 0
            }
        ],
        permanentSorter: [
            {
                field: "dateCreated",
                order: "desc"
            }
        ]
    });

    const moreMenu = (record: Array<IMedia>) => {
        return (
            <Menu
                mode="vertical"
                onClick={({ domEvent }) => domEvent.stopPropagation()}
            >
                {record.map((item: IMedia) => {
                    return (<Menu.Item
                        key={item.uid}
                    >
                        <FileField src={item.url} title={item.name} />
                    </Menu.Item>)
                })}

            </Menu>
        )
    };

    return (
        <>
            <Show isLoading={formLoading || loading}
                headerButtons={({ defaultButtons }) => (
                    <Space>

                        <ListButton
                            data-testid="edit-list-button"
                            resource={parsedResource?.name}
                        >
                            {translate("menu.expense.cost")}
                        </ListButton>
                        <RefreshButton
                            resource={parsedResource?.name}
                            recordItemId={parsedId}
                        />
                        {record?.costStatusTypeID == 1 &&
                            <>
                                <Button disabled={loading} type="primary" onClick={() => {
                                    setCurrentActID(1);
                                    previousEmployeeModalShow();
                                }}>{translate("buttons.approve")}</Button>
                                <Button disabled={loading} type="dashed" danger onClick={() => {
                                    setCurrentActID(2);
                                    previousEmployeeModalShow();
                                }}>{translate("buttons.reject")}</Button>
                                <Button disabled={loading} type="default" onClick={() => {
                                    setCurrentActID(3);
                                    previousEmployeeModalShow();
                                }}>{translate("buttons.sendFix")}</Button>
                            </>
                        }

                    </Space>
                )}
                title={translate("buttons.show")} recordItemId={parsedId}>

                <Tabs>
                    <TabPane tab={translate("menu.expense.cost")} key="1">
                        <Row gutter={[24, 24]}>
                            <Col span={12} key="employee">
                                <Title level={5}>{translate("pages.cost.fields.employee")}</Title>
                                <Text>{record?.employee.label}</Text>
                            </Col>

                            <Col span={12}>
                                <Title level={5}>{translate("pages.cost.fields.date")}</Title>
                                <DateField format="LLL" value={record?.date} />
                            </Col>
                            <Col span={12} key="description">
                                <Title level={5}>{translate("pages.cost.fields.description")}</Title>
                                <Text>{record?.description}</Text>
                            </Col>
                            <Col span={12} key="department">
                                <Title level={5}>{translate("pages.cost.fields.department")}</Title>
                                <Text>{record?.department.name}</Text>
                            </Col>
                            <Col span={12} key="totalPrice">
                                <Title level={5}>{translate("pages.cost.fields.totalPrice")}</Title>
                                <Text>{record?.totalPrice}</Text>
                            </Col>
                            <Col span={12} key="tax">
                                <Title level={5}>{translate("pages.cost.fields.tax")}</Title>
                                <Text>{record?.tax}</Text>
                            </Col>
                            <Col span={12} key="currency">
                                <Title level={5}>{translate("pages.cost.fields.currency")}</Title>
                                <Text>{record?.currency?.label}</Text>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={translate("menu.expense.costitem")} key="2">
                        <Row gutter={24} >
                            <Col span={24} key="costitem">
                                <Form.Item label="" name="costItems">
                                    <List title="" breadcrumb={<></>} canCreate={false} createButtonProps={{
                                    }}>
                                        <Table
                                            {...tableProps}
                                            rowKey="id"
                                            pagination={{
                                                ...tableProps.pagination,
                                                size: "small",
                                            }}
                                            footer={(currentPageData: any) => {
                                                let totalPrice = 0;
                                                let totalTax = 0;

                                                currentPageData.map((item: ICostItem) => {
                                                    totalPrice += item.totalPrice;
                                                    totalTax += item.tax
                                                });

                                                return (
                                                    <div>
                                                        <span>{translate("pages.costitem.fields.totalPrice")}: {totalPrice}</span>
                                                        <br />
                                                        <span>{translate("pages.costitem.fields.tax")}: {totalTax}</span>
                                                    </div>
                                                )
                                            }}
                                            className={isDarkMode ? "table-striped-rows-dark" : "table-striped-rows"}
                                        >
                                            <Table.Column dataIndex="description" title={translate("pages.costitem.fields.description")} />
                                            <Table.Column dataIndex={["subCostCategory", "label"]} title={translate("pages.costitem.fields.subCostCategory")} />
                                            <Table.Column dataIndex="categoryDescription" title={translate("pages.costitem.fields.categoryDescription")} />
                                            <Table.Column dataIndex={["costType", "name"]} title={translate("pages.costitem.fields.costtype")} />
                                            <Table.Column dataIndex="documentNo" title={translate("pages.costitem.fields.documentNo")} />
                                            <Table.Column dataIndex="firmName" title={translate("pages.costitem.fields.firmName")} />
                                            <Table.Column dataIndex={["currency", "label"]} title={translate("pages.costitem.fields.currency")} />
                                            <Table.Column<ICostItem>
                                                key="totalPrice"
                                                title={translate("pages.costitem.fields.totalPrice")}
                                                dataIndex="totalPrice"
                                                render={(value) => (
                                                    <NumberField
                                                        value={value}
                                                    />
                                                )}
                                            />

                                            <Table.Column<ICostItem>
                                                key="tax"
                                                title={translate("pages.costitem.fields.tax")}
                                                dataIndex="tax"
                                                render={(value) => (
                                                    <NumberField
                                                        value={value}
                                                    />
                                                )}
                                            />

                                            <Table.Column<ICostItem>
                                                key="taxPercent"
                                                title={translate("pages.costitem.fields.taxPercent")}
                                                dataIndex="taxPercent"
                                                render={(value) => (
                                                    <NumberField
                                                        value={value}
                                                    />
                                                )}
                                            />

                                            <Table.Column<ICostItem>
                                                title={translate("general.button.files")}
                                                dataIndex="actions"
                                                render={(_text, record): React.ReactNode => {
                                                    return (
                                                        <Dropdown
                                                            overlay={moreMenu(record.medias)}
                                                            trigger={["click"]}
                                                        >
                                                            <MoreOutlined
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{
                                                                    fontSize: 24,
                                                                }}
                                                            />
                                                        </Dropdown>
                                                    );
                                                }}
                                            />
                                        </Table>
                                    </List>
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={translate("menu.expense.costactionhistory")} key="3">
                        <Row gutter={24}>
                            <Col span={24} key="costitem">
                                <Form.Item label="" name="costItems">
                                    <List title="" breadcrumb={<></>} canCreate={false}>
                                        <Table
                                            {...costActionHistoryTableProps}
                                            rowKey="id"
                                            pagination={{
                                                ...costActionHistoryTableProps.pagination,
                                                size: "small",
                                            }}
                                            className={isDarkMode ? "table-striped-rows-dark" : "table-striped-rows"}
                                        >
                                            <Table.Column dataIndex="dateCreated" title={translate("pages.cost.fields.actiondate")} />
                                            <Table.Column dataIndex="description" title={translate("pages.cost.fields.description")} />
                                            <Table.Column
                                                dataIndex="oldCostStatusType"
                                                title={translate("pages.cost.fields.oldcoststatustype")}
                                                render={(value: any) => <TagField value={value.name} color={ConvertCostStatusToColor(value.id)} />}
                                            />
                                            <Table.Column
                                                dataIndex="newCostStatusType"
                                                title={translate("pages.cost.fields.newcoststatustype")}
                                                render={(value: any) => <TagField value={value.name} color={ConvertCostStatusToColor(value.id)} />}
                                            />
                                            <Table.Column dataIndex="oldCurrentLevel" title={translate("pages.cost.fields.oldCurrentLevel")} />
                                            <Table.Column dataIndex="newCurrentLevel" title={translate("pages.cost.fields.newCurrentLevel")} />
                                            <Table.Column dataIndex="approvementLevel" title={translate("pages.cost.fields.approvementLevel")} />
                                            <Table.Column dataIndex={["referenceUser", "label"]} title={translate("pages.costitem.fields.referenceuser")} />
                                        </Table>
                                    </List>
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </Show>
            <Modal
                {...previousEmployeeModalProps} >
                <Form {...previousEmployeeFormProps} onFinish={handleSubmit}>
                    <Form.Item label={translate("pages.cost.fields.description")} name="description" initialValue="">
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                    </Form.Item>
                    {(currentActID == 3) &&

                        <Form.Item label={translate("pages.cost.fields.previouseuser")} name="userId">
                            <Select {...selectProps} />
                        </Form.Item>
                    }
                </Form>
            </Modal>
        </>
    );
};
