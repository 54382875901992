import { Edit, useForm, useSelect, getValueFromEvent, useFileUploadState } from "@refinedev/antd";
import { useApiUrl, useGetIdentity, useParsed, useTranslate } from "@refinedev/core";
import { Card, Col, Form, Input, InputNumber, Modal, Row, Select, Upload, UploadFile, theme } from "antd";
import ColorPicker, { Color } from "antd/es/color-picker";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import { ApplicationSettings, IApplicationSettings } from "entities/configuration/IApplicationSettings";
import { IMultiMediaSaveRequest } from "entities/content/IMultiMediaSaveRequest";
import { ICurrency } from "entities/definition/ICurrency";
import { identity } from "lodash";
import { useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import { PlusOutlined } from '@ant-design/icons';
import { useFormErrorHandler } from "helpers/serviceResponseHelper";
import { useGetIdentityInterface } from "models/useGetIdentityInterface";

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const ApplicationSettingsEdit: React.FC = () => {
    const API_URL = useApiUrl();
    const translate = useTranslate();
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();
    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<IApplicationSettings>({
            resource: "ApplicationSetting",
            redirect: false,
            action: "edit",
            mutationMode: "optimistic",
            meta: {
                variables: {
                    queryStringParams: {
                        ignoreId: true
                    }
                }
            },
            id: 0,
            errorNotification: useFormErrorHandler
        });


    const record = queryResult?.data?.data;

    const { selectProps: currencySelectProps } = useSelect<ICurrency>({
        resource: "Currency",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 5000
    });

    let initialAppSettings: ApplicationSettings = {
        defaultCurrencyID: 1,
        primaryThemeColor: "#311b92",
        logoDarkUrl: "",
        logoLightUrl: "",
        logoDarkCollapsedUrl: "",
        logoLightCollapsedUrl: "",
        minimumAmountRequiredForApproval: 0,
        oldestCostDatePeriod: 30
    };

    const [applicationSettings, setApplicationSettings] = useLocalStorage('ApplicationSettings', initialAppSettings)

    const onChangeComplete = (color: Color) => {
        setApplicationSettings({
            ...applicationSettings,
            primaryThemeColor: color.toHexString()
        })
    }

    const { data: identity } = useGetIdentity<useGetIdentityInterface>();

    const onFileUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.status == "done") {
            let newMedia: IMultiMediaSaveRequest = {
                mediaID: info.file.response.id,
                uid: info.file.uid
            };
        }

        onChange(info);
    }

    const { isLoading, onChange } = useFileUploadState();

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleFinish = (values: any) => {
        values.id = record?.id;
        onFinish(values);
    }

    return (
        <>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="preview" style={{ height: '100%' }} src={previewImage} />
            </Modal>
            <Edit
                isLoading={formLoading}
                canDelete={false}
                saveButtonProps={{
                    ...saveButtonProps,
                    disabled: isLoading || formLoading,
                }}
                title={translate("buttons.edit")}
                recordItemId={record?.id}>
                <Form
                    {...formProps}
                    layout="vertical" onFinish={handleFinish}>
                    <Form.Item label={translate("pages.announcement.fields.id")} name="id" initialValue={record?.id} hidden>
                        <Input value={record?.id} />
                    </Form.Item>
                    <Row gutter={[24, 24]}>
                        <Col span={12}>
                            <Card>
                                <Form.Item label={translate("pages.costitem.fields.currency")} name="defaultCurrencyID" rules={[{ required: true }]}>
                                    <Select {...currencySelectProps} showSearch={true} />
                                </Form.Item>
                            </Card>

                        </Col>
                        <Col span={12}>
                            <Card>
                                <Form.Item initialValue={applicationSettings.primaryThemeColor} label={translate("pages.appsettings.fields.theme")} name="primaryThemeColor" rules={[{ required: true }]}>
                                    <ColorPicker showText onChangeComplete={onChangeComplete} />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Form.Item label={translate("pages.appsettings.fields.ldapCheckUrl")} name="ldapCheckUrl" rules={[{ required: false }]}>
                                    <Input />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card>
                                <Form.Item initialValue={applicationSettings.minimumAmountRequiredForApproval} label={translate("pages.appsettings.fields.minimumAmountRequiredForApproval")} name="minimumAmountRequiredForApproval" rules={[{ required: true }]}>
                                    <InputNumber min={0} step={0.1} />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item initialValue={applicationSettings.oldestCostDatePeriod} label={translate("pages.appsettings.fields.oldestCostDatePeriod")} name="oldestCostDatePeriod" rules={[{ required: true }]}>
                                            <InputNumber min={0} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row>
                                    <Col span={6} >
                                        <Form.Item
                                            name="logoDark"
                                            valuePropName="fileList"
                                            getValueFromEvent={getValueFromEvent}
                                            label={translate("pages.appsettings.fields.applicationSettingMediasLogoDark")}
                                        >
                                            <Upload
                                                name="file"
                                                action={`${API_URL}/Media/upload`}
                                                listType="picture-card"
                                                onChange={onFileUploadChange}
                                                headers={
                                                    {
                                                        "Authorization": `bearer ${identity?.token}`
                                                    }
                                                }
                                                maxCount={1}
                                                showUploadList={{
                                                    showDownloadIcon: true
                                                }}
                                                onPreview={handlePreview}
                                            >
                                                {uploadButton}
                                            </Upload>

                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="logoDarkCollapsed"
                                            valuePropName="fileList"
                                            getValueFromEvent={getValueFromEvent}
                                            label={translate("pages.appsettings.fields.applicationSettingMediasLogoDarkCollapsed")}
                                        >
                                            <Upload
                                                name="file"
                                                action={`${API_URL}/Media/upload`}
                                                listType="picture-card"
                                                onChange={onFileUploadChange}
                                                headers={
                                                    {
                                                        "Authorization": `bearer ${identity?.token}`
                                                    }
                                                }
                                                maxCount={1}
                                                showUploadList={{
                                                    showDownloadIcon: true
                                                }}
                                                onPreview={handlePreview}
                                            >
                                                {uploadButton}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} >
                                        <Form.Item
                                            name="logoLight"
                                            valuePropName="fileList"
                                            getValueFromEvent={getValueFromEvent}
                                            label={translate("pages.appsettings.fields.applicationSettingMediasLogoLight")}
                                        >
                                            <Upload
                                                name="file"
                                                action={`${API_URL}/Media/upload`}
                                                listType="picture-card"
                                                onChange={onFileUploadChange}
                                                headers={
                                                    {
                                                        "Authorization": `bearer ${identity?.token}`
                                                    }
                                                }
                                                maxCount={1}
                                                showUploadList={{
                                                    showDownloadIcon: true
                                                }}
                                                onPreview={handlePreview}
                                            >
                                                {uploadButton}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="logoLightCollapsed"
                                            valuePropName="fileList"
                                            getValueFromEvent={getValueFromEvent}
                                            label={translate("pages.appsettings.fields.applicationSettingMediasLogoLightCollapsed")}
                                        >
                                            <Upload
                                                name="file"
                                                action={`${API_URL}/Media/upload`}
                                                listType="picture-card"
                                                onChange={onFileUploadChange}
                                                headers={
                                                    {
                                                        "Authorization": `bearer ${identity?.token}`
                                                    }
                                                }
                                                maxCount={1}
                                                showUploadList={{
                                                    showDownloadIcon: true
                                                }}
                                                onPreview={handlePreview}
                                            >
                                                {uploadButton}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                    </Row>
                </Form>
            </Edit>
        </>

    )
}