import { List, TextField, TagField, useTable, ShowButton, EditButton } from "@refinedev/antd";
import { Table, Row, Col, Card, Space, Button } from "antd";
import { IResourceComponentsProps, useShow, useTranslate } from "@refinedev/core";
import { IUserForView } from "../../../entities/visa/IUserForView";
import { usePermissions } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { useState } from "react";
import { Breadcrumb } from "components/breadcrumb";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { DateField } from "components/fields/date";
import { ICostReport } from "entities/report/ICostReport";
import { ICostReportDemand } from "entities/report/ICostReportDemand";
import { useConstantValueFromService } from "hooks";

export const CostReportDemandList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult, setFilters, setSorters: setSorter }
        = useTable<ICostReportDemand>({
            resource: "CostReportDemand",
            sorters: {
                permanent: [
                    {
                        order: "desc",
                        field: "ID"
                    }
                ]
            }
        });
    const translate = useTranslate();
    const { selectProps: reportStatusSelectProps } = useConstantValueFromService("GetReportStatus")

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "CostReportName",
            title: translate("pages.costreportdemand.fields.name"),
            type: "string"
        },
        {
            key: "ReportStatusID",
            title: translate("pages.costreportdemand.fields.name"),
            type: "number",
            selectProps: reportStatusSelectProps
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.costreportdemand.fields.id")
        },
        {
            dataIndex: "costReportName",
            title: translate("pages.costreportdemand.fields.costReportName")
        },
        {
            dataIndex: ["reportStatus", "name"],
            title: translate("pages.costreportdemand.fields.reportStatusID")
        },
        {
            dataIndex: "tryCount",
            title: translate("pages.costreportdemand.fields.tryCount")
        },
        {
            dataIndex: "message",
            title: translate("pages.costreportdemand.fields.message")
        },
        {
            dataIndex: "dateCreated",
            title: translate("pages.RequestLog.fields.dateCreated"),
            render: (props: RenderComponentProps) => (
                <DateField value={props.value} format="LLL" />

            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            
            render: (props: RenderComponentProps) => (
                console.log(props.record.downloadUrl),
                <Button onClick={() => {
                    window.open(props.record.downloadUrl, "_blank", "noreferrer");
                }} disabled={!(props.record.reportStatusID == 2 && props.record.downloadUrl)}>
                    {translate("pages.costreportdemand.fields.download")}
                </Button>
            )
        }
    ]

    const handleExport = () => {

    }

    return (
        <ListPage listProps={{
            title: translate("menu.report.costreportdemands"),
            canCreate: false,
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={tableQueryResult.isFetching} />
    );
};